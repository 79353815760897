<template>
  <div class="vhp-empty" :class="{ full: fullScreen }">
    <div v-if="showImage" class="empty-image">
      <img src="@/assets/image/no-image.png" class="image">
    </div>
    <div class="empty-text">{{ !text ? this.$t("콘텐츠없음") : text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    showImage: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss">
.vhp-empty {
  text-align: center;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 64px 0;
  height: 25vh;
  width: 100%;
  font-size: 16px;
  flex-wrap: wrap;
  &.full {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .empty-image {
    margin: 5px auto;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      object-fit: cover;
      max-width: 60%;
      max-height: 60%;
    }
  }
  .empty-text {
    flex: 0 1 100%;
  }
}
</style>
