<template>
    <div class="vhp-footer-content container-width">
        <section class="vhp-footer-section-menu">
            <div
              class="section-menu-wrap desktop-hide"
              v-for="menu in menus"
              :key="menu.key"
            >
              <router-link :to="menu.path">
                <strong>{{$t(menu.text)}}</strong>
              </router-link>
            

              <div
                v-for="submenu in menu.childrens"
                :key="submenu.key"
                class="section-submenu-wrap"
              >
                <router-link :to="submenu.path">{{$t(submenu.text)}}</router-link>
              </div>
            </div>

          
          <div class="mobile-section-menu-wrap desktop-show">
            <ul
                v-for="(menu, idx) in menus" 
                :key="menu.key"
            >
              <li 
              >
                <div class="parent-item" @click="onClickArrow(idx)" >
                  <a v-if="menu.childrens.length > 0" :class="{ active : activeMenuIndex === idx}"> 
                    <strong>{{$t(menu.text)}}</strong>
                    <div class="arrow" v-if="menu.childrens">
                      <span/>
                      <span/>
                    </div>
                  </a>

                  <router-link :to="menu.path"  v-else>
                    <strong>{{$t(menu.text)}}</strong>
                  </router-link>
                </div>

                <div v-if="menu.childrens.length > 0 && activeMenuIndex === idx" :class="{ active : activeMenuIndex === idx}">
                  <div 
                    class="header-allmenu-item"
                    v-for="submenu in menu.childrens" 
                    :key="submenu.key"
                  >
                    <a v-if="submenu.outlink" @click="selectedMenu = null, openLink(submenu.path)" class="outlink">
                      {{$t(submenu.text)}}
                      <img alt="external-link" src="@/assets/image/external-link.svg" height="12" style="margin-left:6px;"/>
                    </a>
                    <router-link v-else :to="submenu.path">
                      {{$t(submenu.text)}}
                    </router-link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          
          

          <div
            class="section-menu-addon"
          >
            <div>
              <router-link  to="/privacy"><strong>{{$t('개인정보 처리방침')}}</strong><img alt="external-link" src="@/assets/image/external-link.svg" height="10" style="margin-left:6px;"/></router-link>
            </div>
            <!-- <div>
              <a href="https://vazil-vhp.s3.ap-northeast-2.amazonaws.com/resources/vazil.pdf" download="Vazil_Company_Introduction(2023).pdf" target="_blank"><strong>{{$t('기업소개서')}}</strong><img alt="external-link" src="@/assets/image/external-link.svg" height="10" style="margin-left:6px;"/></a>
            </div> -->
          </div>
        </section>

        <section class="vhp-footer-section-info">
          <span class="section-info-logo">
            <img alt="logo" src="@/assets/image/logo.svg"  height="18"/>
          </span>

          <div class="section-info-main">
            <div>
              {{$t('(주)바질컴퍼니')}}
            </div>
            <div>
              {{$t('대표')}}: {{$t('황희경')}} | {{$t('사업자등록번호')}}: 180-88-00809 
            </div>
            <div>
              {{$t('회사주소')}}
            </div>
            <div>
              T 051-609-9633 | E admin@vazilcompany.com
            </div>
            <p>
              © Copyright {{ new Date().getFullYear() }} {{$t('저작권')}}
            </p>
          </div>

          

          <div class="section-menu-sns">
            <button @click="$utils.openLink('https://blog.naver.com/vazilcompany')">
              <img alt="globe" src="@/assets/image/naver-blog.svg" width="20" height="20"/>
            </button>
            <button @click="$utils.openLink('https://www.youtube.com/@user-yo5xu2gv4q')">
              <img alt="globe" src="@/assets/image/youtube.svg" width="20" height="20"/>
            </button>
          </div>

          <span class="section-info-copyright">

            
          <vhp-select 
            v-model="familySite"
            :items="[{text:$t('브릿지 AI'), value:'https://www.vridgeai.com'}]" 
            item-key="text"
            item-value="value"
            :placeholder="$t('패밀리 사이트')" 
          />
          </span>
        </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'vhpFooter',
    props: {
      menus: {
        type: Array,
        default: ()=> {
          return []
        }
      }
    },
    watch:{
      familySite(){
        if(this.familySite){
          this.$utils.openLink(this.familySite)
          this.familySite = ''
        }
      }
    },
    data() {
      return{
        familySite:'',
        activeMenuIndex: -1
      }
    },
    methods:{
      onClickArrow(idx) {
        if(idx === this.activeMenuIndex) {
          this.activeMenuIndex = -1
          return
        }
        this.activeMenuIndex = idx
      }
    }
  }
  </script>

<style lang="scss">
.vhp-footer-content{
  margin:0 auto;
  width:100%;
  height:100%;
  padding: 24px 0;
  font-size:13px;


  .vhp-footer-section-menu{
    width:calc(100%);
    display: flex;
    margin:0;
    padding:40px 0;
    text-align: left;
    position:relative;
    border-bottom: 1px solid $base-border-color;
    font-size: 14px;

    .section-menu-wrap{
      margin:0;
      padding:0;
      display: inline-block;
      text-align: left;

      a {
        display: inline-block;
        padding:4px 0;

        &:hover{
          color: $base-primary-color;
        }
      }

      .section-submenu-wrap{
        width:100%;
        a {
          color: $base-foreground-sub-color;
        }
      }
      width:16%;

      @media screen and (max-width: #{$breakpoint-desktop}) {
        display: block;
        padding:16px 0;
        width:100%;
      }
      
    }

    @media screen and (max-width: #{$breakpoint-desktop}) {
      width:calc(100% - 0px);
      display: block;
      padding:20px 0;
    }

    @media screen and (max-width: #{$breakpoint-md}) {
      width:calc(100% - 0px);
      display: block;
      margin:0;
      padding:20px 0;
      font-size: 13px;
    }

    .section-menu-addon{
      text-align: right;
      position: absolute;
      right:0;
      height: 100%;
      font-size: 13px;
      width:150px;

      @media screen and (max-width: #{$breakpoint-desktop}) {
        position:relative;
        text-align: left;
        font-size: 12px;
        bottom:0px;
        left:0;
        width:100%;
        padding-bottom:0px;
        margin:32px 0 0 0;
        display: flex;
        flex-direction: row;
        column-gap: 24px;
      }

      > div{
        margin-bottom:8px;
      }
    }

    .mobile-section-menu-wrap{
        margin:0 auto;
        max-height: calc(100% - 60px);
        overflow-y: auto;

        position:relative;

        ul {
          display: inline;
          margin:0;
          padding:0;
        }

        .parent-item{
          cursor: pointer;
          position: relative;
          border-bottom: 1px solid $base-border-color;
          padding:16px 0;


          

          .arrow{
            position: absolute;
            right:16px;
            top:calc(50% - 10px);
            width: 20px;
            height:20px;

            display: flex;
            justify-content: center;
            align-items: center;
            transform: rotate(0deg);
            transition: .2s;
            
            span {
                width: 8px;
                height: 2px;
                position: absolute;
                top: 50%;
                left: 3.5px;
                transform: translate(-50%, -50%);
                transition: all 0.2s ease-in-out;
                background-color: #222222;
                transform: rotate(45deg);
        
                &:nth-of-type(2) {
                  top: calc(50%);
                  left:calc(50% - 2px);
                  transform: rotate(-45deg);
                }
            }

          }
        }
        


        .active{
          padding:12px 0;
          background-color: #fcfcfc;
          border-radius: 0.5rem;
          strong{
            color: $base-primary-color;
          }

          a {
            animation-name: slide_down;
            animation-duration: 300ms;
            animation-timing-function: ease-in-out;
          }

          .arrow{
            transform: rotate(180deg);
          }

          font-size:0.9rem;
          .header-allmenu-item{
            margin:0 0 12px 0px;
            font-size: 1em;
            text-indent: 8px;
          }

        }

      }

  }

  

  .vhp-footer-section-info{
    width:100%;
    text-align: left;
    margin:80px 0 40px 0;
    position:relative;
    .section-info-logo{
      filter: brightness(0) saturate(100%) invert(22%) sepia(7%) saturate(166%) hue-rotate(201deg) brightness(87%) contrast(88%);
    }

    .section-info-logo{
      position:absolute;
      left:0;
      top:-50px;
      height:40px;
      line-height: 40px;
    }

    .section-info-main{
      letter-spacing: 0px;
      line-height: 20px;
      font-size: 0.82rem;
      color: $base-foreground-sub-color;
      @media screen and (max-width: #{$breakpoint-md}) {
        margin-left:0px;
        margin-top: 0px;
        font-size: 0.75rem;
      }

      p{
        color: $base-foreground-sub-color;
        font-size: 0.7rem;
      }
    }

    .section-info-copyright{
      position: absolute;
      right:0;
      bottom:0px;
      .vhp-input{
        *{
          font-size: 12px !important;

        }
      }

      @media screen and (max-width: #{$breakpoint-desktop}) {
        position: relative;
        top:20px;
        left: 0px;
      }

      @media screen and (max-width: #{$breakpoint-md}) {
        position: relative;
        top:20px;
        left: 0px;

        .vhp-input{
          width:100%;
        }
      }
    }

    .section-menu-sns{
        position:absolute;
        top:-50px;
        right:0;
        
        button {
          width:36px;
          height:36px;
          cursor: pointer;
          background-color: $base-button-default-background-color;
          border:0;
          border-radius: 100%;
          position:relative;
          cursor: pointer;
          z-index: 10;
          margin:0 4px;

          img{
            position:absolute;
            top:calc(50% - 10px);
            left:calc(50% - 10px);
            
            filter: brightness(0) saturate(100%) invert(65%) sepia(0%) saturate(1224%) hue-rotate(11deg) brightness(85%) contrast(93%);
          }

          &:hover{
            opacity: 0.8;
          }
        }

      }
  }
}
  
</style>
  
  