<template>
  <div>
    <vhp-breadcrumb />
    <router-view />
  </div>
</template>

<script>
export default {
  name: "RobotIndex",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>