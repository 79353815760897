<template>
  <div class="preview-container" :id="previewId"></div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

let camera, scene, light, renderer, controls, gltfLoader, space, previewModel;

export default {
  props: {
    id: { type: String, default: "" },
    url: { type: String, default: "/glb/OCR100.glb" },
    vertexLine: { type: Boolean, default: false },
  },
  data() {
    return {
      isModelLoadError: false,
      loading: false,
      previewId: "",
      resizeObserver: null,
    };
  },
  mounted() {
    this.previewId = this.id ? this.id : new Date().getTime();
    this.startObservingSpace();
    this.initScene();
  },
  watch: {
    id() {
      this.previewId = this.id;
    },
    url() {
      this.initScene();
    },
  },
  methods: {
    initializeResource() {
      if (scene) {
        scene = null;
      }
      if (camera) {
        camera = null;
      }
      if (renderer) {
        renderer.dispose();
      }
    },

    onWindowResize() {
      space = document.getElementById(this.previewId);
      if (!space) return;
      let width = space.clientWidth;
      let height = space.clientHeight;
      const aspect = width / height;

      camera.aspect = aspect;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    },

    initScene() {
      if (!this.url || !this.previewId) {
        return;
      }

      this.initializeResource();

      this.$nextTick(() => {
        space = document.getElementById(this.previewId);

        if (!space) return;

        scene = new THREE.Scene();

        renderer = new THREE.WebGLRenderer({
          alpha: false,
          preserveDrawingBuffer: false,
          antialias: false,
        });
        renderer.setClearColor(0xffffff, 0);

        let width = space.clientWidth;
        let height = space.clientHeight;

        renderer.setSize(width, height);
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.soft = true;

        space.appendChild(renderer.domElement);

        light = new THREE.DirectionalLight(0xffffff, 1);
        light.castShadow = true;
        light.shadow.mapSize.x = 256;
        light.shadow.mapSize.y = 256;
        light.shadow.camera.left = -30;
        light.shadow.camera.right = 30;
        light.shadow.camera.top = 35;
        light.shadow.camera.bottom = -30;
        light.shadow.camera.near = 0;
        light.shadow.camera.far = 100;
        light.position.set(7, 7, 7);
        scene.add(light);

        const ambientLight = new THREE.AmbientLight(0x555555, 1);
        scene.add(ambientLight);

        camera = new THREE.PerspectiveCamera(47, width / height, 0.001, 1000);
        camera.position.set(2, 2, 2);

        scene.add(camera);

        controls = new OrbitControls(camera, renderer.domElement);
        controls.maxDistance = 10;
        controls.minDistance = 0.1;

        // controls.mouseButtons.LEFT = THREE.MOUSE.NONE;
        // controls.mouseButtons.MIDDLE = THREE.MOUSE.PAN;
        // controls.mouseButtons.RIGHT = THREE.MOUSE.ROTATE;

        gltfLoader = new GLTFLoader();
        this.animate();
        this.loadModel(this.url);
      });
    },

    animate() {
      if (!scene && !camera) return;

      controls.update();
      renderer.render(scene, camera);
      requestAnimationFrame(this.animate);
    },

    loadModel(url) {
      this.isModelLoadError = false;
      this.loading = true;

      const material = new THREE.MeshPhysicalMaterial({
        color: new THREE.Color(0.0003, 0.04817, 0.8796),
        emissive: 0x000000,
        roughness: 0,
        metalness: 0,
        ior: 2.333,
        reflectivity: 0.5,
        iridescence: 0,
        iridescenceIOR: 0,
        iridescenceThicknessRange: [100, 400],
        sheen: 0,
        sheenRoughness: 1,
        sheenColor: 0x000000,
        clearcoat: 0,
        clearcoatRoughness: 0,
        specularIntensity: 1,
        specularColor: 0xffffff,
        fog: true,
      });

      gltfLoader.load(
        url,
        (object) => {
          previewModel = object.scene;

          const box = new THREE.Box3().setFromObject(previewModel);
          const center = box.getCenter(new THREE.Vector3());

          const distance = 0.8;
          camera.position.set(
            center.x + distance,
            center.y + distance,
            center.z + distance
          );

          camera.lookAt(center);
          previewModel.position.x += previewModel.position.x - center.x;
          previewModel.position.y += previewModel.position.y - center.y;
          previewModel.position.z += previewModel.position.z - center.z;
          camera.lookAt(previewModel.position);

          previewModel.scale.set(1, 1, 1);

          previewModel.traverse((node) => {
            if (node.isMesh) {
              // node.material = material;
              node.material.emissive.setHex(0x000000);
              node.material.roughness = 0;
              node.material.metalness = 0;
              node.material.reflectivity = 1;
              node.specularIntensity = 1;

              if (this.vertexLine) {
                node.material = material;
                const wireframe = new THREE.WireframeGeometry(node.geometry);
                const wireframeLine = new THREE.LineSegments(
                  wireframe,
                  new THREE.LineBasicMaterial({
                    color: 0x909090,
                  })
                );
                wireframeLine.name = "wireframe";
                wireframeLine.visible = false;
                node.add(wireframeLine);
              }
            }
          });

          scene.add(previewModel);

          if (this.vertexLine) {
            this.drawVertexLine();
          }

          this.loading = false;
        },
        // (xhr) => {},
        (err) => {
          this.isModelLoadError = true;
          console.log(err);
          this.loading = false;
        }
      );
    },

    drawVertexLine() {
      scene.traverse((node) => {
        if (node.name === "wireframe") {
          node.visible = true;
        }
      });
    },

    eraseVertexLine() {
      scene.traverse((node) => {
        if (node.name === "wireframe") {
          node.visible = false;
        }
      });
    },

    startObservingSpace() {
      this.$nextTick(() => {
        this.resizeObserver = new ResizeObserver(() => {
          this.onWindowResize();
        });
        this.resizeObserver.observe(this.$el);
      });
    },

    stopObservingSpace() {
      this.resizeObserver.unobserve(this.$el);
    },
  },
};
</script>

<style lang="scss">
.preview-container {
  min-width: 100% !important;
  min-height: 100% !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;

  &:hover {
    .controller-panel {
      opacity: 1 !important;
      pointer-events: all !important;
    }
  }

  .controller-panel {
    transition: all 0.15s ease-in-out;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 36px;

    border: 1px solid $base-border-color;
    .theme--light & {
      background-color: #ffffffc0;
    }
    .theme--dark & {
      background-color: #252b37c0;
    }
    box-shadow: 0px 4px 16px #05070a0c !important;
    backdrop-filter: blur(4px);
  }
}
</style>
