<template>
  <div>
    <vhp-breadcrumb />
    <template
      v-if="$route.path === '/info/vazil' || $route.path === '/info/history' || $route.path === '/info/workplace'"
    >
      <div class="vhp-main-content-title">
        <h1 class="page-title">{{ $t("바질컴퍼니 소개") }}</h1>
        <p class="page-subtitle">{{ $t("바질컴퍼니 소개_설명") }}</p>
      </div>

      <div class="page-background">
        <img src="@/assets/image/background/bg26.jpg" alt="page-image" />
      </div>

      <div class="page-submenus">
        <router-link
          v-for="(menu, idx) in submenus"
          :key="menu.path"
          :to="menu.path"
        >
          <span
            class="page-submenus-item"
            :class="{ active: $route.path === menu.path }"
            >{{ $t(menu.text) }}</span
          >
          <span class="page-submenus-divider" v-if="idx < submenus.length - 1"
            >|</span
          >
        </router-link>
      </div>
    </template>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "InfoIndex",
  computed: {},
  data() {
    return {
      submenus: [
        {
          text: "개요",
          path: "/info/vazil",
        },
        {
          text: "주요연혁",
          path: "/info/history",
        },
        {
          text: "사업장",
          path: "/info/workplace",
        },
      ],
    };
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
