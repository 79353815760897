import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  plugins: [createPersistedState()],
  state: {
    frontToken: null,
    apiToken: null,
    isAdmin: null,
    admin: null,
  },
  getters: {
    getFrontToken(state){
      return state.frontToken
    },
    getApiToken(state){
      return state.apiToken
    },
    getIsAdmin(state){
      return state.isAdmin
    },
    getAdmin(state){
      return state.admin
    },
  },
  mutations: {
    setFrontToken: (state, val) => {
      state.frontToken = val
      state.isAdmin = true
    },
    setApiToken: (state, val) => {
      state.apiToken = val
    },
    setIsAdmin: (state, val) => {
      state.isAdmin = val
    },
    setAdmin: (state, val) => {
      state.admin = val
    },
    clearTokens: (state)=>{
      state.frontToken = null
      state.apiToken = null
      state.isAdmin = false
      state.admin = null
    }
  },
  actions: {
  },
});