<template>
  <div
    class="vhp-simple-slide"
  >
    <div class="vhp-slide-container" @scroll="onScroll">
      <div class="slide-item-wrap container-width">
        <div v-if="mounted">
          <div
            class="slide-item"
            v-for="(item, index) in filterdItems"
            :key="item.title"
            :class="index < slide + maxCount && index >= slide ? 'active' : ''"
            @click="onClickItem(item, index)"
            :style="[
              'left:' + ((index - slide) * 100) / maxCount + '% !important;',
              'width:calc(' + (getCardWidth() - 24) + 'px);',
            ]"
          >
            <div class="item-image">
              <img
                class="card-image"
                alt="card-image"
                :src="item.image"
                @error="replaceImage"
              />
            </div>

            <div class="item-content">
              <h1
                >{{ item.title }}
              </h1>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="vhp-buttons-container container-width">
      <div class="slide-button-wrap" v-if="isMoveable ">
        <div class="slide-sequence">
          <span
            v-for="n in items.length"
            :key="'sequence-' + n"
            :class="
              n - 1 <= slide + (maxCount - 1) && n - 1 >= slide ? 'active' : ''
            "
          >
          </span>
        </div>
      </div>

      <div class="slide-button-wrap" v-if="isMoveable">
        <div class="slide-button prev" @click="prev()">
          <span />
          <span />
        </div>
      </div>
      <div class="slide-button-wrap" v-if="isMoveable">
        <div class="slide-button next" @click="next()">
          <span />
          <span />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showCount: {
      type: Number,
      default: 1,
    },
    contain: {
      type: Boolean,
      default: false,
    },
    showTags: {
      type: Boolean,
      default: false,
    },
    showPrice: {
      type: Boolean,
      default: false,
    },
    showDate: {
      type: Boolean,
      default: false,
    },
    showSimple: {
      type: Boolean,
      default: false,
    },
    baseUrl: {
      type: String,
      default: "/",
    },
    listUrl: {
      type: String,
      default: "/",
    },
  },
  computed: {
    isMoveable() {
      return this.items.length > this.maxCount;
    },
    filterdItems() {
      // let items = this.items
      
      // items = items.slice(this.slide, (this.slide + this.maxCount))
      return this.items
    }
  },
  data() {
    return {
      slide: 0,
      maxCount: 1,
      mounted: false
    };
  },
  mounted() {
    this.setMaxItemCount();
    window.addEventListener("resize", () => {
      this.setMaxItemCount();
    });


    setTimeout(() => {
      const items = document.getElementsByClassName('slide-item')
      for(let item of items) {
        item.style.transition = 'left .3s ease-in-out'
      }
    }, 300)

    this.mounted= true
    
  },
  watch: {},
  methods: {
    setMaxItemCount() {
      this.maxCount =
        window.innerWidth < 640
          ? 1
          : window.innerWidth < 1024
          ? 2
          : this.showCount;
    },
    prev() {
      this.slide =
        this.slide > 0 ? this.slide - 1 : this.items.length - this.maxCount;
    },
    next() {
      this.slide =
        this.slide < this.items.length - this.maxCount ? this.slide + 1 : 0;
    },

    onScroll() {
      //const item = document.getElementsByClassName('item')[0]
    },

    onClickItem(item, index) {
      if (index < this.slide + this.maxCount && index >= this.slide) {
        if (item.externalUrl) {
          this.$utils.openLink(item.externalUrl);
        } else {
          this.$emit('click:item', item, index)
        }
      }
    },

    getCardWidth() {
      const container = document.getElementsByClassName('slide-item-wrap')[0]
      if(container) {
        const width = container.clientWidth
        return width / this.maxCount
      }
      return 0
    }
  },
};
</script>

<style lang="scss" scoped>
.vhp-simple-slide {
  position: relative;
  width: 100%;

  .vhp-slide-container {
    left:50%;
    transform: translateX(-50%);
    position: absolute;
    min-width: $min-width;
    height: 360px;
    min-height: 360px;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;


    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(90, 90, 90, 0);
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }

    .slide-item-wrap{
      width:100%;
      margin: 0 auto;
      transform: translateX(0%) ;
      overflow-x: visible;
    }

    .slide-item {
      position: absolute;
      height: 100%;
      padding: 0;
      z-index: 10;
      margin: 0 12px;
      opacity: 0.1;
      display: flex;
      border-radius: 1rem;
      box-shadow: 0 2px 24px #00000013;
      cursor: pointer;
      overflow: hidden;


      @media screen and (max-width: #{$breakpoint-desktop}) {
        flex: 0 1 calc(50% - 24px);
        max-width: calc(50% - 24px);
      }

      @media screen and (max-width: #{$breakpoint-md}) {
        flex: 0 1 calc(100% - 24px);
        max-width: calc(100% - 24px);
      }

      &.active {
        opacity: 1;

      }

      .item-image {
        position: relative;
        @media screen and (max-width: #{$breakpoint-desktop}) {
          height: 380px;
        }
        // background-color: #eee;

        .card-image {
          transition: all 0.3s ease-in-out;
          display: block;
          width: 100%;
          object-fit: cover;
          min-height: 100%;
          filter: brightness(70%);
          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .item-content {
        width:calc(100% - 32px);
        display: flex;
        z-index: 10;
        color: $base-background-opacity-color;
        margin: 0px 16px;
        position: absolute;
        text-align: left;
        letter-spacing: -0.5px;

        @media screen and (max-width: #{$breakpoint-desktop}) {
          margin: 18px;
          padding-bottom: 18px;
        }

        @media screen and (max-width: #{$breakpoint-md}) {
          margin: 16px;
          padding-bottom: 16px;
        }

        h1 {
          font-size: 1.24rem;
          font-family: "Pretendard-SemiBold";
          max-width: 100%;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: $base-background-opacity-color;

          @media screen and (max-width: #{$breakpoint-desktop}) {
            font-size: 18px;
          }

          @media screen and (max-width: #{$breakpoint-md}) {
            font-size: 16px;
          }
        }

        .vhp-card-content-subtitle {
          color: $base-background-opacity-color;
          font-size: 15px;
          display: block;
          text-overflow: ellipsis;
          word-wrap: break-word;
          -webkit-line-clamp: 2;
          height: 40px;
          line-height: 20px;
          overflow: hidden;
          @media screen and (max-width: #{$breakpoint-md}) {
            padding: 0;
            margin: 0;
            font-size: 14px;
          }
        }

        .vhp-card-content-tags {
          position: absolute;
          bottom: -20px;
          left: 0;
          width: 100%;
          height: 30px;
          line-height: 30px;
          opacity: 0.7;
          font-size: 12px;

          @media screen and (max-width: #{$breakpoint-md}) {
            padding: 0;
            margin: 0;
            height: 24px;
            line-height: 24px;
          }

          .tag {
            background-color: $base-border-color;
            margin: 3px;
            padding: 3px 8px;
            border-radius: 6px;
          }
        }

        .vhp-card-content-right {
          font-size: 13px;
          position: absolute;
          width: 100%;
          bottom: -20px;
          right: 0;
          text-align: right;
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }

  .vhp-buttons-container{
    position: relative;
    width:100%;
    transform: translateX(0%);
    margin: 12px auto;
    height:48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .slide-button-wrap {
      


      .slide-sequence {
        padding: 0;
        margin: 6px 6px 6px 12px;
        height: 32px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        span {
          width: 10px;
          height: 10px;
          margin:2px;
          border-radius: 100%;

          background-color: $base-button-default-background-color;
          transition: all 0.2s ease-in-out;
          display: inline-block;

          &.active {
            background-color: $base-primary-opacity-color;
          }
        }
      }
      .slide-button {
        padding: 0;
        display: inline-block;
        margin: 6px;
        height: 32px;
        width: 32px;

        border-radius: 100%;
        position: relative;
        border: 1px solid $base-primary-high-opacity-color;
        
        box-shadow: 0px 0px 2px $base-primary-shadow-color;
        cursor: pointer;

        &:hover {
          background-color: $base-primary-transparent-color;
        }

        span {
          width: 8px;
          height: 2px;
          position: absolute;
          top: calc(50% + 1.5px);
          left: 11px;
          transition: all 0.1s ease-in-out;
          background-color: $base-button-background-color;
          transform: rotate(45deg);

          &:nth-of-type(2) {
            top: calc(50% - 3px);
            right: 11px;
            transform: rotate(-45deg);
          }
        }

        &.prev {
          transform: rotate(0);
          &:active {
            transform: scale(0.95);
          }
        }

        &.next {
          transform: rotate(180deg);
          &:active {
            transform: scale(0.95) rotate(180deg);
          }
        }

        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }

}
</style>
