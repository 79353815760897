<template>
  <div>
    <vhp-breadcrumb/>
    <div class="vhp-main-content-title">
      <h1 class="page-title">{{$t('기술과 서비스')}}</h1>
      <p class="page-subtitle">{{$t('기술과 서비스_설명')}}</p>
    </div>

    <div class="page-background flex">
      <!-- <img v-if="$route.path === '/service/robot'" src="@/assets/image/background/bg3.jpg" alt="page-image"/>
      <img v-if="$route.path === '/service/ai'" src="@/assets/image/background/bg21.jpg" alt="page-image"/>
      <img v-if="$route.path === '/service/cloud'" src="@/assets/image/background/bg22.jpg" alt="page-image"/> -->
      <img src="@/assets/image/title/tech.png" alt="page-image"/>
    </div>

    <!-- <div class="page-submenus">
        <router-link
          v-for="(menu, idx) in [{
            text:'전체',
            path:'/service'
          },{
            text:'로봇',
            path:'/service/robot'
          },{
            text:'인공지능',
            path:'/service/ai'
          },{
            text:'클라우드',
            path:'/service/cloud'
          }]"
          :key="menu.path"
          :to="menu.path"
        >
          <span class="page-submenus-item" :class="{active:$route.path === menu.path}">{{$t(menu.text)}}</span>
          <span class="page-submenus-divider" v-if="idx < 3">|</span>
        </router-link>
    </div> -->
    
    <layout :contents="contents" base-url="/content" list-url="/service" hide-filter/>
    <!-- <router-view/> -->
  </div>
</template>

<script>
import layout from '@/components/layouts/ListLayout.vue'

export default {
  name: 'ServiceIndex',
  components:{
    layout
  },
  data() {
    return {
      contents:[]
    }
  },
  mounted() {
    this.getContents()
  },
  methods:{
    getContents() {
      this.$axios.get('/content?category=SERVICE&locale=' + this.$i18n.locale.toUpperCase())
      .then(res=>{
        this.contents = res.data
      })
      .catch(err=>{
        console.log(err)
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
