<template>
  <div class="main-layout">
    <div class="scroll-navigation">
      <div
        class="nav-button"
        v-for="(item, index) in mainSections"
        :key="index"
        @click="scrollToSection(index + 1)"
        :class="{ active: index + 1 === activeSectionIndex }"
      >

        <span
          class="vhp-text white"
          :class="{ hide: activeSectionIndex === 1 }"
          >{{ item }}</span
        >
        <button></button>

      </div>
    </div>

    <div class="main-section vh" ref="mainSection" data-aos="fade">
      <div class="main-background">
        <div class="overlay"></div>
        <img
          v-if="prevSlideIndex >= 0 && mainContents[prevSlideIndex].url"
          :src="mainContents[prevSlideIndex].url"
          alt="home-image"
          class="home-image prev"
          :key="prevSlideIndex"
        />
        <img
          :src="
            mainContents[activeSlideIndex].url ||
            '@/assets/image/background/home1.jpg'
          "
          alt="home-image"
          class="home-image"
          :class="{ active: prevSlideIndex >= 0 }"
          :key="activeSlideIndex"
        />
      </div>
      <div class="section-wrap container-width full vh">
        <div class="section left">
          <div class="home-banner px-2 mt-8 mb-32">
            <div
              class="main-title"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="300"
              :key="activeSlideIndex"
            >
              {{ mainContents[activeSlideIndex].title || $t("메인타이틀") }}
            </div>
            <div
              class="main-description vhp-text xlarge light white"
              style="letter-spacing: 0"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="600"
              :key="activeSlideIndex"
            >
              {{ mainContents[activeSlideIndex].subtitle || $t("메인타이틀_설명") }}
            </div>

           
            <div class="main-slide-button-wrap">
       
              <div class="slide-button prev" @click="prevSlide">
                <span />
                <span />
              </div>

              <div class="slide-button next" @click="nextSlide">
                <span />
                <span />
              </div>

              <div class="slide-sequence">
                <span
                  v-for="n in mainContents.length"
                  :key="'sequence-' + n"
                  :class="
                    n - 1 <= activeSlideIndex && n - 1 >= activeSlideIndex
                      ? 'active'
                      : ''
                  "
                  @click="
                    (prevSlideIndex = activeSectionIndex),
                      (activeSlideIndex = n - 1)
                  "
                >
                </span>
              </div>

            </div>
          </div>
        </div>
      </div>
<!-- 
      <div
        style="
          position: absolute;
          bottom: 50px;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0.9;
          display: flex;
          z-index: 10;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          style="
            opacity: 0.7;
            border: 1px solid #fff;
            width: 24px;
            height: 48px;
            border-radius: 1rem;
            cursor: pointer;
          "
          @click="scrollToSection(2)"
        >
          <div
            style="
              margin: auto;
              margin-top: 6px;
              border-radius: 100%;
              width: 4px;
              height: 4px;
              background-color: #fff;
              animation-name: scroll_down;
              animation-delay: 1s;
              animation-duration: 2s;
              animation-fill-mode: forwards;
              animation-iteration-count: infinite;
            "
          ></div>
        </div>
        <div class="vhp-text white xxsmall light my-2">
          SCROLL
        </div>
      </div> -->
    </div>

    <div class="main-section unlimited" ref="mainSection" data-aos="fade-up">
      <div class="section-wrap container-width full">
        <div class="section center">
          <div class="section-title">
            <div class="sub-title">
              <div class="vhp-text medium red bold">ABOUT US</div>
              <div class="sub-title"></div>
            </div>
          </div>
          <div class="section-content">
            <div class="row">
              <div class="vhp-text large">
                <span v-if="$i18n.locale === 'en'">
                  VAZIL Company predicts and carries out transportation, installation, and inspection on its own.
                  <span class="vhp-text large bold underline"
                    >Artificial intelligence robot automation platform company</span
                  >. <br />
                  Experience a customized AI robot factory tailored to various industrial sites. 
                  <br/>manufacturing
                  We provide artificial intelligence solutions needed for industry.
                </span>
                <span v-else>
                  바질컴퍼니는 스스로 예측하고 운반, 설치, 검사를 수행하는
                  <span class="vhp-text large bold underline"
                    >인공지능 로봇 자동화 플랫폼 기업</span
                  >입니다. <br />
                  다양한 산업 현장에 맞는 맞춤형 AI 로봇 공장을 경험하세요. 제조
                  산업에 필요한 인공지능 솔루션을 제공합니다.
                </span>
                
              </div>
            </div>

            <div class="row my-4 pt-2" data-aos="fade-up">
              <div class="box-container">
                <div
                  v-for="(item, idx) in businessInfo"
                  :key="idx"
                  data-aos-duration="500"
                  :data-aos-delay="idx * 200"
                  data-aos-offset="100"
                  data-aos="fade-right"
                  data-aos-easing="ease-in-out"
                  style="display: flex; align-items: center"
                  class="box"
                >
                  <div
                    v-if="idx === 1 || idx === 2"
                    class="transparent icon-wrap"
                  >
                    <div
                      v-if="idx === 1"
                      class="vhp-text xxlarge"
                      style="font-family: 'Pretendard-ExtraLight'"
                    >
                      ＋
                    </div>
                    <div v-if="idx === 2" class="vhp-text xlarge light">→</div>
                  </div>
                  <div
                    class="outline round small pa-4 ma-4"
                    v-if="idx === 0 || idx === 1 || idx === 2"
                  >
                    <div class="round-wrap">
                      <div>
                        <strong class="vhp-text medium bold">
                          {{ $t(item.title) }}
                        </strong>
                      </div>
                      <div class="box-content mt-4">
                        <img :src="item.image" height="70" />
                        <div
                          class="vhp-text mt-2 mx-2"
                        >
                          {{ $t(item.description) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row ">
              <button
                class="vhp-action-button light"
                @click="$router.push('/info')"
              >
                {{ $t("자세히") }}

              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-section" ref="mainSection">
      <div class="main-background">
        <div class="overlay"></div>
        <img
          src="@/assets/image/background/bg37.jpg"
          alt="home-image"
          class="home-image prev"
        />
      </div>
      <div class="section-wrap container-width full">
        <div class="section center">
          <div class="section-title" 
            data-aos="fade-up"
          >
            <div class="vhp-text bold gray">PRODUCTS</div>
            <div class="sub-title" style="color: rgba(255, 255, 255, 0.9)">
              {{ $t("제품") }}
            </div>
          </div>
          <div class="section-content"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="row">
              <div class="slide-wrap my-4">
                <vhp-simple-slide
                  :items="solutionContents"
                  :show-count="4"
                  @click:item="(item, index) => $router.push('/products?index=' + index)"
                />
              </div>
            </div>
            <div class="row ">
              <button
                class="vhp-action-button"
                @click="$router.push('/products')"
              >
                {{ $t("목록보기") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="true" class="main-section" ref="mainSection">
      <div class="section-wrap container-width full">
        <div class="section center">
          <div class="section-title"
            data-aos="fade-up"
          >
            <div class="vhp-text bold navy">TECHNOLOGY</div>
            <div class="sub-title">{{$t('기술')}}</div>
          </div>
          <div class="section-content"
            
            data-aos="fade-up"
            data-aos-delay="200"
          
          >
            <div class="row">
              <div class="slide-wrap my-4">
                <vhp-simple-slide
                  :items="technologyContents"
                  :show-count="4"
                  class="service-slide"
                  @click:item="$router.push('/technology')"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="vhp-action-button light outline"
                @click="$router.push('/technology')"
              >
                {{ $t("상세보기") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-section" ref="mainSection">
      <div v-if="true" class="main-background">
        <div
          class="color"
          style="
            background: #f5f6fa;
            background: linear-gradient(
              90deg,
              rgba(245, 246, 250, 0.5),
              rgba(234, 235, 253, 0.3)
            );
          "
        ></div>
      </div>
      <div class="section-wrap container-width full">
        <div class="section center">
          <div class="section-title"
            data-aos="fade-up"
          >
            <div class="vhp-text pink bold">NEWS</div>
            <div class="sub-title">{{ $t("소식") }}</div>
          </div>
          <div class="section-content"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="row mt-2" v-if="!loadingContents">
              <div class="slide-wrap">
                <vhp-card-slide
                  :items="storyContents.slice(0, 6)"
                  :show-count="3"
                  :base-url="'/news/details'"
                  :list-url="'/news'"
                  @click:item="(item, index) => $router.push('/news/details?id=' + item.id)"
                />
              </div>
            </div>
            <div class="row">
              <div class="vhp-action-button light outline" @click="$router.push('/news')">
                {{ $t('바로가기') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-section unlimited" ref="mainSection">
      <div class="section-wrap container-width full">
        <div class="main-background">
          <div class="overlay"></div>
          <img
            src="@/assets/image/background/bg37.jpg"
            alt="home-image"
            class="home-image"
          />
        </div>
        <div class="section center" style="padding: 35px 0">
          <div class="mt-16 pt-4 pb-8"
             data-aos="fade-up"
          >
            <div class="vhp-text large white mt-2">
              {{ $t("메인콘텐츠5_설명") }}<br />
              {{ $t("메인콘텐츠5_부가설명") }}<br />
            </div>
            <button class="vhp-action-button" @click="$router.push('/contact')">
              {{ $t("문의하기") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeLayout",
  components: {},
  data() {
    return {
      mainSections: [
        "MAIN",
        "ABOUT US",
        "PRODUCTS",
        "TECHNOLOGY",
        "NEWS",
        "CONTACT",
      ],
      mainSectionCount: 0,
      activeSectionIndex: 1,

      bannerMessage: "",
      bannerMessageInterval: null,
      typing: true,

      videoUrls: [
        "https://www.youtube.com/embed/g6vl5RRQAco?si=Jhp3m2xTCfXlG3pi",
        "https://www.youtube.com/embed/hu03pSgNFsQ?si=nVhUaZIGwgdr9D15",
      ],

      loadingContents: false,
      loadingProducts: false,
      viewLimit: 3,
      storyContents: [],

      solutionContents: [
        {
          id: "@content-ts2ggs9s8j79",
          title: this.$t("자동화 솔루션"),
          subtitle: "solution description",
          image: require("@/assets/image/main/automation.jpg"),
        },
        {
          id: "@content-1iepgkj24u2bb",
          title: this.$t("로봇 솔루션"),
          subtitle: "solution description",
          image: require("@/assets/image/main/robot_solution.jpg"),
        },
        {
          id: "@content-qy4y6lfq53ch",
          title: this.$t("통합 S/W 솔루션"),
          subtitle: "solution description",
          image: require("@/assets/image/background/home5.jpg"),
        },
        {
          id: "@content-ts2ggs9s8j79",
          title: this.$t("AI 통합 솔루션"),
          subtitle: "solution description",
          image: require("@/assets/image/main/integration_solution.jpg"),
        },
      ],

      technologyContents: [
        {
          title: "ROBOT",
          subtitle: "technology description",
          image: require("@/assets/image/main/robot.gif"),
        },
        {
          title: "AI",
          subtitle: "technology description",
          image: require("@/assets/image/tech/tech26.gif"),
        },
        {
          title: "3D & 2D VISION",
          subtitle: "technology description",
          image: require("@/assets/image/tech/tech14.jpg"),
        },
        {
          title: "DIGITAL TWIN",
          subtitle: "technology description",
          image: require("@/assets/image/main/twin.jpg"),
        },
        {
          title: "CLOUD",
          subtitle: "technology description",
          image: require("@/assets/image/main/cloud.jpg"),
        },
        
      ],

      products: [],
      businessInfo: [
        {
          name: "ROBOT",
          title: "로봇 사업부",
          description: "빈 피킹 / 핸들링 / 디버링 / 용접로봇 / 비전검사",
          image: require("@/assets/image/pictogram/robot1.png"),
        },
        {
          name: "SOLUTION",
          title: "솔루션 사업부",
          description: "인공지능 통합 플랫폼 / 로봇 통합 관제 시스템",
          image: require("@/assets/image/pictogram/solution4.png"),
        },
        {
          name: "SMART FACTORY",
          title: "공정 자동화 솔루션",
          description: "스마트팩토리 / 맞춤형 AI 자율형 공장",
          image: require("@/assets/image/pictogram/factory1.png"),
        },
      ],

      mainContents: [
        {
          url: require("@/assets/image/background/home3.jpg"),
          type: "image",
          title: this.$t('메인타이틀3'),
          subtitle: this.$t('메인타이틀3_설명')      
        },
        {
          url: require("@/assets/image/background/home5.jpg"),
          type: "image",
          title: this.$t('메인타이틀'),
          subtitle: this.$t('메인타이틀_설명')
        },
        {
          url: require("@/assets/image/background/home4.jpg"),
          type: "image",
          title: this.$t('메인타이틀2'),
          subtitle: this.$t('메인타이틀2_설명')        
        },
      ],
      activeSlideIndex: 0,
      prevSlideIndex: -1,
      slideIntervalTime: 0
    };
  },
  creted() {},
  mounted() {
    setInterval(() => {
      if(this.slideIntervalTime > 5000) {
        this.nextSlide();
      }
      this.slideIntervalTime += 1000
    }, 1000);

    window.addEventListener("scroll", this.scrollEvent);

    this.getContents();
    this.getProducts();
  },
  unmounted() {
    window.removeEventListener("scroll", this.scrollEvent);
  },
  watch: {},
  computed: {},
  methods: {
    prevSlide() {
      this.prevSlideIndex = this.activeSlideIndex;
      this.activeSlideIndex =
        this.activeSlideIndex > 0
          ? this.activeSlideIndex - 1
          : this.mainContents.length - 1;
    },

    nextSlide() {
      this.prevSlideIndex = this.activeSlideIndex;
      this.activeSlideIndex =
        this.activeSlideIndex >= this.mainContents.length - 1
          ? 0
          : this.activeSlideIndex + 1;
      
      this.slideIntervalTime = 0
    },

    scrollTop() {
      window.scroll({
        behavior: "smooth",
        left: 0,
        top: 0,
      });
    },

    scrollEvent() {
      const scrollPosition = window.scrollY;

      const sections = document.querySelectorAll(".main-section");
      let minDistance = Infinity;
      let activeIndex = null;

      sections.forEach((section, index) => {
        const distance = Math.abs(scrollPosition - section.offsetTop);
        if (distance < minDistance) {
          minDistance = distance;
          activeIndex = index + 1;
        }
      });

      this.activeSectionIndex = activeIndex;
    },

    scrollToSection(index) {
      const targetSection =
        document.querySelectorAll(".main-section")[index - 1];

      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - 70,
          behavior: "smooth",
        });
      }
    },

    drawBackground() {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = window.innerWidth;
      canvas.height =window.innerHeight;

      ctx.font =
        "32px bold Apple SD Gothic Neo, Pretendard-Regular, NotoSansKR, sans-serif ";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = "#C4182804";
      ctx.beginPath();
      ctx.moveTo(canvas.width, 0);
      ctx.lineTo(canvas.width * 0.7, 0);
      ctx.lineTo(canvas.width, canvas.height * 0.8);
      ctx.fill();

      ctx.fillStyle = "#C4182803";
      ctx.beginPath();
      ctx.moveTo(canvas.width, canvas.height);
      ctx.lineTo(canvas.width * 0.7, canvas.height);
      ctx.lineTo(canvas.width, canvas.height * 0.5);
      ctx.fill();

      ctx.fillStyle = "#C4182803";
      ctx.beginPath();
      ctx.moveTo(0, canvas.height);
      ctx.lineTo(canvas.width * 0.3, 0);
      ctx.lineTo(0, canvas.height * 0.2);
      ctx.fill();

      ctx.fillStyle = "#C4182802";
      ctx.beginPath();
      ctx.moveTo(0, canvas.height);
      ctx.lineTo(canvas.width * 0.3, canvas.height);
      ctx.lineTo(0, canvas.height * 0.5);
      ctx.fill();

      let src = canvas.toDataURL("image/png", 1.0);
      document.body.style.backgroundImage = "url(" + src + ")";
    },

    async getContents() {
      this.loadingContents = true;
      await this.$axios
        .get(
          "/content?category=NOTICE&category=SERVICE&category=STORY&category=PEOPLE&locale=" +
            this.$i18n.locale.toUpperCase()
        )
        .then((res) => {
          this.storyContents = [];

          let contents = res.data.filter((e) => !e.hidden && e.image);
          for (let i = 0; i < contents.length; i++) {
            if (
              contents[i].category === "NOTICE" ||
              contents[i].category === "STORY"
            ) {
              this.storyContents.push(contents[i]);
            }
          }
        });
      this.loadingContents = false;
    },

    async getProducts() {
      this.loadingProducts = true;

      await this.$axios
        .get("/product")
        .then((res) => {
          this.products = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      this.loadingProducts = false;
    },

    onClickCard(item) {
      if (item.isExternalLink) {
        this.$utils.openLink(item.externalUrl);
      } else {
        this.$router.push("/news/details?id=" + item.id + "&list=/news");
      }
    },

    onClickServiceCard(item) {
      if (!item.id) {
        this.$router.push("/preparation");
      } else if (item.isExternalLink) {
        this.$utils.openLink(item.externalUrl);
      } else {
        this.$router.push("/content?id=" + item.id + "&list=/service");
      }
    },
  },
};
</script>

<style lang="scss">
* {
  // border: 1px solid;
}
.main-layout {
  position: relative;
  margin-top: -$header-height;
  height: 100%;
  width: 100%;
  max-width: 100%;

  @media screen and (max-width: #{$breakpoint-desktop}) {
    .main-section {
      padding: 0 10px;
    }
    .scroll-navigation {
      display: none;
    }
  }

  .scroll-navigation {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    padding: 20px;
    z-index: 99;
    flex-direction: column;

    .nav-button {
      height: 16px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 1px 0;

      button {
        border: none;
        cursor: pointer;
        padding: 0;
        height: 7px;
        width: 7px;
        background-color: $base-primary-high-opacity-color;
        border-radius: 0.15rem;
        transition: all 0.2s ease-in-out;
      }
      .vhp-text {
        opacity: 0;
        margin-right: 6px;
        margin-top: 2px;
        font-family: "Pretendard-Black";
        font-size: 0.6rem;
      }
      &:hover {
        button {
          background-color: $base-primary-opacity-color;
          transform: scale(1.4);
        }
        .vhp-text {
          color: $base-primary-opacity-color;
          opacity: 1;
        }
      }
      &.active {
        button {
          background-color: $base-primary-color;
          transform: scale(1.4);
        }
        .vhp-text {
          margin-right: 8px;
          color: $base-primary-color;
          opacity: 1;
          transform: scale(1.1);

          &.hide {
            opacity: 0;
          }
        }
      }
    }
  }

  .main-section {
    z-index: 1;
    position: relative;
    min-height: calc(100vh - 70px);
    height: calc(100vh - 70px);

    @media screen and (max-height: 900px) {
      max-height: unset;
      height: unset;
    }

    &.vh {
      padding-bottom:10px;
      max-height: calc(100vh);
      min-height: calc(100vh);
      height: calc(100vh);
    }

    &.unlimited {
      max-height: auto;
      min-height: auto;
      height: auto;
    }

    .main-background {
      height: 100%;
      position: absolute;
      left: calc(50% - 50vw);
      z-index: -10;
      width:100vw;
      overflow: hidden;
      

      .overlay {
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.75);
        z-index: 1;

        &.dark {
          background-color: rgba(0, 0, 0, 0.85);
        }
      }

      .home-image {
        position: absolute;
        top: 0;
        left:0;
        width: 100vw;
        height: 100%;
        object-fit: cover;
        transition: all .2s ease-in-out;
        animation: zoom_in_main 15000ms 1;
        &.active {
          // animation-name: fade_right;
          // animation-duration: 800ms;
          // animation-timing-function: ease-in-out;

          animation: fade_right 800ms 1, zoom_in_main 15000ms 1;

        }

      }

      .color {
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100%;
      }
    }

    .section-wrap {
      position: relative;

      max-width: $full-width;
      padding: 20px 0;
      margin: 0 auto;
      z-index: 2;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      align-content: center;

      &.vh {
        height: calc(100vh);
        padding: 0;
      }

      .section {
        min-width: 100%;
        max-width: 100%;
        max-height: 100%;
        padding: 20px 0;


        .home-banner {
          opacity: 0.95;
          height:200px;

          .main-slide-button-wrap {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            justify-items: center;
            flex-direction: row;
            justify-content: flex-start;
            width:100%;
            .slide-sequence {
              padding: 0;
              margin: 6px 6px 6px 12px;
              height: 32px;
              display: inline-flex;
              flex-direction: row;
              align-items: center;
              span {
                cursor: pointer;
                width: 20px;
                height: 3px;
                background-color: $base-button-default-background-color;
                opacity: 0.6;
                transition: all 0.2s ease-in-out;
                display: inline-block;
                margin: 0 0;

                &.active {
                  background-color: $base-primary-color;
                }
              }
            }
            .slide-button {
              padding: 0;
              display: inline-block;
              margin: 6px;
              height: 32px;
              width: 32px;
              border-radius: 100%;
              position: relative;
              cursor: pointer;
              border:1.5px solid $base-background-color;
              opacity: 0.5;

              span {
                width: 8px;
                height: 2px;
                position: absolute;
                top: calc(50% + 1.5px);
                left: 11px;
                transition: all 0.1s ease-in-out;
                background-color: $base-background-color;
                transform: rotate(45deg);

                &:nth-of-type(2) {
                  top: calc(50% - 3px);
                  right: 11px;
                  transform: rotate(-45deg);
                }
              }

              &.prev {
                transform: rotate(0);
                &:active {
                  transform: scale(0.95);
                }
              }

              &.next {
                transform: rotate(180deg);
                &:active {
                  transform: scale(0.95) rotate(180deg);
                }
              }

              &.disabled {
                opacity: 0.5;
              }
            }
          }
        }

        @keyframes scroll_down {
          0% {
            opacity: 1;
            transform: translateY(0);
          }
          100% {
            opacity: 1;
            transform: translateY(400%);
          }
        }

        &.center {
          margin: auto;
        }

        &.left {
          position: relative;
          margin-right: auto;
          text-align: left;
        }

        &.top {
          margin-top: 0;
          margin-bottom: auto;
        }

        .section-title {
          text-align: center;
          margin: 10px auto 30px;
        }

        .section-image {
          width: 100%;
          max-width: 1000px;
        }

        .section-content {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          max-width: 100%;
          padding: 10px 0;

          .row {
            max-height: 100%;
            max-width: 100%;
            height: 100%;
            width: 100%;
            display: flex;
            flex-flow: row;
            justify-content: center;

            &.dense {
              min-height: 300px;
              height: 100%;
            }

            &.grid-2 {
              display: grid;
              grid-template-columns: repeat(2, 1fr);

              @media screen and (max-width: #{$breakpoint-desktop}) {
                grid-template-columns: repeat(1, 1fr);
                gap: 20px;
                .main-card {
                  &.hide {
                    display: none;
                  }
                }
              }

              &.gap {
                gap: 20px 24px;
              }
              .box {
                width: 100%;

                &.left {
                  align-items: flex-start;
                }
              }
              .main-card {
                width: 100%;
                height: 100%;
              }
            }

            &.grid-4 {
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              gap: 20px 24px;

              .box {
                width: 100%;
              }
            }
          }

          .box-container {
            width:100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .icon-wrap{
              position: absolute;
              left: -12px;
            }
            .box {
              flex: 1 1 calc(33% - 36px);
              position: relative;
              &.round {
                .round-wrap {
                  position: relative;
                  strong {
                    font-size: 1rem;
                  }
                  .box-content {
                    margin-top: 8px !important;
                    .vhp-text {
                      display: none;
                    }
                  }

                }
              }
            }

            @media screen and (max-width: #{$breakpoint-md}) {
                .box{
                  flex: 1 1 calc(100% - 24px);
                }
                .icon-wrap{
                  position: static;
                  left: 0;
                }
            }
          }

        }

      }
    }

    .main-title {
      font-family: "Pretendard-Bold";
      letter-spacing: -0.01em;
      font-size: 4rem;
      color: $base-background-color;
      line-height: 1.2;

      @media screen and (max-width: #{$breakpoint-desktop}) {
        font-size: 3.2rem;
      }
      @media screen and (max-width: #{$breakpoint-tablet}) {
        font-size: 2.4rem;
      }
      @media screen and (max-width: #{$breakpoint-md}) {
        font-size: 2.1rem;
      }
    }

    .main-description {
      max-width: 100%;
      width: 800px;
      margin: 20px 0 40px 0;
      font-size: 1.5rem;

      @media screen and (max-width: #{$breakpoint-desktop}) {
        font-size: 1.3rem;
      }
      @media screen and (max-width: #{$breakpoint-tablet}) {
        font-size: 1.1rem;
      }
      @media screen and (max-width: #{$breakpoint-md}) {
        font-size: 1.05rem;
      }
    }

    .sub-title {
      font-size: 3rem;
      font-family: "Pretendard-Bold";

      &.underline {
        bottom: 0;
        line-height: normal;
        display: inline-block;
        position: relative;
        z-index: 10;

        &::before {
          width: 100% !important;
          bottom: calc(20%) !important;
          height: 18px !important;
          background-color: $base-primary-opacity-color;
          transition: all 0.3s ease-in-out;
          content: "";
          position: absolute;
          opacity: 0.4;
          transform: rotate(-0.5deg);
          z-index: -1;
        }
      }
    }

    .highlight {
      padding: 3px;
      &.blue {
        background-color: $color-blue;
        color: $base-background-color;
      }
    }

    .main-card {
      max-width: calc(100% - 24px);
      margin: 12px;
      border-radius: 1.2rem;
      box-shadow: 2px 4px 32px #3a3a3c16;
      background-color: $base-background-color;
      background: linear-gradient(
        to right bottom,
        $base-background-color,
        #fafbfc
      );
      color: $base-foreground-color;

      transition: all 0.2s ease-in-out;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;

      position: relative;
      overflow: hidden;

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }

      &.no-shadow {
        box-shadow: none !important;
      }

      &.transparent {
        background: none;
        background-color: transparent;
      }

      &.outline {
        box-shadow: none !important;
        box-sizing: border-box;
        border: 1px solid $base-border-color !important;
      }

      .loader {
        background-color: $base-border-color !important;
        height: 100%;
        min-height: 16px;
        width: 60px;
        display: inline-block;
        border-radius: 6px;
        position: relative;

        &.image {
          height: 35vh;
          border-radius: 0;
        }
        &.tag {
          width: 30px;
        }
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transform: translateX(-100%);
          background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
          );
          animation: shimmer 2s infinite;
          content: "";
        }
      }

      h1 {
        font-size: 32px;
        display: inline-block;
        margin: 12px 0;
        text-align: left;
      }

      h2 {
        font-size: 20px;
        line-height: 32px;
        margin: 12px 0;
        display: inline-block;

        .small & {
          font-size: 14px;
        }
      }

      @media screen and (max-width: #{$breakpoint-tablet}) {
        h1 {
          font-size: 21px;
          white-space: pre-wrap;
          text-overflow: unset;
          margin: 0;
        }

        h2 {
          font-size: 18px;
          line-height: 24px;
          margin: 0;
        }
      }

      @media screen and (max-width: #{$breakpoint-md}) {
        margin: 8px 0px;
        width: calc(100% - 0px);
        max-width: calc(100% - 0px);
        border-radius: 0 !important;
        border-top: 1px solid rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);

        .col-50 & {
          margin: 8px;
          width: calc(100% - 16px);
          max-width: calc(100% - 16px);
        }

        h1 {
          font-size: 18px;
        }

        h2 {
          font-size: 16px;
        }

        .card-wrap {
          .card-title {
            padding: 12px 0 0 0 !important;
            margin: 0 12px !important;
            width: calc(100% - 24px) !important;
          }

          .card-content {
            padding-left: 12px !important;
            padding-right: 12px !important;
            width: calc(100% - 24px) !important;
          }

          .card-bottom {
            padding: 12px 0 12px 0 !important;
            margin: 0 12px !important;
            width: calc(100% - 24px) !important;
          }
        }
      }

      .vhp-button {
        font-weight: bold;
        font-size: 14px;
        margin-top: 12px;
      }

      &.dark {
        color: #fff;
        .vhp-button {
          color: $base-foreground-color;
        }
      }

      &:not(.read-only) {
        cursor: pointer;
        &:hover {
          transition: all 0.2s ease-in-out;
          transform: translateY(-5px);
          box-shadow: rgba(149, 157, 165, 0.2) 0px 16px 32px;

          &.small {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 16px;
          }

          .card-right {
            opacity: 1 !important;
          }
        }
      }

      .detail-pointer {
        cursor: pointer;
        text-decoration: underline;
        margin: 0 3px;
        opacity: 0.8;
        display: inline-block;
        color: $base-primary-color;
      }

      &.small {
        box-shadow: 0px 2px 12px #3a3a3c13;
        border-radius: 0.8rem;

        .card-wrap {
          .card-title {
            padding: 10px 0 0 0;
            margin: 0 18px;
            width: calc(100% - 36px);
          }

          .card-content {
            padding-left: 18px;
            padding-right: 18px;
            width: calc(100% - 36px) !important;
          }

          .card-bottom {
            padding: 9px 0 18px 0;
            margin: 0 18px;
            width: calc(100% - 36px) !important;
          }
        }
      }

      &.square {
        aspect-ratio: 1;
        .card-wrap {
          width: 100%;
          height: 100%;

          &.back {
            position: absolute;
            top: 0;
            opacity: 0;
          }
        }

        .card-background {
          width: 100%;
          height: 100%;
          object-fit: contain;
          aspect-ratio: 1;
          z-index: 1;
        }

        .card-content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-content: center;
          text-align: center !important;

          .card-bottom {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .card-wrap {
        padding: 0;
        margin: 0;
        border-radius: inherit;
        height: 100%;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        position: relative;

        .card-image-overflow {
          position: absolute;
          right: 0px;
          bottom: 0px;
          max-width: 50%;
          width: 50%;
          height: 100%;
          background-position: right bottom;
          background-size: contain;
          background-repeat: no-repeat;
          z-index: 0;

          &.story {
            height: 80%;
            right: 0;
            bottom: 0;
          }
        }

        .card-title {
          text-align: left;
          position: relative;
          width: calc(100% - 48px);
          padding: 24px 0 0 0;
          margin: 0 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .card-content {
          text-align: left;
          padding: 6px 24px 6px 24px;
          width: calc(100% - 48px);
          z-index: 10;
          position: relative;
          font-size: 14px;
          max-width: calc(100% - 48px) !important;

          p {
            margin: 0 0 4px 0;
            font-size: 12px;
            color: $base-foreground-sub-color;
          }

          img {
            max-width: 100%;
            object-fit: cover;
            margin: auto;
            display: block;
          }

          .image-container {
            height: 128px;
            position: relative;
            display: block;
            padding: 12px 0;
            max-width: 100%;
            overflow: hidden;

            img {
              max-height: 100%;
            }

            @media screen and (max-width: #{$breakpoint-tablet}) {
              height: 96px;
            }
          }

          .people-image {
            position: absolute;
            border-radius: 100%;
            overflow: hidden;
            width: 96px;
            height: 96px;
            display: inline-block;
            margin: 0 24px;
            top: calc(50% - 48px);

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: inline-block;
            }
          }
        }

        .card-right {
          height: 100%;
          white-space: nowrap;
          text-align: center;
          display: inline-flex;
          flex-direction: row;
          justify-items: flex-end;
          align-items: center;

          .vhp-button {
            padding: 0;
            width: 32px;
            height: 32px;

            img {
              object-position: center;
              object-fit: contain;
              width: 16px;
              height: 16px;
              margin-top: 8px;
            }
          }
        }

        .card-content-tags {
          width: 100%;
          opacity: 0.7;
          font-size: 11px;
          display: flex;
          justify-content: flex-start;

          .tag {
            background-color: $base-border-color;
            margin: 3px 3px 3px 0;
            padding: 3px 8px;
            border-radius: 6px;
            display: inline-block;
          }
        }

        .card-bottom {
          position: relative;
          padding: 12px 0 24px 0;
          margin: 0 24px;

          width: calc(100% - 48px);
          font-size: 12px;

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .card-background {
          min-height: 200px;
          width: 100%;
          max-width: 100%;
          margin: 0;
          z-index: -1;
          background-size: cover contain;
          background-repeat: no-repeat;
          object-fit: cover;
        }

        .card-video {
          position: relative;
          width: 100%;
          height: 100%;

          iframe {
            min-width: 560px;
            min-height: 315px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .robot-product,
    .welding-product {
      margin: 16px 12px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px 24px;
    }

    .box {
      margin: 16px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 200px;
      z-index: 0;
      &.gradient {
        background: linear-gradient(
          rgba(245, 246, 250, 0.5),
          rgba(234, 235, 253, 0.3)
        );
      }
      &.blue {
        background-color: rgba($color-blue, 0.3);
      }
      &.skyblue {
        background: #f5f6fa;
      }
      &.pink {
        color: $base-background-color;
        background-color: $base-primary-opacity-color;
      }
      &.red {
        color: $base-background-color;
        background-color: $base-primary-color;
      }
      &.transparent {
        background-color: transparent;
        background: none;
      }
      &.purple {
        background: linear-gradient(
          to top,
          rgba(250, 249, 255, 0.95),
          rgba(250, 249, 255, 0.6)
        );
      }
      &.round {
        border-radius: 100%;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: normal;
        min-height: auto;
        height: 215px;
        width: 215px;
        aspect-ratio: 1;

        .round-wrap {
          position: relative;
          width: 190x;
          height: 190px;
          aspect-ratio: 1;
          border-radius: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      &.outline {
        border: 1px solid $base-foreground-opacity-color;
      }
      &.icon-wrap {
        min-height: auto;
        height: 250px;
        width: 35px;
        max-height: 250px;
        max-width: 35px;
        i {
          font-size: 20px;
        }
      }
    }

    .product-item {
      display: flex;
      border-radius: 0.5rem;
      padding: 20px;

      img {
        max-width: 400px;
        width: 100%;
        height: auto;
        aspect-ratio: 1;
        object-fit: cover;
      }
      .product-description {
        min-width: 400px;

        width: 100%;
        text-align: left;
        margin-left: 50px;

        img {
          max-width: 100px;
          width: 100%;
          height: auto;
          aspect-ratio: 1;
          object-fit: cover;
          border-radius: 0.5rem;
          margin: 5px;
        }
      }
    }
  }
}
</style>
