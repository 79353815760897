<template>
  <div class="admin-page admin-history">
    <h1>회사연혁</h1>

    <div class="menus">
      <button @click="$refs.createHistoryDialog.load()" class="vhp-button">
        연혁 추가
      </button>
    </div>

    <vhp-table
      :headers="[
        {
          text: '년도',
          value: 'year',
        },
        {
          text: '월',
          value: 'month',
        },
        {
          text: '일자',
          value: 'historyDate',
        },
        {
          text: '내용',
          value: 'content',
        },
        {
          text: '작성일',
          value: 'updatedDate',
        },
        {
          text: '수정',
          value: 'edit',
        },
        {
          text: '삭제',
          value: 'delete',
        },
      ]"
      :items="historyList"
    >
      <template v-slot:item-edit="{ item }">
        <img
          v-if="item.username !== 'admin'"
          @click="(edit = item), $refs.editHistoryDialog.load()"
          src="@/assets/image/edit.svg"
          height="15"
          alt="lock-icon"
          class="pointer"
        />
      </template>

      <template v-slot:item-delete="{ item }">
        <img
          v-if="item.username !== 'admin'"
          @click="deleteHistory(item)"
          src="@/assets/image/trash-can-outline.svg"
          height="20"
          alt="lock-icon"
          class="pointer"
        />
      </template>
    </vhp-table>

    <vhp-dialog
      title="연혁 추가"
      id="create-history"
      class="create-dialog"
      ref="createHistoryDialog"
      width="370"
      height="400"
    >
      <template v-slot:content>
        <div>
          <form @submit.prevent="createHistory" method="post">
            <input
              v-model="history.historyDate"
              placeholder="일자"
              type="date"
              maxlength="50"
              required
            />
            <input
              v-model="history.content"
              placeholder="내용"
              type="text"
              maxlength="200"
              required
            />
            <button class="save-btn vhp-button">추가</button>
          </form>
        </div>
      </template>
    </vhp-dialog>

    <vhp-dialog
      title="연혁 수정"
      id="edit-history"
      class="create-dialog"
      ref="editHistoryDialog"
      width="370"
      height="400"
    >
      <template v-slot:content>
        <div>
          <form @submit.prevent="editHistory" method="put">
            <input
              v-model="edit.historyDate"
              placeholder="일자"
              type="date"
              maxlength="50"
              required
            />
            <input
              v-model="edit.content"
              placeholder="내용"
              type="text"
              maxlength="200"
              required
            />
            <button class="save-btn vhp-button">수정</button>
          </form>
        </div>
      </template>
    </vhp-dialog>
  </div>
</template>

<script>
export default {
  name: "AdminHistory",
  data: () => {
    return {
      historyList: [],
      history: {
        historyDate: "",
        content: "",
        locale: "KR",
        category: "HISTORY",
      },
      edit: {
        id: "",
        historyDate: "",
        content: "",
      },
      years: [],
      selectedYear: 0,
    };
  },
  methods: {
    async getHistoryList() {
      await this.$axios
        .get("/history")
        .then((res) => {
          this.historyList = res.data.sort(
            (a, b) => new Date(b.historyDate) - new Date(a.historyDate)
          );
          this.years = [...new Set(res.data.map(item => item.year))];
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async createHistory() {
      if (!this.history.historyDate || !this.history.content) {
        alert("값을 모두 입력해주세요.");
        return;
      }
      this.$axios
        .post("/history", this.history)
        .then(() => {
          window.alert("추가되었습니다.");
          this.history.historyDate = "";
          this.history.content = "";
          this.$refs.createHistoryDialog.close();
          this.getHistoryList();
        })
        .catch((err) => {
          if (err.response.data === "DUPLICATED") {
            window.alert("해당 연혁이 이미 존재합니다.");
          } else {
            window.alert("콘텐츠를 추가할 수 없습니다. 개발팀에 문의하세요.");
          }

          console.log("SIGNUP ERROR : ", err);
        });
    },

    async editHistory() {
      this.$axios
        .put("/history", this.edit)
        .then(() => {
          window.alert("수정되었습니다.");
          this.$refs.editHistoryDialog.close();
          this.getHistoryList();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async deleteHistory(item) {
      const res = window.confirm("콘텐츠를 삭제하시겠습니까?");
      if (!res) return;

      this.$axios
        .delete("/history", {
          params: {
            id: item.id,
          },
        })
        .then(() => {
          this.getHistoryList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getHistoryList();
  },
};
</script>

<style lang="scss">
.admin-page.admin-history {
  .menus {
    margin: 30px 0;
    text-align: right;
  }

  .create-dialog {
    input {
      border: none;
      margin-bottom: 20px;
      background: #bc1d2d14;
      padding: 10px 15px;
      border-radius: 0.4rem;
    }

  
  }

  form{
    display: flex;
    flex-direction: column;
  }
}
</style>
