<template>
  <div class="admin-page admin-log">
    <h1>활동 로그</h1>      

    <vhp-table 
      :headers="[{
          text:'분류',
          value:'type'
        },{
            text:'작성자',
            value:'actor'
        },{
            text:'요청일시',
            value:'requestDate'
        },{
            text:'대상(이름 또는 제목)',
            value:'target'
        },{
            text:'작업',
            value:'method'
        }]" 
      :items="logList"
    >
    </vhp-table>
  </div>
</template>

<script>
export default {
  name: 'AdminLog',
  data: () => {
    return {
      logList: [],
    }
  },
  methods: {
    getLogList(){
      this.$axios.get('/log')
      .then(res=>{
        const rawList = res.data
        
        rawList.forEach(item => {
          const {url, actor, requestDate, method} = item
          let log = {type: '', actor, requestDate, target: '', method: ''}
          
          // set log type
          if(url.indexOf('/content') !== -1){
            log.type = '콘텐츠'
          } else if(url.indexOf('/auth') !== -1){
            log.type = '계정'
          }
          
          // set log target
          log.target = item.content?.title || item.account?.username
          
          // set log method
          if(method === 'POST'){
            log.method = '생성'
          } else if(method === 'PUT'){
            log.method = '수정'
          } else if(method === 'DELETE'){
            log.method = '삭제'
          }
          
          this.logList.push(log)
        });
      })
      .catch(err=>{
        console.log(err)
      })
    },

  },
  mounted() {
    this.getLogList()
  },
}
</script>

<style lang="scss">
</style>
