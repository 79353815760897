<template>
  <div class="admin-page admin-account">
    <h1>계정 관리</h1>

    <div class="menus">
      <button @click="$refs.createAccountDialog.load()" class="vhp-button">
        계정 추가
      </button>
    </div>

    <vhp-table
      :headers="[
        {
          text: '이름',
          value: 'username',
        },
        {
          text: '가입일',
          value: 'createdDate',
        },
        {
          text: '비밀번호 변경',
          value: 'changePW',
        },
        {
          text: '계정 삭제',
          value: 'action',
        },
      ]"
      :items="accountList"
    >
      <template v-slot:item-changePW="{ item }">
        <button
          v-if="$store.state.admin.username === item.username"
          @click="$refs.changePasswordDialog.load()"
          class="vhp-button small"
        >
          비밀번호 변경
        </button>
      </template>
      <template v-slot:item-action="{ item }">
        <img
          v-if="item.username !== 'admin'"
          @click="deleteAccount(item.username)"
          src="@/assets/image/trash-can-outline.svg"
          height="20"
          alt="lock-icon"
          class="pointer"
        />
      </template>
    </vhp-table>

    <vhp-dialog
      title="계정 추가"
      id="create-account"
      class="create-dialog"
      ref="createAccountDialog"
      width="370"
      height="430"
    >
      <template v-slot:content>
        <div>
          <form @submit.prevent="addAccount" method="post">
            <input
              v-model="account.username"
              placeholder="이름"
              type="text"
              maxlength="50"
            />
            <input
              v-model="account.password"
              placeholder="비밀번호"
              type="password"
              maxlength="50"
            />
            <input
              v-model="password2"
              placeholder="비밀번호 확인"
              type="password"
              maxlength="50"
            />

            <button class="save-btn vhp-button">추가</button>
          </form>
        </div>
      </template>
    </vhp-dialog>

    <vhp-dialog
      title="비밀번호 변경"
      id="change-password"
      class="change-password"
      ref="changePasswordDialog"
      width="370"
      height="430"
    >
      <template v-slot:content>
        <div>
          <form @submit.prevent="changePW" method="post">
            <input
              v-model="changePWData.currentPW"
              placeholder="현재 비밀번호"
              type="password"
              maxlength="50"
            />
            <input
              v-model="changePWData.PW"
              placeholder="변경할 비밀번호"
              type="password"
              maxlength="50"
            />
            <input
              v-model="password2"
              placeholder="변경할 비밀번호 확인"
              type="password"
              maxlength="50"
            />

            <button class="save-btn vhp-button">저장</button>
          </form>
        </div>
      </template>
    </vhp-dialog>
  </div>
</template>

<script>
export default {
  name: "AdminIndex",
  data: () => {
    return {
      headers: ["번호", "이름", "가입일", ""],
      accountList: [],
      account: {
        username: "",
        password: "",
      },
      changePWData: {
        currentPW: "",
        PW: "",
      },
      password2: "",
    };
  },
  methods: {
    getAccounts() {
      this.$axios
        .get("/auth")
        .then((res) => {
          this.accountList = res.data.filter((a) => !a.deletedBy);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addAccount() {
      if (!this.account.username || !this.account.password || !this.password2) {
        alert("값을 모두 입력해주세요.");
        return;
      }

      if (this.account.password !== this.password2) {
        alert("비밀번호 입력값이 일치하지 않습니다. 다시 확인해주세요.");
        return;
      }

      this.$axios
        .post("/auth/signup", this.account, {
          params: { actorName: this.$store.state.admin.username },
        })
        .then(() => {
          window.alert("추가되었습니다.");
          this.$refs.createAccountDialog.close();
          this.getAccounts();
        })
        .catch((err) => {
          if (err.response.data === "DUPLICATED") {
            window.alert("해당 이름의 계정이 이미 존재합니다.");
          } else {
            window.alert("계정을 추가할 수 없습니다. 개발팀에 문의하세요.");
          }

          console.log("SIGNUP ERROR : ", err);
        });
    },
    changePW() {
      if (
        !this.changePWData.currentPW ||
        !this.changePWData.PW ||
        !this.password2
      ) {
        alert("값을 모두 입력해주세요.");
        return;
      }

      if (this.changePWData.PW !== this.password2) {
        alert("비밀번호 입력값이 일치하지 않습니다. 다시 확인해주세요.");
        return;
      }

      this.$axios
        .put(
          "/auth/changePW",
          {
            username: this.$store.state.admin.username,
            currentPW: this.changePWData.currentPW,
            password: this.changePWData.PW,
          },
          {
            params: { actorName: this.$store.state.admin.username },
          }
        )
        .then(() => {
          window.alert("변경되었습니다.");
          this.$refs.changePasswordDialog.close();
        })
        .catch((err) => {
          if (err.response.data === "INCORRECT") {
            window.alert("현재 비밀번호가 일치하지 않습니다.");
          } else {
            window.alert("비밀번호를 변경할 수 없습니다. 개발팀에 문의하세요.");
          }

          console.log("CHANGE PASSWORD ERROR : ", err);
        });
    },
    deleteAccount(targetName) {
      const res = window.confirm("계정을 삭제하시겠습니까?");
      if (!res) return;

      this.$axios
        .delete("/auth", {
          params: {
            targetName,
            actorName: this.$store.state.admin.username,
          },
        })
        .then(() => {
          this.getAccounts();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getAccounts();
  },
};
</script>

<style lang="scss">
.admin-page.admin-account {
  .menus {
    margin: 30px 0;
    text-align: right;
  }

  .create-dialog,
  .change-password {
    input {
      border: none;
      margin-bottom: 20px;
      background: #bc1d2d14;
      padding: 10px 15px;
      border-radius: 0.4rem;
    }

  }
  form{
    display: flex;
    flex-direction: column;
  }
}
</style>
