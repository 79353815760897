<template>
  <div>
    <div class="vhp-card-filter-container" v-if="!hideFilter">
      <ul v-if="tabs.length > 1">
        <li v-for="item in tabs" :key="item.value">
          <button
            class="vhp-button"
            @click="setTab(item.value)"
            :class="{ active: tab === item.value }"
          >
            {{ item.text }}
          </button>
        </li>
      </ul>

      <vhp-select
        v-model="sortBy"
        :items="[
          { text: $t('최신순'), value: 'updatedDate' },
          { text: $t('인기순'), value: 'like' },
        ]"
        item-key="text"
        item-value="value"
      />

      <span class="search-wrap">
        <div class="vhp-input">
          <input
            class="append-icon"
            type="text"
            name="search"
            id="search"
            v-model="searchKeyword"
            :placeholder="$t('검색어입력')"
          />
          <img
            class="icon"
            src="@/assets/image/magnify.svg"
            width="16"
            height="16"
            alt="magnify"
          />
        </div>
      </span>
    </div>

    <div class="vhp-products-layout-container">
      <vhp-empty v-if="filteredContents.length === 0 && !loading" />
      <vhp-product-card
        class="card-image"
        v-for="content in filteredContents.slice(0, viewLimit)"
        :key="content.id"
        :content="content"
        :base-url="baseUrl"
        :list-url="listUrl"
        :show-price="true"
        data-aos-duration="800"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-easing="ease-in-out"
      />

      <div class="vhp-card-more-container">
        <button
          class="vhp-button"
          @click="viewLimit += 9"
          v-if="filteredContents.length > viewLimit"
        >
          {{ $t("더 보기") }}
        </button>

        <button
          class="vhp-button"
          @click="scrollUp()"
          v-else-if="filteredContents.length > 12"
        >
          {{ $t("처음으로") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductListLayout",
  props: {
    contents: {
      type: Array,
      default: () => {
        return [];
      },
    },
    baseUrl: {
      type: String,
      default: "/",
    },
    listUrl: {
      type: String,
      default: "/",
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    disableScroll: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredContents() {
      let filteredContents = [];
      if (!this.tab || this.tab === "all") {
        filteredContents = this.contents.filter((e) =>
          this.searchKeyword
            ? (e.hashTags && e.hashTags.length > 0
                ? e.hashTags.filter(
                    (h) => h.indexOf(this.searchKeyword.toLowerCase()) !== -1
                  ).length > 0
                : false) ||
              e.title
                .toLowerCase()
                .indexOf(this.searchKeyword.toLowerCase()) !== -1 ||
              e.type.toLowerCase().indexOf(this.searchKeyword.toLowerCase()) !==
                -1 ||
              e.category
                .toLowerCase()
                .indexOf(this.searchKeyword.toLowerCase()) !== -1
            : true
        );
      } else {
        filteredContents = this.contents
          .filter((e) =>
            e.type.toLowerCase().indexOf(this.tab.toLowerCase()) !== -1
              ? true
              : false
          )
          .filter((e) =>
            this.searchKeyword
              ? (e.hashTags && e.hashTags.length > 0
                  ? e.hashTags.filter(
                      (h) => h.indexOf(this.searchKeyword.toLowerCase()) !== -1
                    ).length > 0
                  : false) ||
                e.title
                  .toLowerCase()
                  .indexOf(this.searchKeyword.toLowerCase()) !== -1 ||
                e.type
                  .toLowerCase()
                  .indexOf(this.searchKeyword.toLowerCase()) !== -1 ||
                e.category
                  .toLowerCase()
                  .indexOf(this.searchKeyword.toLowerCase()) !== -1
              : true
          );
      }
      if (this.sortBy) {
        if (this.sortBy === "like") {
          filteredContents.sort((a, b) => b.likeCount - a.likeCount);
        } else if (this.sortBy === "view") {
          filteredContents.sort((a, b) => b.viewCount - a.viewCount);
        }
      }
      return filteredContents;
    },
  },
  data() {
    return {
      searchKeyword: "",
      tab: "all",
      tabs: [],
      viewLimit: 9,
      loading: true,
      sortBy: "updatedDate",
    };
  },

  watch: {
    contents() {
      this.generateTabs(this.contents);
    },
  },

  mounted() {
    this.generateTabs(this.contents);
    setTimeout(() => {
      this.loading = false;
    }, 1000);

    this.setTab(this.$route.query.tag);

    // 무한 스크롤 로딩
    window.addEventListener("scroll", () => {
      let scrollY = window.innerHeight + window.scrollY;
      let bodyHeight = document.body.offsetHeight;
      let heightRate = window.innerHeight * 0.3;

      if (!this.disableScroll) {
        if (
          scrollY > bodyHeight - heightRate &&
          this.filteredContents.length > this.viewLimit
        ) {
          this.viewLimit += 9;
        }
      }
    });
  },

  methods: {
    scroll(e) {
      console.log(e);
    },
    scrollUp() {
      window.scrollTo(0, 0);
    },
    setTab(tab) {
      if (tab) this.tab = tab;
    },
    generateTabs(contents) {
      if (!this.contents) return;
      let tabs = [
        {
          text: this.$t("전체"),
          value: "all",
        },
      ];
      let tabMap = {};
      for (let i = 0; i < contents.length; i++) {
        if (contents[i].type) {
          let type = contents[i].type;
          tabMap[type] = type;
        }
        for (let tag in contents[i].hashTags) {
          tabMap[contents[i].hashTags[tag]] = contents[i].hashTags[tag];
        }
      }

      for (let tab in tabMap) {
        tabs.push({
          text: tab,
          value: tab,
        });
      }
      this.tabs = tabs;
    },
  },
};
</script>

<style lang="scss">
.vhp-products-layout-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 24px;

  @media screen and (max-width: #{$breakpoint-max}) {
    column-gap: 16px;
    row-gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: #{$breakpoint-desktop}) {
    column-gap: 16px;
    row-gap: 16px;
    margin: 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: #{$breakpoint-md}) {
    margin: 20px;
    grid-template-columns: repeat(1, 1fr);
  }

  .vhp-card-more-container {
    width: 100%;
    text-align: center;
    padding: 32px 0;

    > button {
      font-weight: bold;
      padding: 16px 32px;
    }
  }
}

.vhp-card-filter-container {
  padding: 0px 0 64px 0;
  text-align: left;
  margin: 0 auto 24px auto;
  position: relative;

  @media screen and (max-width: #{$breakpoint-md}) {
    margin: 24px 12px;
    width: calc(100% - 24px);
  }
  ul {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      margin: 4px 0;

      button {
        margin-right: 8px;
      }
    }
  }
  .select {
    margin: 4px 0 !important;
  }
  > .search-wrap {
    float: right;
    margin: 4px 0 !important;

    @media screen and (max-width: #{$breakpoint-md}) {
      width: 100%;
      .vhp-input {
        width: 100%;

        input {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }
  }
}
</style>
