<template>
  <div class="inquiry-container">
    <form
      class="vhp-contact-form"
      @submit="send()"
      :class="[dense ? 'dense' : '']"
    >
      <div class="vhp-contact-wrap half" :class="[dense ? 'dense' : '']">
          <div v-if="product && dense" class="vhp-input full dense">
            <div class="product-container">
              <label class="vhp-input-label" for="제품명">{{
                $t("제품명")
              }}</label>
              <div class="product-item" v-html="product"></div>
            </div>
          </div>

          <div class="vhp-input " :class="[dense ? 'dense' : '']">
            <label class="vhp-input-label" for="회사명">{{ $t("회사명") }}*</label>
            <input
              class="label"
              name="회사명"
              type="text"
              v-model="companyName"
              :placeholder="$t('회사명_설명')"
              required
            />
          </div>

          <div class="vhp-input " :class="[dense ? 'dense' : '']">
            <label class="vhp-input-label" for="이름">{{ $t("이름") }}*</label>
            <input
              class="label"
              name="이름"
              type="text"
              v-model="customerName"
              :placeholder="$t('이름_설명')"
              required
            />
          </div>


          <div class="vhp-input " :class="[dense ? 'dense' : '']">
            <label class="vhp-input-label" for="이메일">{{ $t("이메일") }}*</label>
            <input
              class="label"
              name="이메일"
              type="email"
              v-model="customerEmail"
              :placeholder="$t('이메일_설명')"
              required
            />
          </div>

          
          <div class="vhp-input " :class="[dense ? 'dense' : '']">
            <label class="vhp-input-label" for="연락처">{{ $t("연락처") }}</label>
            <input
              class="label"
              name="연락처"
              pattern="[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}"
              type="text"
              v-model="customerTel"
              :placeholder="$t('연락처_설명')"
              maxlength="13"
            />
          </div>

          <vhp-select
            v-if="!product"
            v-model="contactType"
            :items="contactTypeOptions"
            item-key="text"
            item-value="value"
            :placeholder="$t('문의유형_설명')"
            :label="$t('문의유형')"
            full-width
            :class="[dense ? 'dense' : '']"
          />

      </div>

      <div class="vhp-contact-wrap full" :class="[dense ? 'dense' : '']">
        <div class="vhp-input full" :class="[dense ? 'dense' : '']">
          <label class="vhp-input-label" for="문의내용">{{
            $t("문의내용")
          }}</label>
          <textarea
            class="label scroll"
            name="문의내용"
            type="text"
            v-model="contactContent"
            :placeholder="$t('문의내용_설명')"
            required
          />
        </div>

        <div
          v-if="!showSimple"
          class="vhp-input full"
          :class="[dense ? 'dense' : '']"
        >
          <label class="vhp-input-label" for="첨부파일">{{
            $t("첨부파일")
          }}</label>
          <input
            ref="attachment"
            id="attachment"
            type="file"
            class="label"
            :class="{ empty: !attachment }"
            name="첨부파일"
            @change="onAttachmentChange"
            accept=".doc, .docx, .pdf, .jpg, .png, .zip, .txt, .hwp, .gif"
          />
          <div class="close" @click="clearAttachment()" v-if="attachment">
            <span />
            <span />
          </div>
        </div>
      </div>

      <div class="vhp-contact-wrap full">
        <div>
          <div class="vhp-input" :class="[dense ? 'dense mt-2' : '']">
            <input
              type="checkbox"
              class="vhp-input"
              name="개인정보수집동의"
              v-model="customerPrivacy"
            />
            <label
              class="vhp-input-checkbox-detail font-bold pointer no-select"
              @click="customerPrivacy = !customerPrivacy"
              >{{ $t("개인정보이용동의") }}</label
            >
          </div>
          <span>
            <router-link to="/privacy"
              >{{ $t("개인정보 처리방침")
              }}<img
                alt="external-link"
                src="@/assets/image/external-link.svg"
                height="12"
                style="margin-left: 6px"
            /></router-link>
          </span>
        </div>

        <div
          class="vhp-contact-wrap full center"
          :class="[dense ? 'dense' : 'mt-16']"
        >
          <input
            :value="$t('문의하기')"
            type="submit"
            class="vhp-button"
            :disabled="!customerPrivacy"
            :class="{ active: customerPrivacy, xlarge: !dense }"
            style="width:180px;"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "InquiryLayout",
  props: {
    product: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    showSimple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {
      companyName: "",
      customerName: "",
      customerTel: "",
      customerEmail: "",
      contactType: "",
      customerPrivacy: false,
      contactContent: "",
      attachment: null,
      attachmentName: "",
      contactTypeOptions: [
        {
          text: this.$t("로봇 구매 문의"),
          value: "로봇 구매 문의",
        },
        {
          text: this.$t("로봇 솔루션 도입 문의"),
          value: "로봇 솔루션 도입 문의",
        },
        {
          text: this.$t("인공지능 솔루션 도입 문의"),
          value: "인공지능 솔루션 도입 문의",
        },
        {
          text: this.$t("투자 관련 문의"),
          value: "투자 관련 문의",
        },
        {
          text: this.$t("기타"),
          value: "기타 문의",
        },
      ],
    };
  },

  watch: {
    customerTel() {
      let validTel = this.customerTel.replaceAll(/[^0-9]/g, "");
      let replacedTel = "";
      if (validTel.length === 9) {
        for (let i = 0; i < validTel.length; i++) {
          if (i === 2 || i === 5) {
            replacedTel += "-";
          }
          replacedTel += validTel[i];
        }
        this.customerTel = replacedTel;
      } else if (validTel.length === 10) {
        for (let i = 0; i < validTel.length; i++) {
          if (i === 3 || i === 6) {
            replacedTel += "-";
          }
          replacedTel += validTel[i];
        }
        this.customerTel = replacedTel;
      } else {
        for (let i = 0; i < validTel.length; i++) {
          if (i === 3 || i === 7) {
            replacedTel += "-";
          }
          replacedTel += validTel[i];
        }
        this.customerTel = replacedTel;
      }
    },
  },

  methods: {
    send() {
      let formData = new FormData();

      let data = {
        name: this.customerName,
        companyName: this.companyName,
        phoneNumber: this.customerTel,
        email: this.customerEmail,
        contactType: this.contactType,
        productName: this.product,
        privacy: this.customerPrivacy,
        content: this.contactContent,
        attachment: this.attachment,
        attachmentName: this.attachmentName,
      };

      for (let k in data) {
        formData.append(k, data[k]);
      }

      event.preventDefault();

      this.$axios
        .post("/inquiry", formData)
        .then(() => {
          alert("성공적으로 전송되었습니다.");
        })
        .catch((err) => {
          console.log(err);
          alert("오류가 발생하였습니다. 다시 시도해주세요.");
        });
    },

    clearAttachment() {
      this.$refs.attachment.value = "";
      this.attachment = null;
    },

    onAttachmentChange(e) {
      let file = e.target.files[0] || e.dataTransfer.files[0];

      if (!file) {
        return;
      }

      if (file.size > 2097152) {
        alert("첨부 파일은 2MB를 초과할 수 없습니다.");
        e.target.value = "";
        return;
      }
      const extension = file.name.substring(file.name.lastIndexOf("."));
      const accept = [
        ".doc",
        ".docx",
        ".pdf",
        ".jpg",
        ".png",
        ".zip",
        ".txt",
        ".hwp",
        ".gif",
      ];
      if (accept.indexOf(extension) === -1) {
        alert(
          ".doc, .docx, .pdf, .jpg, .png, .zip, .txt, .hwp, .gif 유형의 파일만 첨부할 수 있습니다."
        );
        e.target.value = "";
        return;
      }

      this.attachmentName = file.name;
      this.attachment = file;

      // const reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = () => {
      //   let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
      //   if (encoded.length % 4 > 0) {
      //     encoded += "=".repeat(4 - (encoded.length % 4));
      //   }
      //   this.attachment = encoded;
      // };
      // reader.onerror = (err) => {
      //   alert("업로드에 오류가 발생하였습니다.");
      //   e.target.value = "";
      //   console.log(err);
      // };
    },
  },
};
</script>

<style lang="scss">
.inquiry-container {
  margin: 12px;
  padding: 0;
  width:calc(100% - 24px);

  .vhp-contact-form {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: auto;
    column-gap: 10px;

    &.dense {
      flex-direction: column;
      padding: 16px 0;
    }
  }

  .vhp-contact-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: auto;
    column-gap: 12px;

    &.half{
      .vhp-input {
        flex: 1 1 calc(50% - 6px);
      }
    }

    &.full {
      flex: 0 1 calc(100%);
    }
    &.center {
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    &.dense {
      padding: 0;
    }
    @media screen and (max-width: #{$breakpoint-md}) {
      .vhp-input {
        flex: 1 1 calc(100%) !important;
      }
      
    }

    text-align: left;

    .vhp-input {
      input[type="text"],
      input[type="email"],
      input[type="file"],
      textarea,
      select {
        padding-top: 18px;
        padding-bottom: 18px;
      }
      textarea {
        height: 226px;
        margin: 0;
      }
      margin: 0 0 16px 0;

      &.dense {
        * {
          font-size: 0.9rem;
        }
        input[type="text"],
        input[type="email"],
        input[type="file"],
        textarea,
        select {
          padding-top: 14px;
          padding-bottom: 14px;
          font-size: 0.9rem;
        }
        textarea {
          height: 100px;
          margin: 0;
          width: 100%;
        }
        margin: 0 0 10px 0;
      }
    }

    .product-container {
      position: relative;
      border: 0;
      outline: none;
      min-height: 18px;
      width: 100%;
      margin: 0;
      border-radius: 0.5rem;
      background-color: $base-button-default-background-color;

      .product-label {
        position: absolute;
        top: calc(50% - 20px);
        left: 20px;
        font-family: "Pretendard-Bold";
        width: 64px;
        font-size: 0.9rem;
        margin: 0;
      }

      .product-item {
        padding: $base-button-padding;
        max-height: 100px;
        overflow-y: auto;
        border-radius: 0.5rem;
        padding-left: 86px;
        font-size: 0.9rem;
      }
    }
  }
}
</style>
