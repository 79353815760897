<template>
  <div class="admin-page admin-index-container">
    <div class="admin-index-box">
      <div class="logo">
        <img @click="$router.push('/')" src="@/assets/image/logo-color.png" height="36" alt="logo-icon"/>
      </div>
      <form @submit.prevent="submit" method="post">
        <input id="nameInput" type="text" maxlength="30" v-model="username" placeholder="아이디">
        <input type="password" maxlength="30" v-model="password" placeholder="비밀번호">
        <button class="vhp-button">관리자 로그인</button>

      </form>

      <a >문의 programming@vazilcompany.com</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AdminIndex',
  data: () => {
    return {
      username: '',
      password: '',
    }
  },
  methods: {
    submit(){
      if(!this.username || !this.password){
        alert('아이디와 비밀번호를 입력하세요.')
        return
      }
      
      this.$axios.post('/auth', {
        username: this.username,
        password: this.password
      })
      .then((res)=>{
        if(res.data.accessToken){
          this.$store.commit('setFrontToken', this.$CryptoJS.AES.encrypt(process.env.VUE_APP_VALIDATION_TEXT, process.env.VUE_APP_SECRET_KEY).toString())
          this.$store.commit('setApiToken', `Bearer ${res.data.accessToken}`)
          this.$store.commit('setAdmin', {username: this.username})
          this.$axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`

          let route = this.$router.resolve({name: 'adminConsole'});
          window.open(route.href, '_self');
        }
      })
      .catch(err=>{
        console.log("LOGIN ERROR : " , err)
        window.alert('승인되지 않은 계정입니다.')
      })
    },
  },
  mounted() {
    document.querySelector('#nameInput').focus()

  },
}
</script>

<style lang="scss">

.admin-page.admin-index-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  color: white;
  position: relative;

  &::after{
    content:'';
    position: fixed;
    left:calc(50% - 50vw);
    top:0;
    z-index: -1;
    width:100vw;
    height:100vh;
    background-image:url('@/assets/image/background/bg32.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .admin-index-box {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding:36px;

    .logo {
      margin-bottom: 36px;
      object-fit: contain;
      height: 36px;
      display: inline-flex;
      justify-content: center;
      
      img {
        margin-bottom: 36px;
        display: inline-flex;
        object-fit: contain;
        cursor: pointer;
        height: 36px;
      }
    }

    a {
      margin-top:12px;
      font-size: 13px;
      display: inline-flex;
      align-self: center;
      opacity: 0.7;
    }
  
    form {
      * {
        font-size: 16px;
      }
      
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      border: 1px solid $base-border-color;
      border-radius: 0.35rem;
      width:320px;
      box-shadow: 2px 4px 64px #3a3a3c10;
      background-color: $base-background-opacity-color;

      input{
        width:100%;
        padding:12px 24px;
        outline: none;
        box-sizing: border-box;
        border:1px solid $base-border-color;

        
        &:nth-child(1){
          border-top-left-radius: 0.35rem;
          border-top-right-radius: 0.35rem;
        }

        &:nth-child(2){
          border-bottom-left-radius: 0.35rem;
          border-bottom-right-radius: 0.35rem;
        }

        &:focus{
          border:1px solid $base-primary-opacity-color;
        }
      }

      button {
        margin:24px 0 0 0;
        width:100%;
        color: #fff;
        background-color: $base-primary-color;
        font-weight: bold;
        border-radius: 0.35rem;
      }
    }
  }
}
</style>
