<template>
  <div>
    <div class="vhp-card-filter-container" v-if="!hideFilter">
      <ul>
        <li v-for="item in tabs" :key="item.value">
          <button
            class="vhp-button"
            @click="tab = item.value"
            :class="{ active: tab === item.value }"
          >
            {{ item.text }}
          </button>
        </li>
      </ul>

      <span class="search-wrap">
        <div class="vhp-input">
          <input
            class="append-icon"
            type="text"
            name="search"
            id="search"
            v-model="searchKeyword"
            :placeholder="$t('검색어입력')"
          />
          <img
            class="icon"
            src="@/assets/image/magnify.svg"
            width="16"
            height="16"
            alt="magnify"
          />
        </div>
      </span>
    </div>

    <div class="vhp-card-container" style="margin: 0; row-gap: 0;">
      <vhp-empty v-if="filteredContents.length === 0 && !loading" />
      <vhp-list-card
        v-for="(content, index) in filteredContents.slice(0, viewLimit)"
        :key="content.link"
        :content="content"
        data-aos-duration="800"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-easing="ease-in-out"
        :base-url="baseUrl"
        :list-url="listUrl"
        :backgroundColor="index % 2 === 0 ? '#f5f5f580' : ''"
        :imageLeft="index % 2 === 0 ? true : false"
      />

      <div class="vhp-card-more-container">
        <button
          class="vhp-button"
          @click="viewLimit += 9"
          v-if="filteredContents.length > viewLimit"
        >
          {{ $t("더 보기") }}
        </button>

        <button
          class="vhp-button"
          @click="scrollUp()"
          v-else-if="filteredContents.length > 12"
        >
          {{ $t("처음으로") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListLayout",
  props: {
    contents: {
      type: Array,
      default: () => {
        return [];
      },
    },
    baseUrl: {
      type: String,
      default: "/",
    },
    listUrl: {
      type: String,
      default: "/",
    },
    hideFilter: {
      type: Boolean,
      defalult: false,
    },
  },
  computed: {
    filteredContents() {
      if (!this.tab || this.tab === "all") {
        return this.contents
          .filter((e) =>
            this.searchKeyword
              ? e.title
                  .toLowerCase()
                  .indexOf(this.searchKeyword.toLowerCase()) !== -1 ||
                e.description
                  .toLowerCase()
                  .indexOf(this.searchKeyword.toLowerCase()) !== -1
              : true
          )
          .sort((x, y) => y.postdate - x.postdate);
      } else {
        return this.contents
          .filter((e) =>
            this.searchKeyword
              ? e.title
                  .toLowerCase()
                  .indexOf(this.searchKeyword.toLowerCase()) !== -1 ||
                e.description
                  .toLowerCase()
                  .indexOf(this.searchKeyword.toLowerCase()) !== -1
              : true
          )
          .sort((x, y) => y.postdate - x.postdate);
      }
    },
  },
  data() {
    return {
      searchKeyword: "",
      tab: "all",
      tabs: [],
      viewLimit: 9,
      loading: true,
    };
  },

  watch: {
    contents() {
      this.generateTabs(this.contents);
    },
  },

  mounted() {
    this.generateTabs(this.contents);
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },

  methods: {
    scrollUp() {
      window.scrollTo(0, 0);
    },
    generateTabs(contents) {
      if (!this.contents) return;
      let tabs = [
        {
          text: "전체",
          value: "all",
        },
      ];
      let tabMap = {};
      for (let i = 0; i < contents.length; i++) {
        for (let tag in contents[i].hashTags) {
          tabMap[contents[i].hashTags[tag]] = contents[i].hashTags[tag];
        }
      }

      for (let tab in tabMap) {
        tabs.push({
          text: tab,
          value: tab,
        });
      }
      this.tabs = tabs;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
