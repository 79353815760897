<template>
  <div class="admin-header">
    <button @click="$router.push('/')">
      <img src="@/assets/image/logo-a.svg" height="12" />
    </button>
    <button
      v-for="page in pages"
      :key="page.path"
      :class="$route.path === page.path ? 'active' : ''"
      @click="$router.push(page.path)"
    >
      {{ page.text }}
    </button>

    <nav v-if="$route.query.id && $route.query.id.indexOf('@content') !== -1">
      <button @click="openEditPage()">편집</button>
      <button @click="clickDelBtn()">삭제</button>
    </nav>

    <span style="margin-left: auto; margin-right: 10px">{{
      $store.state.admin.username
    }}</span>
    <span style="font-size: 10px; opacity: 0.5">|</span>
    <button @click="exitAdminMode" class="exit-button">로그아웃</button>
    <span style="font-size: 10px; opacity: 0.5">|</span>
    <span
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        width: 115px;
        margin-left: 10px;
      "
    >
      <i class="fa-regular fa-clock" style="margin-right: 5px"></i>
      <span style="min-width: 65px">{{ formatTime(expirationTime) }}</span>
      <button style="margin: 0" @click="$refs.loginAdminDialog.load()">
        <i class="fa-solid fa-rotate-right"></i>
      </button>
    </span>

    <vhp-dialog
      title="로그인 연장"
      id="login-admin"
      class="login-admin"
      ref="loginAdminDialog"
      width="370"
      height="300"
    >
      <template v-slot:content>
        <div class="mb-4">
          <form @submit.prevent="loginAdmin" method="post">
            <div class="vhp-text regular mb-4" style="min-width: 65px">
              <i class="fa-regular fa-clock" style="margin-right: 5px"></i>
              {{ formatTime(expirationTime) }}
            </div>

            <input
              v-model="password"
              placeholder="비밀번호 입력"
              type="password"
              maxlength="50"
            />
            <button class="save-btn vhp-button">연장</button>
          </form>
        </div>
      </template>
    </vhp-dialog>
  </div>
</template>

<script>
export default {
  name: "vhpAdminHeader",
  computed: {},
  data() {
    return {
      pages: [
        {
          path: "/admin/history",
          text: "회사연혁",
        },
        {
          path: "/admin/award",
          text: "수상/인증 관리",
        },
        {
          path: "/admin/patent",
          text: "특허 관리",
        },
        {
          path: "/admin/product",
          text: "제품 관리",
        },
        {
          path: "/admin/console",
          text: "소식 관리",
        },
        {
          path: "/admin/account",
          text: "계정 관리",
        },
        {
          path: "/admin/log",
          text: "활동 로그",
        },
        {
          path: "/admin/statistics",
          text: "페이지 통계",
        },
      ],
      expirationTime: 0,
      username: "",
      password: "",
    };
  },
  mounted() {
    setInterval(this.updateExpiration, 1000);
    this.username = this.$store.state.admin.username;
  },
  methods: {
    updateExpiration() {
      const token = this.$store.state.apiToken;
      const payload = JSON.parse(atob(token.split(".")[1]));
      const expirationTime = new Date(payload.exp * 1000);

      const currentTime = new Date();
      const timeDiff = expirationTime - currentTime;
      const secondsLeft = Math.floor(timeDiff / 1000);

      this.expirationTime = secondsLeft;
    },

    formatTime(seconds) {
      if (seconds < 0) {
        this.exitAdminMode();
      } else {
        const formattedMinutes = String(Math.floor(seconds / 60)).padStart(
          2,
          "0"
        );
        const formattedSeconds = String(seconds % 60).padStart(2, "0");
        return `${formattedMinutes}분 ${formattedSeconds}초`;
      }
    },

    exitAdminMode() {
      const res = window.confirm("관리자 모드를 종료하시겠습니까?");
      if (res) {
        this.$store.commit("clearTokens");
        this.$axios.defaults.headers.common["Authorization"] = "";
        let route = this.$router.resolve({ path: "/" });
        window.open(route.href, "_self");
      } else {
        if (this.expirationTime < 30) {
          this.$refs.loginAdminDialog.load();
        }
      }
    },

    loginAdmin() {
      if (!this.password) {
        alert("비밀번호를 입력하세요.");
        return;
      }
      this.$axios
        .post("/auth", {
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          if (res.data.accessToken) {
            this.$store.commit(
              "setFrontToken",
              this.$CryptoJS.AES.encrypt(
                process.env.VUE_APP_VALIDATION_TEXT,
                process.env.VUE_APP_SECRET_KEY
              ).toString()
            );
            this.$store.commit("setApiToken", `Bearer ${res.data.accessToken}`);
            this.$store.commit("setAdmin", { username: this.username });
            this.$axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${res.data.accessToken}`;
            this.password = "";
            this.$refs.loginAdminDialog.close();
          }
        })
        .catch((err) => {
          console.log("LOGIN ERROR : ", err);
          window.alert("비밀번호 입력값이 일치하지 않습니다.");
        });
    },

    openEditPage() {
      this.$router.push({
        name: "adminContent",
        params: { type: "edit", id: this.$route.query.id },
      });
    },

    clickDelBtn() {
      const res = window.confirm("게시물을 삭제하시겠습니까?");
      if (res) this.deleteContent();
    },

    deleteContent() {
      this.$axios
        .delete("/content", { params: { id: this.$route.query.id } })
        .then(() => {
          this.$router.push({ path: "/news" });
          window.alert("삭제되었습니다.");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
.admin-header {
  background-color: $base-foreground-color;
  overflow: hidden;
  margin: 0;
  display: flex;
  align-items: center;
  color: #ddd;
  z-index: 2010;
  max-width: 100vw;
  max-height: 36px;
  height: 36px;
  padding: 0px 6px;
  width: calc(100% - 12px);
  position: fixed;
  bottom: 0;

  nav {
    margin: 0 auto;
    position: absolute;
    width: 50vw;
    left: calc(50% - 25vw);
  }

  button:not(.vhp-button) {
    background-color: transparent;
    border: none;
    margin: 2px;
    padding: 6px 9px;
    cursor: pointer;
    position: relative;
    color: #ddd;
    border-radius: 0.35rem;

    &:hover {
      background-color: #ffffff20;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -4px;
      width: 0px;
    }

    &:active {
      transform: scale(0.97);
    }

    &.active {
      background-color: #ffffff20;
      color: #fff;
    }
  }
}

.login-admin {
  color: $base-foreground-color;
  input {
    border: none;
    margin-bottom: 20px;
    background: #bc1d2d14;
    padding: 10px 15px;
    border-radius: 0.4rem;
  }

  form{
    display: flex;
    flex-direction: column;
  }

}
</style>
