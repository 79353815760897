<template>
  <div class="admin-page admin-console">
    <h1>소식 관리</h1>
    
    <div class="menus">
      <vhp-select
        v-model="selectedMenu"
        :items="menus" 
        item-key="text"
        item-value="name"
        class="mr-2"
      />

      <vhp-select
        v-model="selectedLocale"
        :items="[{text:'전체', value:'ALL'},{text:'한국어', value:'KR'},{text:'ENGLISH', value:'EN'}]" 
        item-key="text"
        item-value="value"
        class="mr-2"
      />
      
      <button @click="openCreatePage" class="vhp-button" >소식 작성</button>
    </div>

    <vhp-table 
      v-if="!loading && filteredList?.length > 0"
      :headers="[{
          text:'제목',
          value:'title',
          width: 200,
        },{
            text:'숨김',
            value:'hidden'
        },{
            text:'작성자',
            value:'editor'
        },{
            text:'조회수',
            value:'viewCount'
        },{
            text:'등록일',
            value:'editedDate'
        }]" 
      :items="filteredList"
    >
      <template v-slot:item-title="{item}">
        <a @click="openEditPage(item)">{{item.title}}</a>
      </template>
    </vhp-table>
  </div>
</template>

<script>
export default {
  name: 'AdminConsole',
  data() {
    return {
      loading: true,
      menus: [
        {name: 'STORY', text: '소식 > 스토리'},
        {name: 'NOTICE', text: '소식 > 공지사항'},
        {name: 'RESOURCE', text: '소식 > 자료실'},
      ],
      investmentContents: [],
      selectedMenu: 'STORY',
      selectedLocale: 'KR',
      activeList: [],
      header: ['번호', '제목', '작성일', '조회수'],
      itemsPerPage:15,
      currPage:0,
    }
  },

  computed:{
    filteredList(){
      return this.activeList?.filter(item => item.category === this.selectedMenu)
        .filter(item => this.selectedLocale === 'ALL' ? true : item.locale === this.selectedLocale)
    },
    pageTab() {
      return Math.floor(this.currPage / 10)
    },
    maxPageTab() {
      return Math.ceil(this.filteredList.length / this.itemsPerPage)
    },
  },
  methods:{
    init(){
      this.getNewsContents()
      this.getInvestmentContents()
    },
    menuChange(e){
      this.selectedMenu = e.target.value
            
      if(this.selectedMenu === ('STORY' || 'NOTICE' || 'RESOURCE')){
        this.activeList = this.newsContents
      }
    },
    async getNewsContents() {
      this.loading = true;
      
      await this.$axios.get(
        '/content?category=STORY&category=NOTICE&category=RESOURCE&locale=KR&locale=EN'
      )
      .then(res=>{
        this.newsContents = res.data
        this.activeList = this.newsContents
      })
      .catch(err=>{
        console.log(err)
      })
      
      this.loading = false;
    },
    getInvestmentContents() {
      this.$axios.get('/content?category=ANNOUNCEMENT')
      .then(res=>{
        this.investmentContents = res.data
      })
      .catch(err=>{
        console.log(err)
      })
    },
    openEditPage(item){
      this.$router.push({name: 'adminContent', params: {type: 'edit', id: item.id}})
    },
    openCreatePage(){
      this.$router.push({name: 'adminContent', params: {type: 'create'}})
    },
    prev() {
      this.currPage = this.currPage > 0 ? this.currPage - 1 : this.currPage
    },
    next() {
      this.currPage = this.currPage < this.maxPageTab - 1 ? this.currPage + 1 : this.currPage
    },
    toFirst() {
      this.currPage = 0
    },
    toLast() {
      this.currPage = this.maxPageTab - 1
    },
    range(start, end) {
      if(end > this.maxPageTab) {
        end = this.maxPageTab
      }
      return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start)
    },
  },
  mounted() {
    if(!this.$store.state.isAdmin){
      alert('관리자 권한 없음')
      this.$router.push({path: '/'})
    }
    
    this.init()
  },
  watch: {
  },
}
</script>

<style lang="scss">
.admin-page {
  font-size: 1rem;
  padding-top: 25px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  input {
    font-size: 1rem;
    
    &.flat {
      width: 500px;
      border: none;
      border-bottom: 1px solid black;
    }
  }
  
  .menus, .selects {
    width: 100%;
    position: relative;
    
    select {
      margin-right: 20px;
      border: 2px solid $base-primary-color;
      border-radius: 5px;
      padding: 3px 10px;
    }
  }

  &.admin-console {
    .menus {
      text-align: left;
      width: 100%;
    }
  }
}


</style>