<template>
  <div>
    <vhp-breadcrumb />

    <div class="vhp-main-content-title">
      <h1 class="page-title">{{ $t("제품") }}</h1>
      <p class="page-subtitle mb-4">{{ $t("제품_설명") }}</p>
    </div>

    <div class="page-background flex">
      <img src="@/assets/image/background/bg48.jpg" alt="page-image"/>
    </div>

    <div  v-if="$route.path === '/products'" class="product-container">
      <div class="product-menus" v-if="categories">
          <div
            v-for="(item, index) in categories"
            :key="item.id"
            class="menu"
            :class="categoryIndex === index ? 'active' : ''"
           
          >
            <div style="cursor: pointer; display:flex; flex-direction: row; align-items:center; "  @click="categoryIndex = index, (itemIndex = 0), $router.push('/products?index=' + index)"> 
              {{ $t(item.name)}}
              <i :class="`fas fa-arrow-${categoryIndex === index ? 'right' : 'down'}`" style=" margin-left:auto;"></i>
            </div>


            <ul v-if="categoryIndex === index" >
              <li
                v-for="(product, idx) in filteredProductsTotal" :key="product.title"
                :class="itemIndex === idx ? 'active' : ''"
                @click="$router.push('/products/details?id=' + product.id)"
              >
              {{ product.title }}

              </li>
            </ul>

          </div>
      </div>

      <div class="product-hidden" :style="'height: ' + (parseInt((filteredProducts.length + 1) / 3) + 1) * 450 + 'px;'"></div>

      <div class="product-list-container">
        <h1 class="title" v-if="categories[categoryIndex]">{{ $t(categories[categoryIndex].name) }}</h1>
        <p class="subtitle" v-if="categories[categoryIndex]">{{ categories[categoryIndex].description }}</p>
        <div
          data-aos-duration="800" data-aos="fade"
          class="list"
        >
    
          <template
            v-for="item in filteredProducts"
            :key="item.title"
          >
            <div class="content-card"
            >
              <div class="card-wrap"
                data-aos-duration="400"
                data-aos="fade-up"
              >
                <div
                  class="card-image"
                  @click="$router.push('/products/details?id=' + item.id)"
                >
                  
                  <img  v-if="item.image || item.thumbnail" :src="item.image || item.thumbnail"  />
                  
                  <img v-else src="@/assets/image/etc/noimage.png"/>
                </div>

                <div
                  class="item-description"
                >
              
                  <h2>
                    {{ item.title }}
                  </h2>

                  <p>
                    {{ item.subtitle }}
                  </p>

                </div>
                
              </div>
            </div>
          </template>

          <div class="content-card"
            v-if="!loadingProducts"
          >
              <div class="card-wrap"
                data-aos-duration="400"
                data-aos="fade-up"
              >
                <div
                  class="card-image"
                  @click="$router.push('/contact')"

                >
                  <img src="@/assets/image/etc/contact.png"  />
                </div>

                <div
                  class="item-description"
                >
              
                  <h2>
                    {{$t('도입/견적 문의')}}
                  </h2>

                  <p>
                    {{$t('도입/견적 문의_설명')}}
                  </p>
                 
                </div>
                
              </div>
          </div>
        </div>

        <button 
          class="vhp-button" 
          style="margin: 0 auto;"
          data-aos-duration="800"
          data-aos="fade-up" 
          @click="maxViewLength += 9"
          v-if="filteredProductsTotal.length > filteredProducts.length">
            <i class="fas fa-arrow-down mr-2"/>
            {{ $t('더 보기') }} 
        </button>

        
      </div>
    </div>

    <router-view v-else/>


    <!-- <div class="vhp-divider"></div> -->
  </div>
</template>

<script>
export default {
  name: "ProductIndex",
  data() {
    return {
      categoryIndex: 0,
      itemIndex: 0,
      categories: [],
      products:[],
      loadingProducts: false,
      maxViewLength:8,
    };
  },
  mounted() {
    this.categoryIndex = parseInt(this.$route.query.index) || 0
    this.get();
  },
  watch: {
    '$route.query'(){
      this.categoryIndex = parseInt(this.$route.query.index) || 0
    }

  },
  computed:{
    filteredProducts() {
      return this.products.filter((e) => e.category === this.categories[this.categoryIndex].id).splice(0, this.maxViewLength)
    },
    filteredProductsTotal() {
      return this.products.filter((e) => e.category === this.categories[this.categoryIndex].id)
    }
  },
  methods: {
    async get() {
      this.loadingProducts = true;

      await this.getCategory()
      await this.getProducts()

      this.loadingProducts = false;

    },
    async getProducts() {

      await this.$axios
        .get("/product")
        .then((res) => {
          this.products = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      this.loadingProducts = false;
    },

    async getCategory() {
      const response = await this.$axios.get('/category')
      if(response.data) {
        this.categories = response.data
      }
    }

  },
};
</script>

<style lang="scss" scoped>
.product-container {
  position: relative;
  width:100%;
  max-width: 100%;
  margin:0;
  padding:0;

  .product-hidden{
    min-height: 480px;
  }

  .product-menus{
    position: -webkit-sticky;
    position: sticky;
    display: inline-block;
    left:0;
    top:10vh;
    text-align: left;
    width:100%;
    max-width:210px;
    font-size: 1rem;
    font-weight: bold;
    z-index: 10;
    display: flex;
    flex-direction: column;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    padding:4px 0;

   
    
    .menu {
      opacity: 0.3;
      display: inline-block;
      padding: 8px 0;
      font-size: 1.16rem;
      position: relative;
   
      &:hover{
        opacity: 1;
      }

      &.active{
        opacity: 1;
      }

      ul {
        margin:0;
        padding: 4px 0 0 12px;
        font-size: 1rem;
        li{
          cursor: pointer;
          margin:0 0 0 12px;
          padding:4px 0;
          opacity: 0.7;
      
          &:hover{
            opacity: 1;
          }

        }
      }
    }

  }

  .product-list-container{
    position: absolute;
    top:0;
    left:280px;
    width:calc(100% - 280px);
    .title{
      
    text-align:start;
      font-size: 2rem;
      margin:0;
    }
    .subtitle{
      
      text-align:start;
      font-size:1.1rem;
    }

    .list{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin:32px 0;
      
      .content-card {
        flex: 1 1 calc(33% - 21px);
        max-width: calc(33% - 21px);
        position: relative;
        margin: 12px;

        .card-wrap {
          overflow: hidden;
          min-height: 400px;
          height: 100%;
          width: 100%;

          &:hover{
            .card-image{
              > img{
                scale: 1.05;
              }
            }
          }

          .item-description {
            text-align: center;
            margin: 12px 0;
            height: 80px;
            position: relative;

            h2,  p {
              margin:0;
              padding:0;
            }

            h2{
              font-size: 1.2rem;
            }
            p{
              font-size:1rem;
            }

            .item-action{
              position: absolute;
              bottom: 0;
              width:100%;
            }
          }
          .card-image {
            height:300px;
            width:100%;
            cursor: pointer;
            overflow: hidden;
            > img {
              transition: all .2s ease-in-out;
              height:100%;
              width:100%;
              max-width:100%;
              max-height:100%;
              object-fit: contain;
              object-position: center;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: #{$breakpoint-desktop}) {    
    .product-menus{
      max-width: calc(100% - 36px);
      width:calc(100% - 36px);
      position: static;
      margin:0 12px;
    }
    .product-hidden{
      display:none;
    }
    .product-list-container{
      width:calc(100% - 36px) !important;
      margin:48px 12px !important;
      left:0;
      position: relative;

      .list{
        .content-card {
          flex: 1 1 calc(50% - 24px);
          max-width: calc(50% - 24px);
          .card-wrap{
            
            .item-description {
                p{
                  font-size: 0.9rem;
                }
            
            }
          }
        }
      }
      
    }
  }

  @media screen and (max-width: #{$breakpoint-mobile}) { 
    .product-list-container{
      .list{
        .content-card {
          .card-wrap{
            min-height: 240px;
            .item-description{
              h2{
                font-size:1rem;
              }
            }
            .card-image{
              height:160px;
            }
          }
        }
      }
    }
  }



}
</style>
