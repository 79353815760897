<template>
  <div class="admin-page admin-award">
    <h1>수상/인증</h1>

    <div class="menus">
      <button @click="$refs.createAwardDialog.load()" class="vhp-button">
        수상/인증 추가
      </button>
    </div>

    <vhp-table
      :headers="[
        {
          text: '연도',
          value: 'year',
        },
        {
          text: '수여기관',
          value: 'org',
        },
        {
          text: '제목',
          value: 'title',
        },
        {
          text: '언어(ko,en)',
          value: 'locale',
        },
        {
          text: '수정',
          value: 'edit',
        },
        {
          text: '삭제',
          value: 'delete',
        },
      ]"
      :items="awardList"
    >
      <template v-slot:item-edit="{ item }">
        <img
          v-if="item.username !== 'admin'"
          @click="(edit = item), $refs.editAwardDialog.load()"
          src="@/assets/image/edit.svg"
          height="15"
          alt="lock-icon"
          class="pointer"
        />
      </template>

      <template v-slot:item-delete="{ item }">
        <img
          v-if="item.username !== 'admin'"
          @click="deleteAward(item)"
          src="@/assets/image/trash-can-outline.svg"
          height="20"
          alt="lock-icon"
          class="pointer"
        />
      </template>
    </vhp-table>

    <vhp-dialog
      title="수상/인증 추가"
      id="create-award"
      class="create-dialog"
      ref="createAwardDialog"
      width="370"
      height="400"
    >
      <template v-slot:content>
        <div>
          <form @submit.prevent="createAward" method="post">
          
        

            <label  for="연도">{{ $t("연도") }}</label>
            <input
              name="연도"
              v-model="award.year"
              placeholder="연도"
              type="text"
              maxlength="200"
              required
            />

            <label  for="수여기관">{{ $t("수여기관") }}</label>
            <input
              name="수여기관"
              v-model="award.org"
              placeholder="수여기관"
              type="text"
              maxlength="200"
              required
            />

            <label  for="제목">{{ $t("제목") }}</label>
            <input
              name="제목"
              v-model="award.title"
              placeholder="제목"
              type="text"
              maxlength="200"
              required
            />


            <label  for="이미지">{{ $t("이미지") }}</label>
            <img v-if="!award.image" src="@/assets/image/etc/noimage.png" @click="$refs.imageInput.click()"/>
            <img v-else :src="award.image" @click="$refs.imageInput.click()"/>
            <input
              ref="imageInput"
              type="file"
              name="이미지"
              accept="image/*"
              @change="addImage($event, award)"
              hidden
            />
            <button class="save-btn vhp-button">추가</button>
          </form>
        </div>
      </template>
    </vhp-dialog>

    <vhp-dialog
      title="내용 수정"
      id="edit-award"
      class="create-dialog"
      ref="editAwardDialog"
      width="370"
      height="400"
    >
      <template v-slot:content>
        <div>
          <form @submit.prevent="editAward" method="put">
        
            <label  for="연도">{{ $t("연도") }}</label>
            <input
              name="연도"
              v-model="edit.year"
              placeholder="연도"
              type="text"
              maxlength="200"
              required
            />

            <label  for="수여기관">{{ $t("수여기관") }}</label>
            <input
              name="수여기관"
              v-model="edit.org"
              placeholder="수여기관"
              type="text"
              maxlength="200"
              required
            />

            <label  for="제목">{{ $t("제목") }}</label>
            <input
              name="제목"
              v-model="edit.title"
              placeholder="제목"
              type="text"
              maxlength="200"
              required
            />


            <label  for="이미지">{{ $t("이미지") }}</label>
            <img v-if="!edit.image" src="@/assets/image/etc/noimage.png" @click="$refs.imageInput.click()"/>
            <img v-else :src="edit.image" @click="$refs.imageInput.click()"/>
            <input
              ref="imageInput"
              type="file"
              name="이미지"
              accept="image/*"
              @change="addImage($event, edit)"
              hidden
            />
            
            <button class="save-btn vhp-button">수정</button>
          </form>
        </div>
      </template>
    </vhp-dialog>
  </div>
</template>

<script>
export default {
  name: "AdminAward",
  data: () => {
    return {
      awardList: [],
      award: {
        year: "",
        org:"",
        title:"",
        locale:"ko",
        image:""
      },
      edit: {
        id: "",
        year: "",
        org:"",
        title:"",
        locale:"ko",
        image:""
      },
    };
  },
  methods: {
    addImage(e, target){
      const file = e.target.files[0]
      if(file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            // target.image = reader.result
            const img = new Image();
            img.onload = function() {
                let width = img.width;
                let height = img.height;
                const maxDimension = 320;

                if (width > height) {
                    // 너비가 높이보다 클 경우
                    if (width > maxDimension) {
                        height *= maxDimension / width;
                        width = maxDimension;
                    }
                } else {
                    // 높이가 너비보다 클 경우
                    if (height > maxDimension) {
                        width *= maxDimension / height;
                        height = maxDimension;
                    }
                }
                
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                
                canvas.width = width;
                canvas.height = height;
                
                ctx.drawImage(img, 0, 0, width, height);
                
                const resizedBase64 = canvas.toDataURL(file.type);
                target.image = resizedBase64;
            };
            img.src = e.target.result;
        };
        reader.readAsDataURL(file); // 파일을 읽어서 Base64로 변환
      }
    }, 
    async getAwardList() {
      await this.$axios
        .get("/award")
        .then((res) => {
          this.awardList = res.data.sort(
            (a, b) => new Date(b.updatedDate) - new Date(a.updatedDate)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async createAward() {
      for(let k of Object.keys(this.award)) {
        if(!this.award[k] && k !== 'image'){
          console.log(this.award)
          alert("값을 모두 입력해주세요.");
          return
        }
      }
    
      this.$axios
        .post("/award", this.award)
        .then(() => {
          window.alert("추가되었습니다.");
          this.$refs.createAwardDialog.close();
          this.getAwardList();
        })
        .catch((err) => {
          if (err.response.data === "DUPLICATED") {
            window.alert("해당 연혁이 이미 존재합니다.");
          } else {
            window.alert("콘텐츠를 추가할 수 없습니다. 개발팀에 문의하세요.");
          }

          console.log("SIGNUP ERROR : ", err);
        });
    },

    async editAward() {
      this.$axios
        .put("/award", this.edit)
        .then(() => {
          window.alert("수정되었습니다.");
          this.$refs.editAwardDialog.close();
          this.getAwardList();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async deleteAward(item) {
      const res = window.confirm("콘텐츠를 삭제하시겠습니까?");
      if (!res) return;

      this.$axios
        .delete("/award", {
          params: {
            id: item.id,
          },
        })
        .then(() => {
          this.getAwardList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getAwardList();
  },
};
</script>

<style lang="scss">
.admin-page.admin-award {
  .menus {
    margin: 30px 0;
    text-align: right;
  }

  .create-dialog {
    text-align: start;
    label{
      display: block;
    }
    input {
      width: calc(100% - 24px);
      border: none;
      margin-bottom: 20px;
      background: #bc1d2d14;
      padding: 12px;
      border-radius: 0.4rem;
    }

    form{
      display: flex;
      flex-direction: column;
      min-width:320px;
      width:640px;
      max-width: 100%;
      > img{
        object-fit: contain;
        max-width: 320px;
        width:100%;
        cursor: pointer;
      }
    }
  }
}
</style>
