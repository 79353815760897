<template>
  <div>
    <vhp-breadcrumb />
    <div class="vhp-main-content-title">
      <h1 class="page-title">{{ $t("문의") }}</h1>
      <p class="page-subtitle">{{ $t("문의_설명") }}</p>
    </div>

    <div class="page-background flex">
      <img src="@/assets/image/background/bg50.jpg" alt="page-image" />
    </div>

    <inquiry-layout/>
  </div>
</template>

<script>
import InquiryLayout from "@/components/layouts/InquiryLayout.vue";
export default {
  name: "ContactIndex",
  components: {
    InquiryLayout,
  },
  computed: {},
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss"></style>
