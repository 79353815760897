<template>
  <div class="vhp-menu" :id="id">
    <div class="act-btn" @click="isActive = !isActive">
      <slot v-if="$slots.activator" name="activator" />
      <div v-else class="vhp-button round">
        <img src="@/assets/image/menu2.svg" width="16" height="16"/>
      </div>
    </div>

    <div v-show="isActive" class="menus" :class="position" :style="menuStyle">
      <slot v-if="$slots.body" name="body" v-bind:items="items" v-bind:close="close" />
      <div v-else class="menu" v-for="(item, index) in items" :key="index">
        <button @click="item.event()">{{item.title}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    items: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true
    },
    isActiveProp: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'bottom',
    },

    // STYLE
    width: {
      type: [String, Number],
      default: 'auto'
    },
    height: {
      type: [String, Number],
      default: 'auto'
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    menuStyle(){
      return {
        width: this.width === 'auto' ? 'auto' : this.width + 'px',
        height: this.height === 'auto' ? 'auto' : this.height + 'px',
      }
    }
  },
  methods: {
    setClickOutsideEvent(){
      window.addEventListener('click', e => {
        const menu = document.querySelector(`#${this.id}`)
        
        if (menu && !menu.contains(e.target)){
          this.isActive = false
        } 
      });
    },
    close(){
      this.isActive = false
    }
  },
  mounted() {
    if(this.isActiveProp){
      this.isActive = this.isActiveProp
    }

    this.setClickOutsideEvent()
  },
}
</script>

<style lang="scss">
.vhp-menu {
  position: relative;
  
  .act-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  
  .menus {
    position: absolute;
    z-index: 99;
    border:1px solid $base-border-color;
    
    box-shadow: 2px 4px 32px #3a3a3c16;
    padding:6px;
    border-radius: 16px;
    background-color: $base-background-color;
    
    &.top {
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, -5px);
    }
    
    &.right {
      left: 100%;
      top: 50%;
      transform: translate(5px, -50%);
    }
    
    &.bottom {
      top: 100%;
      left: 50%;
      transform: translate(-50%, 5px);
    }
    
    &.left {
      right: 100%;
      top: 50%;
      transform: translate(-5px, -50%);
    }
    
    .menu {
      &:hover {
        background-color: $base-button-hover-color;
        
        button {
          font-weight: 800;
        }
      }
      
      &:first-child{
        border-radius: 12px 12px 0 0;
      }
      
      &:last-child{
        border-radius: 0 0 12px 12px;
      }
    }
    
    button {
      background-color: transparent;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
    }
  }
}
</style>