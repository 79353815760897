<template>
  <div class="vhp-tabs-container">
    <div class="vhp-tabs" data-aos-duration="800" data-aos="fade">
      <div class="tabs-background"></div>
      <div class="wrap" :class="'grid-' + items.length">
        <button
          v-for="(item, index) in items"
          :key="index"
          class="tab-btn"
          :class="{ active: index === tabIndex }"
          @click.stop="(tabIndex = index), (itemIndex = 0), $emit('click', index)"
        >
          <div class="vhp-text small bold">
            {{ item.title }}
          </div>
        </button>
      </div>
    </div>

 

    <div
      data-aos-duration="800" data-aos="fade"
      class="vhp-tabs-contents"
    >
      <slot v-bind:index="tabIndex">
      </slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "vhpTabs",
  directives: {
  },
  props: {
    items: Array
  },
  data() {
    return {
      tabIndex: 0
    };
  },


  methods: {
    
  },
};
</script>


<style lang="scss">
.vhp-tabs-container {
  width:100%;

  .vhp-tabs {
    position: sticky;
    top: $header-height;

 
    z-index: 8;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 60px;
    height:60px;

    .tabs-background {
      position: absolute;
      width: 100vw;
      left: calc(50% - 50vw);
      height: 100%;
      border:1px solid $base-border-color;
      border-bottom: 1px solid $base-border-color;
      background-color: $base-background-color;
      z-index: -1;
    }
    .wrap {
      width: 100%;
      height: 100%;
      background-color: $base-background-color;
      z-index: 2;
      display: flex;
      flex-direction: row;
      justify-content: center;



      .tab-btn {
        height: 100%;
        min-width: 120px;
        width: 100%;
        max-width:320px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        *{
          font-size:1.1rem !important;
        }
        &.active {
          // color: $base-button-background-color;
          position: relative;

          .vhp-text {
            color: $base-button-background-color;
            // color: $color-blue;
          }

          &::after {
            content: "";
            position: absolute;
            opacity: 0.8;
            width: 100%;
            height: 2.6px;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0.3px;
            z-index: -1;
            background-color: $base-button-background-color;
            // background-color: $color-blue;
          }
        }
      }
    }

    @media screen and (max-width: #{$breakpoint-md}) {
      top: $header-mobile-height !important;
      min-height: 48px;
      height:48px;
      .wrap{
        .tab-btn{
          *{
          font-size:0.95rem !important;
          }

        }
      }
    }
  }

  .vhp-tabs-contents{
    margin:0;
    padding:0;
  }
}
</style>