<template>
  <div class="vhp-product-card-container">
    <div class="vhp-product-card" @click="onClickCard(content)">
      <div v-if="content.thumbnail" class="vhp-product-card-wrap">
        <img
          class="card-image"
          alt="card-image"
          :src="content.thumbnail"
          cover
          @error="replaceImage"
        />
      </div>
      <div v-else class="vhp-product-card-wrap">
        <img
          class="card-image"
          alt="card-image"
          :src="content.image || content.images[0] || ''"
          cover
          @error="replaceImage"
        />
      </div>
    </div>
    <div v-if="!showSimple" class="vhp-product-card-content">
      <div class="vhp-text xsmall bold red" style="margin-left: 1px">
        {{ content.brand }}
      </div>
      <div class="vhp-product-card-content-title" v-html="content.title"></div>
      <div v-if="showPrice">
        <div v-if="content.price && typeof content.price === 'number'" class="vhp-product-card-content-price">
          {{ content.price.toLocaleString() }}<span class="price">원</span>
          <!-- <span class="vat">(VAT 포함)</span> -->
        </div>
        <div v-else class="vhp-product-card-content-price">
          가격문의
        </div>
      </div>
      <div
        class="vhp-product-card-content-tags"
        v-if="content.hashTags && showTags"
      >
        <span
          v-for="tag in content.hashTags.slice(0, 5)"
          :key="'hashtag-' + tag"
          class="tag"
        >
          #{{ tag }}
        </span>
        <span v-if="content.hashTags.length > 5"> .. </span>
      </div>

      <span v-if="showDate" class="vhp-product-card-content-right">
        {{ content.editedDate }}
      </span>
    </div>
    <div v-else>
      <div style="margin: 14px 18px">
        <strong class="vhp-text medium" v-html="content.title"></strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {
        return null;
      },
    },
    baseUrl: {
      type: String,
      default: "/",
    },
    listUrl: {
      type: String,
      default: "/",
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    showSimple: {
      type: Boolean,
      default: false,
    },
    showTags: {
      type: Boolean,
      default: false,
    },
    showPrice: {
      type: Boolean,
      default: false,
    },
    showDate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onClickCard(content) {
      if (content.isExternalLink) {
        window.open(content.externalUrl);
      } else {
        this.$router.push(
          this.baseUrl + "?id=" + content.id + "&list=" + this.listUrl
        );
      }
    },

    getCategoryImage(category) {
      return "@/assets/image/category/" + category.toLowerCase() + ".svg";
    },

    replaceImage(e) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const container = document.getElementsByClassName("vhp-card-image")[0];

      canvas.width = container.clientWidth;
      canvas.height = 280;

      ctx.font =
        "32px bold Apple SD Gothic Neo, Pretendard-Regular, NotoSansKR, sans-serif ";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = "#C4182890";
      ctx.fillRect(0, 0, container.clientWidth, 280);

      ctx.fillStyle = "#C4182830";
      ctx.beginPath();
      ctx.moveTo(container.clientWidth * 0.6, 0);
      ctx.lineTo(container.clientWidth, 0);
      ctx.lineTo(container.clientWidth, 200);
      ctx.fill();

      ctx.fillStyle = "#C4182860";
      ctx.beginPath();
      ctx.moveTo(container.clientWidth * 0.6, 280);
      ctx.lineTo(container.clientWidth, 280);
      ctx.lineTo(container.clientWidth, 140);
      ctx.fill();

      ctx.fillStyle = "#FFFFFF";
      ctx.fillText(this.content.title, container.clientWidth / 2, 140);
      let src = canvas.toDataURL("image/png", 1.0);
      e.target.src = src;
    },
  },
};
</script>

<style lang="scss" scoped>
.vhp-product-card-container {
  max-height: 100%;
  max-width: 100%;

  &:hover {
    cursor: pointer;
    .card-image {
      transform: scale(1.05);
    }
  }

  .vhp-product-card {
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 1;
    border-radius: 14px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 16px 32px;
    cursor: pointer;
    overflow: hidden;

    @media screen and (max-width: #{$breakpoint-desktop}) {
      flex: 0 1 calc(50% - 8px);
      max-width: calc(50% - 8px);
    }

    @media screen and (max-width: #{$breakpoint-md}) {
      flex: 0 1 calc(100% - 0px);
      max-width: calc(100% - 0px);
      border-radius: 0;
      &:hover {
        transform: none;
      }
    }

    .vhp-product-card-wrap {
      border-radius: inherit;
      margin: 0;
      height: 100%;
      width: 100%;

      .card-image {
        transition: all 0.4s ease-in-out;
        aspect-ratio: 1;
        z-index: 1;
        border-radius: inherit;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .vhp-product-card-content {
    // width: calc(100% - 35px);
    // max-width: calc(100% - 35px);
    // margin: 20px auto;
    position: relative;
    // text-align: center;
    text-align: left;
    padding: 7px 10px;

    @media screen and (max-width: #{$breakpoint-desktop}) {
      margin: 18px;
      padding-bottom: 18px;
    }

    @media screen and (max-width: #{$breakpoint-md}) {
      margin: 16px;
      padding-bottom: 16px;
    }

    .vhp-product-card-content-title {
      // font-size: 19px;
      // font-weight: bold;
      font-size: 0.95rem;
      display: inline-block;
      word-wrap: break-word;
      word-break: break-word;
      text-overflow: ellipsis;
      white-space: normal;
      overflow: hidden;
      margin-top: 1px;

      @media screen and (max-width: #{$breakpoint-desktop}) {
        font-size: 18px;
      }

      @media screen and (max-width: #{$breakpoint-md}) {
        font-size: 16px;
      }
    }

    .vhp-product-card-content-price {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Pretendard-SemiBold";
      font-size: 1.2rem;
      display: block;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      max-height: 40px;
      line-height: 20px;
      color: $base-foreground-subtitle-color;
      overflow: hidden;
      @media screen and (max-width: #{$breakpoint-md}) {
        padding: 0;
        margin: 0;
        font-size: 14px;
      }

      .price {
        font-family: "Pretendard-SemiBold";
        margin-left: 2px;
        margin-right: 5px;
      }
      .vat {
        opacity: 0.7;
        font-size: 13px;
      }
    }

    .vhp-product-card-content-tags {
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      max-height: 30px;
      line-height: 30px;
      opacity: 0.7;
      font-size: 12px;

      @media screen and (max-width: #{$breakpoint-md}) {
        padding: 0;
        margin: 0;
        height: 24px;
        line-height: 24px;
      }

      .tag {
        background-color: $base-border-color;
        margin: 3px;
        padding: 3px 8px;
        border-radius: 6px;
      }
    }

    .vhp-product-card-content-right {
      font-size: 13px;
      position: absolute;
      width: 100%;
      bottom: -20px;
      right: 0;
      text-align: right;
      // height: 30px;
      line-height: 30px;
    }
  }
}
</style>
