<template>
  <div class="admin-page admin-log">
    <h1>통계</h1>      

    <vhp-table 
      :headers="[{
          text:'날짜',
          value:'date'
        },{
            text:'방문수',
            value:'count'
        }]" 
      :items="statistics"
      :showIndex="false"
    >
    </vhp-table>
  </div>
</template>

<script>
export default {
  name: 'AdminStatistics',
  data: () => {
    return {
      statistics: [],
    }
  },
  methods: {
    getStatistics() {
      this.$axios.get('/visit/details')
      .then(res=>{
        this.statistics=res.data.reverse()
      })
    },
  },
  mounted() {
    this.getStatistics()
  },
}
</script>

<style lang="scss">
</style>
