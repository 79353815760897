<template>
  <div  class="product-container">
      <div class="product-contents" v-if="product">
        <div v-if="category" class="product-category">
          <span class="pointer" @click="$router.push('/products?index=' + category.order)">{{ category.name }}</span>
          <span class="mx-2">/</span>
          <strong>{{ product.title }}</strong>
        </div>

        <div class="product-image">
          <img :src="product.thumbnail"/>
        </div>

        <div class="product-overview">
          <div class="title">
            <h1>
              {{product.title}}
            </h1>
            <p>
              {{product.subtitle}}
            </p>
          </div>

          <button class="vhp-button large bold text" style="width:100%; " @click="$router.push('/contact')">{{$t('문의하기')}}</button>

        </div>

        <div class="product-description" v-if="product.content" v-html="product.content"/>


      </div>


      <div class="product-footer">
       
        T 051-609-9633 | E admin@vazilcompany.com

        <br/>
        <br/>
        <button class="vhp-button my-2 large" style="display: inline-block;" @click="$router.go(-1)">
          {{$t('목록보기')}}
        </button>
      </div>
  </div>

</template>

<script>
export default {
  name: "ProductLayout",
  components:{
  },
  watch:{
    product(){
      if(this.product?.category) {
        this.getCategory(this.product.category)
      }
    }
  },
  props:{
    product: Object
  },
  data() {
    return{
      category: null
    }
  },
  mounted(){
    this.getCategory(this.product.category)
  },
  methods:{
    async getCategory(categoryId) {

      if(categoryId) {
        await this.$axios.get('/category/details?id=' + this.product.category).then(res=>{
          if(res.data) {
            this.category = res.data
          }
        }).catch(err=>{console.log(err)})
      }
    }
  }
}

</script>

<style lang="scss">
.product-container {
  position: relative;
  width:calc(100% - 24px);
  max-width: calc(100% - 24px);
  margin:0 12px;
  padding:0;
  font-size: 1rem;

  .product-contents{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
    min-height:360px;
    justify-content: space-between;

    .product-category{
      display: flex;
      flex-direction: row;
      width:100%;
      align-items: center;
      margin-bottom:24px;
    }
    
    .product-image{
      flex: 1 1 320px;
      margin:12px;
      overflow: hidden;
      border-radius: 1rem;
      background-color: rgba(0,0,0,0.05);
      aspect-ratio: 1;

      img{
        width:100%;
        height:100%;
        max-height:100%;
        max-width:100%;
        object-fit: cover;
      }
    }
    .product-overview{
      text-align: start;
      flex: 1 1 calc(40% - 48px);
      margin:12px 48px;
      width:100%;
      position: relative;

      .title{
        padding:0 0 12px 0;
        height:calc(100% - 64px);
        h1{
          margin:0;
          font-size: 2rem;
        }
        p{
          margin:8px 0;
          font-size: 1.2rem;
        }
      }
     

   
    }

    .product-description{
      border: 1px solid $base-border-color;
        text-align: start;
        margin: 24px auto;
        padding: 24px;
        border-radius: 1rem;
        width:calc(100% - 48px);
        max-width:calc(100% - 48px);
        min-width: 320px;        
        font-size: 1.2rem;

        div {
          width: 100%;
          
          img{
            max-width: 100% !important;
            object-fit: contain !important;
          }
        }
        img{
          max-width: 100% !important;
          object-fit: contain !important;
        }
      }
  }

  .product-footer{
    margin:48px 0;
    padding:24px 0;
    font-size: 0.9rem;
    opacity: 0.8;
    width:100%;
    text-align: center;
  }

  @media screen and (max-width: #{$breakpoint-desktop}) {    
    .product-contents{
      .product-image{
        flex: 1 1 calc(100% - 24px);
      }
      .product-overview{
        flex: 1 1 calc(100% - 24px);
        text-align: center;
        .title{
          h1{
            font-size: 1.5rem;
          }
          h3{
            font-size: 1.15rem;
          }
        }
       
      }
    }

  }

}
</style>
