<template>
  <div
    class="vhp-header" 
    :class="{ dynamic: dynamic, [mode]: mode }"
  >
    <div
      class="vhp-header-content container-width"
      v-click-outside="onClickOutside"
    >
      <span class="vhp-header-title">
        <a href="/" @click="scrollTop">
          <img
            v-if="mode === 'white'"
            class="logo"
            alt="logo"
            src="@/assets/image/logo-color.png"
            height="20"
          />
          <img
            v-else
            class="logo"
            alt="logo"
            src="@/assets/image/logo-color-w.png"
            height="20"
          />
        </a>
      </span>

      <nav class="vhp-header-menu-wrap">
        <ul class="vhp-header-menu" @mouseleave="selectedMenu = null">
          <li
            v-for="menu in menus"
            :key="menu.key"
            @mouseover="hoverMenu(menu)"
            class="main-menu"
          >
            <router-link
              :to="menu.path"
              :class="{ active: $route.path.indexOf(menu.key) !== -1 }"
            >
              <span
                class="menu-title"
                :class="{ [mode]: mode }"
                >{{ $t(menu.text) }}</span
              >
            </router-link>

            <ul
              class="vhp-header-submenu"
              v-if="selectedMenu === menu && menu.childrens.length > 0"
            >
              <li v-for="submenu in selectedMenu.childrens" :key="submenu.key">
                <a
                  v-if="submenu.outlink"
                  @click="(selectedMenu = null), $utils.openLink(submenu.path)"
                  class="outlink"
                >
                  {{ $t(submenu.text) }}
                  <img
                    alt="external-link"
                    src="@/assets/image/external-link.svg"
                    height="12"
                    style="margin-left: 6px"
                  />
                </a>
                <router-link
                  v-else
                  :to="submenu.path"
                  @click="selectedMenu = null"
                  :class="{highlight: $route.path.includes(submenu.path)}"
                >
                  {{ $t(submenu.text) }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>

        <div class="vhp-header-allmenu" v-if="menuIndex === 0">
          <ul class="vhp-header-allmenu-item-wrap container-width desktop-hide">
            <li v-for="menu in menus" :key="menu.key">
              <div>
                <router-link :to="menu.path" @click="allMenu = false">
                  <strong>{{ $t(menu.text) }}</strong>
                </router-link>
              </div>
              <div
                class="header-allmenu-item"
                v-for="submenu in menu.childrens"
                :key="submenu.key"
              >
                <a
                  v-if="submenu.outlink"
                  @click="(menuIndex = -1), $utils.openLink(submenu.path)"
                  class="outlink"
                >
                  {{ $t(submenu.text) }}
                  <img
                    alt="external-link"
                    src="@/assets/image/external-link.svg"
                    height="12"
                    style="margin-left: 6px"
                  />
                </a>
                <router-link v-else :to="submenu.path" @click="menuIndex = -1">
                  {{ $t(submenu.text) }}
                </router-link>
              </div>
            </li>
          </ul>

          <div class="vhp-header-allmenu-item-wrap desktop-show">
            <ul v-for="(menu, idx) in menus" :key="menu.key"
              
            >
              <li
                data-aos-duration="500"
                :data-aos-delay="idx * 100"
                data-aos-offset="100"
                data-aos="fade-right"
                data-aos-easing="ease-in-out"
              >
                <div class="parent-item">
                  <a
                    v-if="menu.childrens.length > 0"
                    @click="onClickArrow(idx)"
                    :class="{ active: activeMenuIndex === idx }"
                  >
                    <strong>{{ $t(menu.text) }}</strong>
                    <div class="arrow" v-if="menu.childrens">
                      <span />
                      <span />
                    </div>
                  </a>

                  <router-link :to="menu.path" @click="menuIndex = -1" v-else>
                    <strong>{{ $t(menu.text) }}</strong>
                  </router-link>
                </div>

                <div
                  v-if="menu.childrens.length > 0 && activeMenuIndex === idx"
                  :class="{ active: activeMenuIndex === idx }"
                >
                  <div
                    class="header-allmenu-item"
                    v-for="submenu in menu.childrens"
                    :key="submenu.key"
                  >
                    <a
                      v-if="submenu.outlink"
                      @click="(selectedMenu = null), openLink(submenu.path)"
                      class="outlink"
                    >
                      {{ $t(submenu.text) }}
                      <img
                        alt="external-link"
                        src="@/assets/image/external-link.svg"
                        height="12"
                        style="margin-left: 6px"
                      />
                    </a>
                    <router-link
                      v-else
                      :to="submenu.path"
                      @click="menuIndex = -1"
                    >
                      {{ $t(submenu.text) }}
                    </router-link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="vhp-header-search" v-if="menuIndex === 1">
          <span
            class="search-wrap container-width"
            data-aos-duration="200"
            data-aos="zoom-in-down"
            data-aos-easing="ease-in-out"
          >
            <div class="vhp-input full">
              <input
                class="append-icon"
                type="text"
                name="search"
                id="search"
                v-model="searchKeyword"
                :placeholder="$t('검색어입력')"
                @keyup.enter="searching()"
              />
              <img
                class="icon"
                src="@/assets/image/search.svg"
                width="20"
                height="20"
                alt="magnify"
              />
            </div>
          </span>
        </div>
      </nav>

      <div class="vhp-header-util" :class="{ [mode]: mode }">
        <button @click="setLanguage()" class="vhp-button round globe">
          <span class="add">{{ $i18n.locale }}</span>
          <!-- <img
            alt="globe"
            src="@/assets/image/globe2.svg"
            width="20"
            height="20"
          /> -->
        </button>

        <button
          @click="openMenu(1)"
          class="vhp-button round"
          :class="menuIndex === 1 ? 'on' : ''"
        >
          <img
            alt="globe"
            src="@/assets/image/search.svg"
            width="20"
            height="20"
          />
        </button>

        <div
          class="vhp-header-menu-btn vhp-button round"
          :class="{ on: menuIndex === 0 }"
          @click="openMenu(0)"
        >
          <span class="menu-btn-line"></span>
          <span class="menu-btn-line"></span>
          <span class="menu-btn-line"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";

export default {
  name: "vhpHeader",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    menus: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dynamic: {
      type: Boolean,
      default: false,
    },
    style: {
      type: Object,
    },
    headerMode: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {
      selectedMenu: null,
      searchMenu: false,
      activeMenuIndex: -1,
      searchKeyword: "",
      menuIndex: -1,
      mode: this.headerMode,
    };
  },

  watch: {
    menuIndex() {
      this.mode = this.headerMode;
      if (this.menuIndex === 0) {
        document.body.style.overflowY = "hidden";
        this.mode = "white";
      } else if (this.menuIndex === 1) {
        document.body.style.overflowY = "hidden"; 
        this.mode = "white";
      } else {
        document.body.style.overflowY = "auto";
      }
    },

    "$route.path"() {
      this.menuIndex = -1;
    },

    headerMode() {
      this.mode = this.headerMode;
    }
  },

  methods: {
    setLanguage() {
      //임시
      // this.$route.query.lang = "kr"
      // alert('현재 영문 페이지 준비중입니다. 빠른 시일 내에 준비하겠습니다.')

      this.$route.query.lang = this.$i18n.locale === "kr" ? "en" : "kr";
      let route = this.$router.resolve({
        path: this.$route.path,
        query: this.$route.query,
      });

      window.open(route.href, "_self");
    },
    openMenu(index) {
      if (this.menuIndex === index) {
        this.menuIndex = -1;
      } else {
        this.menuIndex = index;
      }
    },
    searching() {
      this.$route.query["keyword"] = this.searchKeyword;
      let route = this.$router.resolve({
        path: "/search",
        query: this.$route.query,
      });

      window.open(route.href, "_self");
      this.searchKeyword = "";
      this.searchMenu = false;
    },
    hoverMenu(menu) {
      if (this.menuIndex === 0) return;
      this.selectedMenu = menu;
    },
    onClickOutside() {
      this.menuIndex = -1;
    },
    onClickArrow(idx) {
      if (idx === this.activeMenuIndex) {
        this.activeMenuIndex = -1;
        return;
      }
      this.activeMenuIndex = idx;
    },
    scrollTop() {
      window.scroll({
        left: 0,
        top: 0,
      });
    },
  },
};
</script>

<style lang="scss">
.vhp-header {

  @media screen and (max-width: #{$breakpoint-md}) {
    height: $header-mobile-height !important;
  }
  &.transparent {
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: transparent;
    color: $base-background-color;
    backdrop-filter: blur(3px);
  }

  &.white {
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: rgba($base-background-color, 1);
    color: #000;
  }

  .vhp-header-content {
    position: relative;
    max-width: $full-width;
    margin: 0 auto;

    .menu-title {
      
      font-family: "Pretendard-Bold" !important;
      &.transparent {
        font-family: "Pretendard-Thin";
        color: $base-background-color;
      }

      &.white {
        color: #000;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: calc(50% - 50vw);
      top: 0;
      z-index: -1;
      height: 100%;
      max-width: 100vw;
      width: 100vw;
      min-width: $min-width;
      // background-color: $base-background-color;
    }

    @media screen and (min-width: #{$breakpoint-tablet}) {
      &.dynamic {
        &::before {
          background-color: transparent;
        }
        .vhp-header-title {
          img {
            content: url("@/assets/image/logo-color-w.png");
          }
        }

        .vhp-header-menu {
          > li {
            > a {
              color: $base-background-color !important;
              
            }
          }
        }

        .vhp-header-util {
          .vhp-header-menu-btn {
            span {
              background-color: $base-background-color !important;
            }
          }
          .add {
            color: $base-background-color !important;
          }

          img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
              saturate(7500%) hue-rotate(261deg) brightness(104%) contrast(103%) !important;
          }
        }
      }
    }

    position: relative;
    margin: 0 auto;
    height: 100%;

    min-height: $header-height;
    @media screen and (max-width: #{$breakpoint-md}) {
      min-height: $header-mobile-height;
    }
    width: 100%;

    .vhp-header-title {
      position: absolute;
      height: 36px;
      line-height: 36px;
      top: calc(50% - 18px);
      left: 0px;
      z-index: 11;

      .logo {
        padding: 8px;
        @media screen and (max-width: #{$breakpoint-md}) {
          content: url("@/assets/image/logo-a.svg") !important;
        }
      }

      @media screen and (max-width: #{$breakpoint-tablet}) {
        left: 8px;
      }
    }

    .vhp-header-menu-wrap {
      position: relative;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;

      .outlink {
        position: relaitve;
        img {
          opacity: 0;
          transition: 0.15s ease-in-out;
        }

        &:hover {
          img {
            opacity: 1;
          }
        }
      }

      .vhp-header-menu {
        position: relative;
        text-align: center;
        list-style: none;
        display: inline-block;
        margin: 0;
        padding: 0;
        font-size: 16px;
        letter-spacing: -0.5px;
        line-height: $header-height;
        @media screen and (max-width: #{$breakpoint-md}) {
          line-height: $header-mobile-height;
        }
        @media screen and (max-width: #{$breakpoint-desktop}) {
          display: none;
        }

        li {
          display: inline-block;
          position: relative;

          a {
            font-family: "Pretendard-Medium";
            padding: 28px 40px;
            position: relative;
            color: $header-menu-foreground-color;
            
            transition: all 0.1s ease-in-out;

            &.active {
              font-weight: bold;
              color: $base-primary-color;

              // &:before{
              //   display: inline-block;
              //   position: absolute;
              //   top: calc(50% + 16px);
              //   left: calc(50% - 12px);
              //   content: "";
              //   width: 24px;
              //   height: 5px;
              //   border-radius: 4px;
              //   background: $base-button-active-color !important;
              //   animation-name: stretch_right;
              //   animation-duration: 150ms;
              //   animation-timing-function: ease-in-out;
              // } 
             
            }
          }

          &:hover {
            > a {
              font-weight: bold;
              color: $base-primary-color;
            }
          }
        }

        .main-menu{
          &:hover {
            > a {
                &:before {
                  display: inline-block;
                  position: absolute;
                  top: calc(50% + 16px);
                  left: calc(50% - 12px);
                  content: "";
                  width: 24px;
                  height: 5px;
                  border-radius: 4px;
                  background: $base-button-hover-color !important;
                  animation-name: stretch_right;
                  animation-duration: 150ms;
                  animation-timing-function: ease-in-out;
                }
            }
          }
        }

        
      }

      .vhp-header-submenu {
        position: absolute;
        top: 65px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        z-index: 10;
        margin: 0 auto;
        padding: 10px 25px;
        min-width: 40%;
        max-width: 100%;

        border: 1px solid $base-border-color;
        box-shadow: 2px 8px 32px #3a3a3c13;
        border-radius: 12px;
        background-color: $base-background-color;

        white-space: nowrap;
        animation-name: fade_down;
        animation-timing-function: ease-in-out;

        li {
          // text-align: left;
          display: block;
          padding: 0;
          line-height: 42px;
          margin: 0;
          a {
            cursor: pointer;
            display: block;
            width: calc(100%-42px);
            padding: 0;
            margin: 0;
            color: $header-submenu-foreground-color;
          }

          .highlight{
            color: $base-primary-color !important;
            font-weight: bold;
          }
        }
      }
      .vhp-header-search {
        position: fixed;
        width: 100%;
        max-width: 100vw;
        min-width: $min-width;
        margin: 0;
        left: 0;
        top: $header-height;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        padding: 15px 0 50px 0;
        animation: fade_down 300ms ease-in-out;
        z-index: 1000 !important;
        background-color: $base-background-color !important;

        @media screen and (max-width: #{$breakpoint-md}) {
          top: $header-mobile-height !important;
        }

        &::before {
          content: "";
          position: absolute;
          width: 100vw;
          height: 100vh;
          max-width: 100%;
          top: 150px;
          left: 0;
          background-color: rgba(0,0,0,0.1);
          -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
          transition: none !important;
        }

        .search-wrap {
          background-color: rgba($base-background-color, 1);
          width: calc(100% - 24px);
          max-width: 760px;
          margin: 12px;
          z-index: 0;

          .vhp-input {
            input {
              padding-top: 16px;
              padding-bottom: 16px;
              font-size: 24px;
              border-radius: 0;
              background-color: transparent !important;

              &:focus {
                border-radius: 0;
              }

              &.append-icon{
                padding-left: 64px !important;
              }

            
            }

            .icon {
              width: 24px !important;
              height: 24px !important;
              left: 24px !important;
              top: calc(50% - 12px) !important;
            }

          }

          
          @media screen and (max-width: #{$breakpoint-md}) {
            .vhp-input {
              input {
                font-size: 16px;

                &.append-icon{
                  padding-left: 42px !important;
                }
              }
              .icon {
                width: 16px !important;
                height: 16px !important;
                left: 16px !important;
                top: calc(50% - 8px) !important;
              }

            }
          }
        }
      }

      .vhp-header-allmenu {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        border: 1px solid rgba(0, 0, 0, 0.06);
        box-shadow: 0 2px 32px #00000010;

        box-shadow: 0 2px 64px #00000010;

        box-shadow: 0 2px 128px #00000010;

        padding: $header-height 0 30px 0;
        background-color: $base-background-color;

        animation-name: slide_down_fixed_top;
        animation-duration: 200ms;
        animation-timing-function: ease-in-out;

        * {
          font-size: 16px;
        }

        @media screen and (max-width: #{$breakpoint-desktop}) {
          width: 100%;
          height: 100vh;

          animation-name: slide_left_fixed_top;
          animation-duration: 200ms;
        }

        .vhp-header-allmenu-item-wrap {
          margin: 0 auto;
          padding: 12px 0 24px 0;
          max-height: calc(100% - 60px);
          overflow-y: auto;

          @media screen and (max-width: #{$breakpoint-desktop}) {
            padding: 0;
          }

          position: relative;

          ul {
            display: inline;
            margin: 0;
            padding: 0;
          }

          .parent-item {
            cursor: pointer;
            position: relative;
            padding:8px 0;

            .arrow {
              position: absolute;
              right: 16px;
              top: calc(50% - 10px);
              width: 20px;
              height: 20px;

              display: flex;
              justify-content: center;
              align-items: center;
              transform: rotate(0deg);
              transition: 0.2s;

              span {
                width: 10px;
                height: 2px;
                position: absolute;
                top: 50%;
                left: 2px;
                transform: translate(-50%, -50%);
                transition: all 0.2s ease-in-out;
                background-color: #222222;
                transform: rotate(45deg);

                &:nth-of-type(2) {
                  top: calc(50%);
                  left: calc(50% - 2px);
                  transform: rotate(-45deg);
                }
              }
            }
          }

          .active {
            strong {
              color: $base-primary-color;
            }

            a {
              animation-name: slide_down;
              animation-duration: 300ms;
              animation-timing-function: ease-in-out;
            }

            .arrow {
              transform: rotate(180deg);
            }
          }
        }
        .header-allmenu-item {
          margin: 18px 0px;

          @media screen and (max-width: #{$breakpoint-desktop}) {
            margin: 18px 12px;
            max-width: calc(100% - 24px);
          }
        }

        strong {
          font-size: 17px;
        }

        li {
          text-align: left;
          display: inline-block;
          position: relative;
          vertical-align: top;

          @media screen and (max-width: #{$breakpoint-desktop}) {
            display: block;
            margin-left: 60px;
            margin: 4px auto 24px auto;

            strong {
              font-size: 20px;
            }

            a {
              padding: 0px 12px !important;
              display: block;
              font-size: 16px;
            }
          }

          a {
            cursor: pointer;
            color: $header-menu-foreground-color;
            padding: 0 56px;
          }
        }

        :hover {
          > a {
            font-weight: bold;
            color: $base-primary-color;
          }
        }
      }
    }

    .vhp-header-util {
      z-index: 11;
      position: absolute;
      display: flex;
      column-gap: 10px;
      justify-items: center;
      align-items: center;
      height: 36px;
      line-height: 36px;
      top: calc(50% - 18px);
      right: 0px;

      &.transparent {
        img {
          filter: brightness(0) saturate(100%) invert(99%) sepia(1%)
            saturate(1883%) hue-rotate(219deg) brightness(107%) contrast(100%);
        }
        .add {
          color: $base-background-color;
          font-family: "Pretendard-Thin";
        }
        .menu-btn-line {
          background-color: rgba(255, 255, 255, 0.75);
        }
      }

      @media screen and (max-width: #{$breakpoint-tablet}) {
        column-gap: 6px;
        right: 8px;
      }

      .vhp-button {
        color: black;
        &.globe {
          > span {
            font-family: 'Pretendard-SemiBold' !important;
            text-transform: uppercase;
            font-size: 0.85rem;
          }
        }
      }

      .vhp-header-menu-btn {
        display: inline-block;
        width: 17px;
        height: 17px;
        position: relative;
        z-index: 2;
        overflow: hidden;
        cursor: pointer;

        span {
          width: 16px;
          height: 2px;
          position: absolute;
          top: 50%;
          left: calc(50% + 2px);
          transform: translate(-50%, -50%);
          transition: all 0.2s ease-in-out;
          background-color: rgba(0, 0, 0, 0.75);

          &:nth-of-type(2) {
            top: calc(50% - 7px);
            left: calc(50% - 2px);
          }
          &:nth-of-type(3) {
            top: calc(50% + 7px);
            left: calc(50% - 2px);
          }
        }

        &:hover {
          span {
            width: 16px;

            &:nth-of-type(1) {
              top: 50%;
              left: 50%;
            }
            &:nth-of-type(2) {
              top: calc(50% - 7px);
              left: 50%;
            }
            &:nth-of-type(3) {
              top: calc(50% + 7px);
              left: 50%;
            }
          }
        }

        &.on {
          transform: translateX(0);

          span {
            width: 20px;

            &:nth-of-type(1) {
              display: none;
            }
            &:nth-of-type(2) {
              top: 50%;
              left: 50%;
              transform: translate(-50%, 0%) rotate(-45deg);
            }
            &:nth-of-type(3) {
              top: 50%;
              left: 50%;
              transform: translate(-50%, 0%) rotate(45deg);
            }
          }
        }
      }
    }
  }
}
</style>
