<template>
  <div
    class="vhp-input"
    :class="[fullWidth ? 'full' : '', dense ? 'dense' : '']"
  >
    <label v-if="label" class="vhp-input-label" for="select">{{ label }}</label>
    <div class="select">
      <select
        name="select"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :class="[
          label ? 'label' : '',
          { empty: !modelValue },
          outline ? 'outline' : '',
          dense ? 'dense' : '',
        ]"
        :required="required"
      >
        <option v-if="placeholder" value="" class="placeholder">
          {{ placeholder }}
        </option>
        <option
          v-for="(opt, idx) in items"
          :key="'vhp-input-option-' + idx"
          :value="opt[itemValue] ? opt[itemValue] : opt"
          class="option"
        >
          {{ opt[itemKey] ? opt[itemKey] : opt }}
        </option>
      </select>
      <div class="arrow">
        <span />
        <span />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {
        return null;
      },
    },
    modelValue: {
      type: String,
      default: "",
      required: true,
    },
    itemKey: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    label: {
      type: String,
      default: () => {
        return "";
      },
    },
    placeholder: {
      type: String,
      default: () => {
        return "";
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
