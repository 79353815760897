<template>
  <div class="vhp-dialog">
    <div class="dialog" :id="id">
      <div class="dialog-container">
        <div class="header">
          <h3 v-if="title">{{ title }}</h3>

          <button
            @click="preventClose ? $emit('close') : close()"
            class="close-btn"
          >
            <i class="fas fa-close"/>
          </button>
        </div>

        <div class="content">
          <slot name="content" />
        </div>

        <div class="action" v-if="$slots.action">
          <slot name="action"/>
        </div>
      </div>
    </div>

    <!-- dialog 배경 sheet -->
    <div v-if="overlay" class="dialog-sheet" :id="id" @click="close"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    preventClose: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    // STYLE
    width: {
      type: [String, Number],
      default: 550,
    },
    height: {
      type: [String, Number],
      default: 550,
    },
  },
  data() {
    return {};
  },
  computed: {
    dialogStyle() {
      return {
        width: this.width + "px",
        height: this.height + "px",
      };
    },
  },
  methods: {
    load() {
      // document.querySelector("body").style.overflow = "hidden";
      document.querySelector(`#${this.id}`).style.display = "block";
      document.querySelector(`.dialog-sheet#${this.id}`).style.display =
        "block";

      this.$emit("load");
    },

    close() {
      // document.querySelector("body").style.overflow = "auto";
      document.querySelector(`#${this.id}`).style.display = "none";
      document.querySelector(`.dialog-sheet#${this.id}`).style.display = "none";
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.vhp-dialog .dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 2002;

  .dialog-container {
    position: relative;
    height: 100%;
    min-width:320px;
    box-shadow: rgba(149, 157, 165, 0.3) 0px 16px 32px;
    border-radius: 0.5rem;
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    animation-name: zoom_out;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
    .header{
      height: 24px;
      width:calc(100% - 36px);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      padding:18px;

      h3{
        padding:0;
        margin:0;
      }
    }

    .close-btn {
      cursor: pointer;
      margin-left:auto;
      border: none;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      text-align: center;
      font-size: 1rem !important;
      
      &:hover {
        background-color: $base-primary-transparent-color;
        border-radius: 100%;
      }
    }

    .content{
      width: calc(100% - 36px);
      padding: 0 18px 18px 18px;
      display: flex;
      flex-direction: column;
      max-height: calc(75vh);
      overflow-y: auto;
    }
    .action{
      height: 24px;
      width:calc(100% - 36px);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      padding: 0 18px 18px 18px;
    }
  }
}

.dialog-sheet {
  display: none;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  height: calc(100% + 70px);
  background-color: #222;
  position: fixed;
  top: -$header-height;
  left: 0;
  opacity: 30%;
  z-index: 2001;
}
</style>
