<template>
  <div>
    <preparation-layout></preparation-layout>
  </div>
</template>

<script>
import PreparationLayout from "@/components/layouts/PreparationLayout.vue";
export default {
  components: { PreparationLayout },
  name: "PreparationIndex",
};
</script>

<style></style>
