<template>
  <div>
    <vhp-breadcrumb/>
    <div class="vhp-main-content-title">
      <h1 class="page-title">{{$t('개인정보 처리방침')}}</h1>
    </div>


    <div class="vhp-privacy-container">
      (주)바질컴퍼니('http://www.vazilcompany.com'이하 '바질컴퍼니')은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
      - 이 개인정보처리방침은 2023년 1월 1부터 적용됩니다.

      <div class="privacy-list-wrap">
        <h2>개인정보의 처리 목적</h2>

        <div>
          
          (주)바질컴퍼니('http://www.vazilcompany.com'이하 '바질컴퍼니')은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
          
          <br/>
          <br/>
          1. 민원사무 처리
          민원인의 신원 확인, 민원사항 확인, 처리결과 통보 목적으로 개인정보를 처리합니다.
          <br/>

          2. 마케팅 및 광고에의 활용
          접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
          <br/>

          6. 기타
          상담 및 서비스 신청 등을 목적으로 개인정보를 처리합니다.
        </div>

      </div>


      <div class="privacy-list-wrap">
        <h2>개인정보의 처리 및 보유 기간</h2>

        <div>
          (주)바질컴퍼니은(는) 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니다
        </div>
      </div>


      <div class="privacy-list-wrap">
        <h2>처리하는 개인정보의 항목</h2>
        <div>
          (주)바질컴퍼니은(는) 다음의 개인정보 항목을 처리하고 있습니다.
          <br/>
          <br/>

          필수항목 : 쿠키, 접속 IP 정보
          <br/>
          선택항목 : 이메일, 휴대전화번호, 이름, 회사전화번호, 회사명
        </div>
      </div>


      <div class="privacy-list-wrap">

        <h2>개인정보의 파기절차 및 파기방법</h2>
        <div>
          (주)바질컴퍼니 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
          <br/>

          정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
          <br/>
          <br/>
          1. 법령 근거 :
          <br/>
          2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜
          <br/>
          <br/>

          개인정보 파기의 절차 및 방법은 다음과 같습니다.
          <br/>
          1. 파기절차
          <br/>
          (주)바질컴퍼니 은(는) 파기 사유가 발생한 개인정보를 선정하고,  (주)바질컴퍼니 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
          <br/>
          <br/>
          2. 파기방법
          <br/>

          전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다
        </div>

      </div>


      <div class="privacy-list-wrap">

        <h2>정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항</h2>
        <div>
          1.정보주체는 (주)바질컴퍼니에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
          <br/>

          2.제1항에 따른 권리 행사는(주)바질컴퍼니에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 (주)바질컴퍼니은(는) 이에 대해 지체 없이 조치하겠습니다.
          <br/>

          3.제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
          <br/>

          4.개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
          <br/>

          5.개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
          <br/>

          6.(주)바질컴퍼니은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
          <br/>
        </div>

      </div>


      <div class="privacy-list-wrap">

        <h2>개인정보의 안전성 확보조치에 관한 사항</h2>
        <div>
          (주)바질컴퍼니은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
          <br/>
          <br/>

          1. 개인정보의 암호화
          <br/>
          이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
          <br/>
          <br/>

          2. 접속기록의 보관 및 위변조 방지
          <br/>
          개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다.
          또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
        </div>

      </div>


      <div class="privacy-list-wrap">

        <h2>개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항</h2>
        <div>
          1.(주)바질컴퍼니 은(는) 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
          <br/>
          <br/>
          2.쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
          <br/>
          가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
          <br/>
          나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
          <br/>
          다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
        </div>
      </div>

      <div class="privacy-list-wrap">
        <h2>개인정보 보호책임자에 관한 사항</h2>
        <div>
          1.(주)바질컴퍼니 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
          <br/>
          <br/>

          개인정보 보호책임자
          <br/>
          성명 :박경호
          <br/>
          직책 :선임연구원
          <br/>
          직급 :팀장
          <br/>
          연락처 :051-609-9633, programming@vazilcompany.com, 051-609-9634
          <br/>
          ※ 개인정보 보호 담당부서로 연결됩니다.
          <br/>
          <br/>

          개인정보 보호 담당부서
          <br/>
          부서명 : 기업부설연구소
          <br/>
          담당자 : 박경호
          <br/>
          연락처 : 051-609-9633
          <br/>
          <br/>
          2.정보주체께서는 (주)바질컴퍼니 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. (주)바질컴퍼니 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
        </div>
      </div>


      <div class="privacy-list-wrap">
        <h2>정보주체의 권익침해에 대한 구제방법</h2>
        <div>

          정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
          <br/>
          <br/>

          1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
          <br/>
          2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
          <br/>
          3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
          <br/>
          4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
          <br/>
          <br/>

          「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
          
          <br/>
          ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
        </div>
      </div>

      <div class="privacy-list-wrap">
        <h2>개인정보 처리방침 변경</h2>
        <div>
          이 개인정보처리방침은 2023년 1월 1부터 적용됩니다.
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyIndex',
  computed:{
  },
  data() {
    return {
    }
  },


  methods:{
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.vhp-privacy-container{
  margin:64px 12px;
  padding:0;
  text-align: left;
  font-size:15px;
  line-height: 32px;

  .privacy-list-wrap{
    border-top: 1px solid $base-border-color;
    margin-top:24px;
    padding:24px 0;

    > div{

    }
  }

  h2{
    font-size:20px;
  }

}
</style>
