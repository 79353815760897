import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js';
import axios from 'axios'
import global from './plugins/global.js'
import messages from './i18n'

import VueCryptojs from 'vue-cryptojs'
import { VueEditor, Quill } from 'vue3-editor'
import AOS from 'aos'
import { createI18n } from 'vue-i18n'
import '@fortawesome/fontawesome-free/css/all.css'
import 'aos/dist/aos.css'

import vhpCard from '@/components/cards/vhpCard.vue'
import vhpProductCard from '@/components/cards/vhpProductCard.vue'
import vhpSimpleCard from '@/components/cards/vhpSimpleCard.vue'
import vhpListCard from '@/components/cards/vhpListCard.vue'
import vhpTable from '@/components/table/vhpTable.vue'
import vhpSelect from '@/components/inputs/vhpSelect.vue'
import vhpSlide from '@/components/slide/vhpSlide.vue'
import vhpCardSlide from '@/components/slide/vhpCardSlide.vue'
import vhpProductSlide from '@/components/slide/vhpProductSlide.vue'
import vhpSimpleSlide from '@/components/slide/vhpSimpleSlide.vue'
import vhpEmpty from '@/components/etc/vhpEmpty.vue'
import vhpLoading from '@/components/etc/vhpLoading.vue'
import vhpDialog from '@/components/etc/vhpDialog.vue'
import vhpMenu from '@/components/etc/vhpMenu.vue'
import vhpPlayer from '@/components/video/vhpPlayer.vue'
import vhpBreadcrumb from '@/components/etc/vhpBreadcrumb.vue'
import vhpStepper from '@/components/stepper/vhpStepper.vue'
import vhpTabs from '@/components/tabs/vhpTabs.vue'


const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueCryptojs)
app.use(global)
app.use(AOS.init())
app.use(createI18n({
    locale:'kr',
    fallbackLocale:'en',
    messages
}))

var Block = Quill.import('blots/block')
Block.tagName = 'div';
Quill.register(Block)

app.component("vhp-card", vhpCard)
app.component("vhp-product-card", vhpProductCard)
app.component("vhp-simple-card", vhpSimpleCard)
app.component("vhp-list-card", vhpListCard)
app.component("vhp-table", vhpTable)
app.component("vhp-select", vhpSelect)
app.component("vhp-slide", vhpSlide)
app.component("vhp-card-slide", vhpCardSlide)
app.component("vhp-product-slide", vhpProductSlide)
app.component("vhp-simple-slide", vhpSimpleSlide)
app.component("vhp-empty", vhpEmpty)
app.component("vhp-loading", vhpLoading)
app.component("vhp-dialog", vhpDialog)
app.component("vhp-menu", vhpMenu)
app.component("vhp-player", vhpPlayer)
app.component("vhp-breadcrumb", vhpBreadcrumb)
app.component("vhp-stepper", vhpStepper)
app.component("vue-editor", VueEditor)
app.component("vhp-tabs", vhpTabs)


axios.defaults.baseURL = 'https://api.vazilcompany.com'
app.config.globalProperties.$axios = axios

app.mount('#app')


