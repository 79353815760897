<template>
  <div class="vhp-product-slide" v-if="items && items.length > 0">
    <div class="product-card">
      <div class="product-wrap">
        <div class="content-wrap">
          <div>
            <div class="vhp-text small red bold">
              {{ items[slide].brand }}
            </div>
            <div class="vhp-text bold" style="font-size: 1.8rem">
              {{ items[slide].title }}
            </div>
            <div class="vhp-text medium mt-4">
              {{ items[slide].subtitle }}<br />
            </div>
          </div>

          <div>
            <button
              class="vhp-button outline"
              @click="onClickItem(items[slide], false)"
            >
              {{ $t("상세보기") }}
            </button>
          </div>
        </div>

        <div v-if="showGlb && items[slide].glb" class="viewer-wrap">
          <viewer :url="items[slide].glb" />
        </div>
        <div v-else class="image-card-wrap">
          <img
            v-if="items[slide].thumbnail"
            class="image-card"
            :src="items[slide].thumbnail"
            cover
          />
          <img
            v-else
            class="image-card"
            :src="items[slide].images[0] || ''"
            cover
          />
        </div>
      </div>

      <div class="product-wrap">
        <div class="content-wrap">
          <div>
            <div class="vhp-text small red bold">
              {{ items[(slide + 1) % items.length].brand }}
            </div>
            <div class="vhp-text bold" style="font-size: 1.8rem">
              {{ items[(slide + 1) % items.length].title }}
            </div>
            <div class="vhp-text medium mt-4">
              {{ items[(slide + 1) % items.length].subtitle }}<br />
            </div>
          </div>

          <div>
            <button
              class="vhp-button outline"
              @click="onClickItem(items[(slide + 1) % items.length], false)"
            >
              {{ $t("상세보기") }}
            </button>
          </div>
        </div>

        <div
          v-if="showGlb && items[(slide + 1) % items.length].glb"
          class="viewer-wrap"
        >
          <viewer :url="items[(slide + 1) % items.length].glb" />
        </div>
        <div v-else class="image-card-wrap">
          <img
            v-if="items[(slide + 1) % items.length].thumbnail"
            class="image-card"
            :src="items[(slide + 1) % items.length].thumbnail"
            cover
          />
          <img
            v-else
            class="image-card"
            :src="items[(slide + 1) % items.length].images[0] || ''"
            cover
          />
        </div>
      </div>

      <div class="slide-button-wrap">
        <div class="slide-button prev" @click="prev()">
          <span />
          <span />
        </div>

        <div class="slide-button next" @click="next()">
          <span />
          <span />
        </div>

        <div class="slide-sequence">
          <span
            v-for="n in items.length"
            :key="'sequence-' + n"
            :class="
              n - 1 <= slide + (maxCount - 1) && n - 1 >= slide ? 'active' : ''
            "
          >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import viewer from "@/components/etc/vhp3dViewer.vue";
export default {
  components: {
    viewer,
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    baseUrl: {
      type: String,
      default: "/",
    },
    listUrl: {
      type: String,
      default: "/",
    },
    show3d: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {
      showGlb: false,
      slide: 0,
      maxCount: 1,
    };
  },
  mounted() {
    if (this.show3d) {
      this.showGlb = true;
    }
  },
  watch: {},
  methods: {
    prev() {
      this.slide =
        this.slide > 0 ? this.slide - 1 : this.items.length - this.maxCount;
    },
    next() {
      this.slide =
        this.slide < this.items.length - this.maxCount ? this.slide + 1 : 0;
    },
    onClickItem(item, inquiry) {
      if (inquiry) {
        this.$router.push(
          this.baseUrl +
            "?id=" +
            item.id +
            "&list=" +
            this.listUrl +
            "&inquiry=true"
        );
      } else {
        this.$router.push(
          this.baseUrl + "?id=" + item.id + "&list=" + this.listUrl
        );
      }
    },
  },
};
</script>

<style lang="scss">
.vhp-product-slide {
  * {
    // border: 1px solid;
  }
  .product-card {
    width: 100%;
    max-width: calc(100vw- 200px);
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .product-wrap {
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      // width: 60vw;
      // max-width: 500px;
      height: 300px;
      padding: 50px 60px;
      margin: 0 10px;
      position: relative;
      border-radius: 14px;
      // border: 1px solid $base-border-color;
      background-color: $base-background-color;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 16px 32px;

      .content-wrap {
        width: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .vhp-button {
          width: auto;
        }
      }

      .image-card-wrap {
        position: relative;
        width: 100%;
        padding: 10px;
        margin: auto;
        .image-card {
          display: block;
          width: 100%;
          max-width: 300px;
          height: auto;
          aspect-ratio: 1;
          object-fit: cover;
          transition: all 0.2s ease-in-out;

          &:hover {
            // transform: scale(1.02);
          }
        }
      }

      .viewer-wrap {
        height: 100%;
        width: 100%;
        max-height: 100%;
        aspect-ratio: 1;
        object-fit: cover;
      }
    }

    .slide-button-wrap {
      position: absolute;
      bottom: -60px;
      left: 0;
      display: flex;
      justify-items: center;
      .slide-sequence {
        padding: 0;
        margin: 6px 6px 6px 24px;
        height: 32px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        span {
          width: 18px;
          height: 2px;
          background-color: $base-button-default-background-color;
          transition: all 0.2s ease-in-out;
          display: inline-block;
          margin: 0 0;

          &.active {
            background-color: $base-primary-color;
          }
        }
      }
      .slide-button {
        padding: 0;
        display: inline-block;
        margin: 6px;
        height: 32px;
        width: 32px;

        border-radius: 100%;
        position: relative;
        border: 1px solid $base-button-default-background-color;
        cursor: pointer;

        span {
          width: 8px;
          height: 2px;
          position: absolute;
          top: calc(50% + 1.5px);
          left: 11px;
          transition: all 0.1s ease-in-out;
          background-color: $base-button-background-color;
          transform: rotate(45deg);

          &:nth-of-type(2) {
            top: calc(50% - 3px);
            right: 11px;
            transform: rotate(-45deg);
          }
        }

        &.prev {
          transform: rotate(0);
          &:active {
            transform: scale(0.95);
          }
        }

        &.next {
          transform: rotate(180deg);
          &:active {
            transform: scale(0.95) rotate(180deg);
          }
        }

        &.disabled {
          opacity: 0.5;
        }
      }
    }

    @media screen and (max-width: #{$breakpoint-desktop}) {
      flex-direction: column;
      .product-wrap {
        margin: 10px;
      }
    }
  }
}
</style>
