<template>
  <div class="admin-page admin-patent">
    <h1>특허</h1>

    <div class="menus">
      <button @click="$refs.createPatentDialog.load()" class="vhp-button">
        특허 추가
      </button>
    </div>

    <vhp-table
      :headers="[
        {
          text: 'No',
          value: 'no',
        },
        {
          text: '국가',
          value: 'country',
        },
        {
          text: '제목',
          value: 'title',
        },
        {
          text: '출원번호',
          value: 'applicationNumber',
        },
        {
          text: '출원일',
          value: 'applicationDate',
        },
        {
          text: '등록번호',
          value: 'registrationNumber',
        },
        {
          text: '등록일',
          value: 'registrationDate',
        },
        {
          text: '수정',
          value: 'edit',
        },
        {
          text: '삭제',
          value: 'delete',
        },
      ]"
      :items="patentList"
    >
      <template v-slot:item-edit="{ item }">
        <img
          v-if="item.username !== 'admin'"
          @click="(edit = item), $refs.editPatentDialog.load()"
          src="@/assets/image/edit.svg"
          height="15"
          alt="lock-icon"
          class="pointer"
        />
      </template>

      <template v-slot:item-delete="{ item }">
        <img
          v-if="item.username !== 'admin'"
          @click="deletePatent(item)"
          src="@/assets/image/trash-can-outline.svg"
          height="20"
          alt="lock-icon"
          class="pointer"
        />
      </template>
    </vhp-table>

    <vhp-dialog
      title="특허 추가"
      id="create-patent"
      class="create-dialog"
      ref="createPatentDialog"
      width="370"
      height="400"
    >
      <template v-slot:content>
        <div>
          <form @submit.prevent="createPatent" method="post">
          
        

            <label  for="No">{{ $t("No") }}</label>
            <input
              name="No"
              v-model="patent.no"
              placeholder="No"
              type="text"
              maxlength="200"
              required
            />

            <label  for="국가">{{ $t("국가") }}</label>
            <input
              name="국가"
              v-model="patent.country"
              placeholder="국가"
              type="text"
              maxlength="200"
              required
            />

            <label  for="제목">{{ $t("제목") }}</label>
            <input
              name="제목"
              v-model="patent.title"
              placeholder="제목"
              type="text"
              maxlength="200"
              required
            />

            <label  for="출원번호">{{ $t("출원번호") }}</label>
            <input
              name="출원번호"
              v-model="patent.applicationNumber"
              placeholder="출원번호"
              type="text"
              maxlength="200"
            />

            <label  for="출원일">{{ $t("출원일") }}</label>
            <input
              v-model="patent.applicationDate"
              placeholder="출원일"
              type="date"
              name="출원일"
              maxlength="50"
            />

            <label  for="등록번호">{{ $t("등록번호") }}</label>
            <input
              name="등록번호"
              v-model="patent.registrationNumber"
              placeholder="등록번호"
              type="text"
              maxlength="200"
            />
            
            <label  for="등록일">{{ $t("등록일") }}</label>
            <input
              v-model="patent.registrationDate"
              placeholder="등록일"
              name="등록일"
              type="date"
              maxlength="50"
            />

            <label  for="이미지">{{ $t("이미지") }}</label>
            <img v-if="!patent.image" src="@/assets/image/etc/noimage.png" @click="$refs.imageInput.click()"/>
            <img v-else :src="patent.image" @click="$refs.imageInput.click()"/>
            <input
              ref="imageInput"
              type="file"
              name="이미지"
              accept="image/*"
              @change="addImage($event, patent)"
              hidden
            />
            <button class="save-btn vhp-button">추가</button>
          </form>
        </div>
      </template>
    </vhp-dialog>

    <vhp-dialog
      title="내용 수정"
      id="edit-patent"
      class="create-dialog"
      ref="editPatentDialog"
      width="370"
      height="400"
    >
      <template v-slot:content>
        <div>
          <form @submit.prevent="editPatent" method="put">
            <label  for="No">{{ $t("No") }}</label>
            <input
              name="No"
              v-model="edit.no"
              placeholder="No"
              type="text"
              maxlength="200"
              required
            />

            <label  for="국가">{{ $t("국가") }}</label>
            <input
              name="국가"
              v-model="edit.country"
              placeholder="국가"
              type="text"
              maxlength="200"
              required
            />

            <label  for="제목">{{ $t("제목") }}</label>
            <input
              name="제목"
              v-model="edit.title"
              placeholder="제목"
              type="text"
              maxlength="200"
              required
            />

            <label  for="출원번호">{{ $t("출원번호") }}</label>
            <input
              name="출원번호"
              v-model="edit.applicationNumber"
              placeholder="출원번호"
              type="text"
              maxlength="200"
            />

            <label  for="출원일">{{ $t("출원일") }}</label>
            <input
              v-model="edit.applicationDate"
              placeholder="출원일"
              type="date"
              name="출원일"
              maxlength="50"
            />

            <label  for="등록번호">{{ $t("등록번호") }}</label>
            <input
              name="등록번호"
              v-model="edit.registrationNumber"
              placeholder="등록번호"
              type="text"
              maxlength="200"
            />
            
            <label  for="등록일">{{ $t("등록일") }}</label>
            <input
              v-model="edit.registrationDate"
              placeholder="등록일"
              name="등록일"
              type="date"
              maxlength="50"
            />

            <label  for="이미지">{{ $t("이미지") }}</label>
            <img v-if="!edit.image" src="@/assets/image/etc/noimage.png" @click="$refs.imageInput.click()"/>
            <img v-else :src="edit.image" @click="$refs.imageInput.click()"/>
            <input
              ref="imageInput"
              type="file"
              name="이미지"
              accept="image/*"
              @change="addImage($event, edit)"
              hidden
            />
            
            <button class="save-btn vhp-button">수정</button>
          </form>
        </div>
      </template>
    </vhp-dialog>
  </div>
</template>

<script>
export default {
  name: "AdminPatent",
  data: () => {
    return {
      patentList: [],
      patent: {
        no: "",
        country:"",
        title:"",
        applicationNumber:"",
        applicationDate: "",
        registrationNumber: "",
        registrationDate:"",
        image:""
      },
      edit: {
        id: "",
        no: "",
        country:"",
        title:"",
        applicationNumber:"",
        applicationDate: "",
        registrationNumber: "",
        registrationDate:"",
        image:""
      },
    };
  },
  methods: {
    addImage(e, target){
      const file = e.target.files[0]
      if(file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            // target.image = reader.result
            const img = new Image();
            img.onload = function() {
                let width = img.width;
                let height = img.height;
                const maxDimension = 320;

                if (width > height) {
                    // 너비가 높이보다 클 경우
                    if (width > maxDimension) {
                        height *= maxDimension / width;
                        width = maxDimension;
                    }
                } else {
                    // 높이가 너비보다 클 경우
                    if (height > maxDimension) {
                        width *= maxDimension / height;
                        height = maxDimension;
                    }
                }
                
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                
                canvas.width = width;
                canvas.height = height;
                
                ctx.drawImage(img, 0, 0, width, height);
                
                const resizedBase64 = canvas.toDataURL(file.type);
                target.image = resizedBase64;
            };
            img.src = e.target.result;
        };
        reader.readAsDataURL(file); // 파일을 읽어서 Base64로 변환
      }
    }, 
    async getPatentList() {
      await this.$axios
        .get("/patent")
        .then((res) => {
          this.patentList = res.data.sort(
            (a, b) => new Date(b.updatedDate) - new Date(a.updatedDate)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async createPatent() {
      let requiredKeys = ['no', 'contry', 'title']
      for(let k of Object.keys(this.patent)) {
        if(!this.patent[k] && requiredKeys.includes(k)){
          alert("값을 모두 입력해주세요.");
          return
        }
      }
    
      this.$axios
        .post("/patent", this.patent)
        .then(() => {
          window.alert("추가되었습니다.");
          this.patent.patentDate = "";
          this.patent.content = "";
          this.$refs.createPatentDialog.close();
          this.getPatentList();
        })
        .catch((err) => {
          if (err.response.data === "DUPLICATED") {
            window.alert("해당 연혁이 이미 존재합니다.");
          } else {
            window.alert("콘텐츠를 추가할 수 없습니다. 개발팀에 문의하세요.");
          }

          console.log("SIGNUP ERROR : ", err);
        });
    },

    async editPatent() {
      this.$axios
        .put("/patent", this.edit)
        .then(() => {
          window.alert("수정되었습니다.");
          this.$refs.editPatentDialog.close();
          this.getPatentList();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async deletePatent(item) {
      const res = window.confirm("콘텐츠를 삭제하시겠습니까?");
      if (!res) return;

      this.$axios
        .delete("/patent", {
          params: {
            id: item.id,
          },
        })
        .then(() => {
          this.getPatentList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getPatentList();
  },
};
</script>

<style lang="scss">
.admin-page.admin-patent {
  .menus {
    margin: 30px 0;
    text-align: right;
  }

  .create-dialog {
    text-align: start;
    label{
      display: block;
    }
    input {
      width: calc(100% - 24px);
      border: none;
      margin-bottom: 20px;
      background: #bc1d2d14;
      padding: 12px;
      border-radius: 0.4rem;
    }

    form{
      display: flex;
      flex-direction: column;
      min-width:320px;
      width:640px;
      max-width: 100%;
      > img{
        object-fit: contain;
        max-width: 320px;
        width:100%;
        cursor: pointer;
      }
    }
  }
}
</style>
