<template>
  <div>
    <vhp-breadcrumb/>
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'NewsIndex',
  components:{
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods:{
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
