<template>
  <div class="admin-page admin-product">
    <h1>제품 관리</h1>

    <div class="menus">

      <button class="vhp-button ml-2" @click="$refs.categoryDialog.load()">카테고리 관리</button>
      <button class="vhp-button ml-2" @click="createProduct">제품 등록</button>
      <button class="vhp-button ml-2" @click="preview = !preview">
        <i v-if="!preview" class="fa-solid fa-table-cells-large"></i>
        <i v-else class="fa-solid fa-list-ul"></i>
      </button>
    </div>

    <layout 
      v-if="preview"
      :contents="products"
      class="product-list"
      base-url="/admin/product/details"
      list-url="/product"
    />

    <vhp-table
      v-if="!preview && !loading && products?.length > 0"
      :headers="[
        {
          text: '아이디',
          value: 'id',
        },
        {
          text: '제품명',
          value: 'title',
        },
        {
          text: '가격',
          value: 'price',
        },
        {
          text: '등록일',
          value: 'createdDate',
        },
        {
          text: '수정일',
          value: 'updatedDate',
        },
        {
          text: '삭제',
          value: 'action',
        },
      ]"
      :items="products"
    >
      <template v-slot:item-title="{ item }">
        <a @click="editProduct(item)">{{ item.title }}</a>
      </template>
      <template v-slot:item-id="{ item }">
        <a @click="editProduct(item)">{{ item.id }}</a>
      </template>
      <template v-slot:item-price="{ item }">
        <div v-if="item.price && typeof item.price === 'number'">
          {{ item.price.toLocaleString() }}
        </div>
        <div v-else>가격협의</div>
      </template>
      <template v-slot:item-action="{ item }">
        <img
          @click="deleteProduct(item.id)"
          src="@/assets/image/trash-can-outline.svg"
          height="20"
          alt="lock-icon"
          class="pointer"
        />
      </template>
    </vhp-table>

    <vhp-dialog
      title="카테고리"
      id="category-dialog"
      ref="categoryDialog"
    >
      <template v-slot:content>
        <div 
          class="category-item"
        >
          <strong>
            이름
          </strong>
          <div>
            설명
          </div>
        </div>

        <div 
          v-for="item in categories" :key="item.id"
          class="category-item"
        >
          <strong  v-if="item !== editCategory || !isEdit">
            {{ item.name }}
          </strong>

          <strong class="vhp-input dense" v-else>
            <label class="vhp-input-label" for="이름" v-show="!editCategory.name">{{ $t("이름") }}</label>
            <input
              @input="changed = true"
              class="transparent outline"
              type="text"
              v-model="editCategory.name"
              required
              name="이름"
              label="새로운 카테고리"
            />
          </strong>

          <div  v-if="item !== editCategory || !isEdit">
            {{ item.description }}
          </div>

          <div class="vhp-input dense" v-else>
            <label class="vhp-input-label" for="설명"  v-show="!editCategory.description">{{ $t("설명") }}</label>
            <input
              @input="changed = true"
              class="transparent outline"
              type="text"
              name="설명"
              v-model="editCategory.description"
              required
            />
          </div>

          <i class="fas fa-minus" @click="deleteCateogry(editCategory)" v-if="item === editCategory && isEdit"/>
          <i class="fas fa-check" @click="saveCateogry(editCategory)" v-if="item === editCategory && isEdit"/>
          <i class="fas fa-pencil" @click="tempItem = JSON.parse(JSON.stringify(item)), editCategory = item, isEdit = true" v-else/>
        </div>

        <button
          class=" vhp-button "
          v-if="!isEdit"
          @click="categories.push(JSON.parse(JSON.stringify(category))), editCategory = categories[categories.length - 1], isEdit = true"
        >
          카테고리 추가
        </button>
      </template>
    </vhp-dialog>
  </div>
</template>

<script>
import layout from "@/components/layouts/ProductListLayout.vue";
export default {
  name: "AdminProduct",
  components: { layout },
  data() {
    return {
      loading: true,
      products: [],
      preview: false,
      categories:[],
      category:{
        name:'',
        description:''
      },
      editCategory: null,
      tempItem: null,
      isEdit: false,
    };
  },

  computed: {},
  methods: {
    init() {
      this.getProducts();
      this.getCategories();
    },

    async getProducts() {
      this.loading = true;

      await this.$axios
        .get("/product")
        .then((res) => {
          this.products = res.data.sort((a, b) => new Date(b.updatedDate) - new Date(a.updatedDate));
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },

    async getCategories() {
      this.loading = true;

      await this.$axios
        .get("/category")
        .then((res) => {
          this.categories = res.data
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },

    async saveCateogry(item){
      if(!item.name || !item.description) {
        alert('내용을 입력해주세요.')
        return
      }
      
      if(item.id) {
        await this.$axios.put('/category', item).catch(err=>{console.log(err)})
      } else {
        await this.$axios.post('/category', item).catch(err=>{console.log(err)})
      }
      this.editCategory = null
      this.isEdit = false
      this.getCategories()
    },

    
    async deleteCateogry(item){
      const res = window.confirm("카테고리를 삭제하시겠습니까?");
      if (!res) return;

      if(item.id) {
        await this.$axios.delete('/category?id=' + item.id).catch(err=>{console.log(err)})
      }
      this.editCategory = null
      this.isEdit = false
      this.getCategories()
    },

    editProduct(item) {
      this.$router.push({
        name: "adminProductDetails",
        query: { type: "edit", id: item.id },
      });
    },

    deleteProduct(productId) {
      const res = window.confirm("제품을 삭제하시겠습니까?");
      if (!res) return;

      this.$axios
        .delete("/product", {
          params: {
            productId: productId,
          },
        })
        .then(() => {
          this.products = this.products.filter(
            (product) => product.id !== productId
          );
        })
        .catch((err) => {
          console.log(err);
          alert("오류가 발생하였습니다. 다시 시도해주세요.");
        });
    },

    createProduct() {
      this.$router.push({
        name: "adminProductDetails",
        query: { type: "create" },
      });
    },
  },
  mounted() {
    if (!this.$store.state.isAdmin) {
      alert("관리자 권한 없음");
      this.$router.push({ path: "/" });
    }

    this.init();
  },
  watch: {},
};
</script>

<style lang="scss">
.admin-page.admin-product {
  .menus {
    margin: 30px 0;
    text-align: right;
  }
  .product-list {
    width: 100%;
    padding-bottom: 60px;
  }
  .category-item{
    width:480px;
    min-width: 320px;
    display: flex;
    flex-direction: row;
    text-align: start;
    margin:12px 0;
    font-size:0.95rem;
    align-items: center;

    strong{
      margin:0 6px;
      flex: 0 1 ;
      min-width:100px;
    }
    > div {
      margin:0 6px;
      flex: 1 1;
      display: flex;
      flex-direction: row;
    }
    i {
      cursor: pointer;
      padding:6px;
      font-size: 1rem;
      opacity: 0.5;
    }

    
  }
}
</style>
