<template>
  <div class="vhp-list-card">
    <div class="vhp-card-wrap" @click="onClickCard(content)">
      <div
        class="vhp-card-background"
        :style="{ backgroundColor: backgroundColor }"
      ></div>

      <div
        v-if="imageLeft"
        class="vhp-card-image mr-16"
        data-aos-duration="800"
        data-aos="fade-right"
      >
        <img
          alt="card-image"
          :src="content.image || ''"
          cover
          @error="replaceImage"
        />
      </div>

      <div class="vhp-card-content-wrap">
        <div
          class="vhp-card-content"
          :class="!imageLeft ? 'right' : ''"
          data-aos-duration="800"
          data-aos="fade-up"
        >
          <strong class="vhp-card-content-title">{{ content.title }}</strong>
          <p class="vhp-card-content-subtitle" v-text="content.subtitle"></p>

          <div class="vhp-card-content-tags" v-if="content.hashTags">
            <span
              v-for="tag in content.hashTags.slice(0, 5)"
              :key="'hashtag-' + tag"
              class="tag"
            >
              # {{ tag }}
            </span>
            <span v-if="content.hashTags.length > 5"> .. </span>
          </div>
        </div>
      </div>

      <div
        v-if="!imageLeft"
        class="vhp-card-image ml-16"
        data-aos-duration="800"
        data-aos="fade-left"
      >
        <img
          alt="card-image"
          :src="content.image || ''"
          cover
          @error="replaceImage"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {
        return null;
      },
    },
    baseUrl: {
      type: String,
      default: "/",
    },
    listUrl: {
      type: String,
      default: "/",
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    imageLeft: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onClickCard(content) {
      if (!content.id) {
        this.$router.push("/preparation");
      } else if (content.isExternalLink) {
        window.open(content.externalUrl);
      } else {
        this.$router.push(
          this.baseUrl + "?id=" + content.id + "&list=" + this.listUrl
        );
      }
    },

    getCategoryImage(category) {
      return "@/assets/image/category/" + category.toLowerCase() + ".svg";
    },

    replaceImage(e) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const container = document.getElementsByClassName("vhp-card-image")[0];

      canvas.width = container.clientWidth;
      canvas.height = 280;

      ctx.font =
        "32px bold Apple SD Gothic Neo, Pretendard-Regular, NotoSansKR, sans-serif ";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = "#C4182890";
      ctx.fillRect(0, 0, container.clientWidth, 280);

      ctx.fillStyle = "#C4182830";
      ctx.beginPath();
      ctx.moveTo(container.clientWidth * 0.6, 0);
      ctx.lineTo(container.clientWidth, 0);
      ctx.lineTo(container.clientWidth, 200);
      ctx.fill();

      ctx.fillStyle = "#C4182860";
      ctx.beginPath();
      ctx.moveTo(container.clientWidth * 0.6, 280);
      ctx.lineTo(container.clientWidth, 280);
      ctx.lineTo(container.clientWidth, 140);
      ctx.fill();

      ctx.fillStyle = "#FFFFFF";
      ctx.fillText(this.content.title, container.clientWidth / 2, 140);
      let src = canvas.toDataURL("image/png", 1.0);
      e.target.src = src;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  //   border: 1px solid;
}
.vhp-list-card {
  display: flex;
  flex: 0 1 calc(100%);
  max-width: calc(100%);

  .vhp-card-wrap {
    padding: 8vh 7vw;
    width: calc(100%);
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &:hover {
      transition: all 0.2s ease-in-out;
      .vhp-card-content-title {
        &::before {
          width: 100% !important;
          bottom: calc(20% + 3px) !important;
          height: 6px !important;
        }
      }
      .vhp-card-image {
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  .vhp-card-background {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
    z-index: -10;
  }

  .vhp-card-image {
    width: 500px;
    height: 320px;
    max-width: 50%;
    border-radius: 14px;
    background-color: #eee;
    overflow: hidden;

    > img {
      transition: all 0.5s ease-in-out;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      object-fit: cover;
    }

    @media screen and (max-width: #{$breakpoint-desktop}) {
      height: 190px;
    }

    @media screen and (max-width: #{$breakpoint-md}) {
      height: 170px;
      border-radius: 0px;
    }
  }

  .vhp-card-content-wrap {
    flex-grow: 1;
    margin: auto;

    .vhp-card-content {
      width: calc(100% - 64px);
      width: 100%;
      position: relative;
      text-align: left;

      &.right {
        text-align: right;
      }

      @media screen and (max-width: #{$breakpoint-desktop}) {
        margin: 0 24px;
        width: calc(100% - 48px);
      }

      @media screen and (max-width: #{$breakpoint-md}) {
        margin: 0 12px;
        width: calc(100% - 24px);
      }

      .vhp-card-content-title {
        font-size: 1.8rem;
        font-family: 'Pretendard-Bold';
        max-width: 100%;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        &::before {
          transition: all 0.3s ease-in-out;
          content: "";
          position: absolute;
          width: 0;
          bottom: 20%;
          height: 0px;
          z-index: -10;
          opacity: 0.5;
          transform: rotate(-2deg);
          background-color: $base-primary-opacity-color;
        }

        @media screen and (max-width: #{$breakpoint-desktop}) {
          font-size: 18px;
        }

        @media screen and (max-width: #{$breakpoint-md}) {
          font-size: 16px;
        }
      }

      .vhp-card-content-subtitle {
        font-size: 1.15rem;
        display: block;
        text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 5;
        height: 155px;
        line-height: 24px;
        margin: 0;
        color: $base-foreground-subtitle-color;
        overflow: hidden;

        @media screen and (max-width: #{$breakpoint-desktop}) {
          height: 120px;
          font-size: 15px;
        }

        @media screen and (max-width: #{$breakpoint-md}) {
          height: 100px;
          -webkit-line-clamp: 4;
          padding: 0;
          margin: 0;
          font-size: 14px;
        }
      }

      .vhp-card-content-tags {
        position: absolute;
        bottom: -32px;
        left: 0;
        width: 100%;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        color: $base-foreground-sub-color;

        .tag {
          border: 0;
          padding: 8px 12px;
          border-radius: 10px;
          margin-right: 6px;
          background-color: $base-button-default-background-color;
        }

        @media screen and (max-width: #{$breakpoint-md}) {
          padding: 0;
          margin: 0;

          height: 24px;
          line-height: 24px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
