<template>
  <div class="error-page">
    <div class="image mt-2">
      <img src="@/assets/image/error.png" height="300"/>
    </div>
    <!-- <div class="error-code">404</div> -->
    <div class="message">
      <div class="vhp-text xlarge">
        <i class="fa-solid fa-circle-exclamation mr-1"></i>
        페이지를 찾을 수 없습니다.
      </div>
      <div class="vhp-text gray light mt-4">
        페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.
        <br />
        입력하신 주소가 정확한지 다시 한 번 확인해주세요.
      </div>
    </div>
    <div class="action">
      <div class="error-home vhp-button" @click="$router.push('/')">
        <div class="vhp-text">홈 화면</div>
      </div>
      <div class="error-home vhp-button" @click="$router.go(-1)">
        <div class="vhp-text pink">이전화면</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorIndex",
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
.error-page {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .error-code {
    font-size: 128px !important;
    font-family: "Pretendard-Bold" !important;
    color: $base-primary-opacity-color;
    margin: 5px 0 0 0;
    padding: 0;
  }

  .message {
    margin-bottom: 30px;
  }

  .action {
    display: flex;

    .vhp-button {
      padding: 10px 18px;
      margin: 4px;

      .vhp-text {
        font-family: "Pretendard-Medium";
      }
    }
  }
}
</style>
