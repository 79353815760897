<template>
  <div class="tech-container">
    
    <div class="tech-title">
        <h1 class="white--text">{{ $t("기술") }}</h1>
        <p class="white--text">{{ $t("기술_설명") }}</p>
    </div>

    <div class="tech-menus" v-if="technologyContents">
        
        <!-- <i class="fas fa-arrow-up white--text menu" style="cursor:pointer; " @click="scrollToContent(contentIndex - 1)" ></i> -->
        <div
          v-for="(item, index) in technologyContents"
          :key="item.title"
          class="menu"
          :class="contentIndex === index ? 'active' : ''"
          @click="scrollToContent(index)"
        >
          {{item.title}}
        </div>
        <!-- <i class="fas fa-arrow-down white--text menu" style="cursor:pointer; " @click="scrollToContent(contentIndex + 1)" ></i> -->

    </div>

     


    <div class="tech-contents"
      v-for="(item, index) in technologyContents"
      :key="'tc-' + item.title"
      :id="'contents-' + index"
    >

      <div class="tech-background"
        :style="`background-image: url(${item.image}); z-index: -10 + ${index}; opacity: ${index === contentIndex ? 1 : 0}; transition: opacity 1s ease-in-out;`"
      />

  
      <h1 class="content-title">
        {{ item.title }}
      </h1>

      <div
        v-for="(content, idx) in item.items"
        :key="'content-' + idx"
        class="tech-content"   
        :class="'content-' + index"   
        :id="'content-' + idx"
      >
        <div class="tech-visual" 
          v-if="content.image"
          data-aos-duration="1000"
          data-aos-offset="50"
          data-aos="fade-left"
          data-aos-easing="ease-in-out"
        >
          <img :src="content.image"/>
        </div>

        <div class="tech-description">
          <h1>{{ $i18n.locale === 'en' ? content.title_en : content.title }} </h1>
          <p>
            {{  $i18n.locale === 'en' ? content.description_en : content.description }}
          </p>

          <span v-if="content.tags">
            <span class="mr-2" v-for="tag in content.tags" :key="'tag-' + tag">
              # {{ tag }}
            </span> 
          </span>
        </div>
      </div>
    </div>

  
  </div>
</template>

<script>
export default {
  name: "TechIndex",

  data() {
    return {
      technologyContents: [
        {
          title: "ROBOT",
          subtitle: "technology description",
          image: require("@/assets/image/background/bg29.jpg"),
          hashTags: ["로봇", "용접로봇"],
          items:[{
            image:require("@/assets/image/tech/tech21.gif"),
            title:'용접 로봇',
            description: '스폿/아크 용접에 쓰이는 로봇이며, 로봇용접으로 더 정밀하고 일관성있는 품질과 다음 작업으로 빠르게 이동할 수 있어 전체적인 작업 속도를 높일 수 있습니다. 다양한 형상에 정밀하고 빠른 용접을 위해 로봇 캘리브레이션 기술과 용접선 추출 및 경로 최적화 기술을 발전시키고 있습니다.',
            title_en:'Welding Robot',
            description_en:'This is a robot used for spot/arc welding. Robot welding provides more precise and consistent quality and allows you to quickly move to the next task, increasing the overall work speed. We are developing robot calibration technology and weld line extraction and path optimization technology for precise and fast welding in various shapes.',
          },{
            image:require("@/assets/image/tech/tech20.gif"),
            title:'빈피킹 로봇',
            description: '무작위로 놓여 있는 다양한 제품을 3D 위치, 방향, 치수를 로봇에 탑재된 인공지능이 정확하게 찾을 수 있습니다. 이 기술을 이용하여 제조 공정에서는 주로 부품을 옮기고 해당 위치에 놓거나 정렬하고 기계에서 부품이 준비 되면 로봇이 부품을 픽업하여 다음 작업을 진행할 자리로 옮겨주는 등의 업무를 수행합니다. 이런 적재와 이송 작업은 제조 현장에서 가장 많이 사용되고 있습니다.',
            title_en:'Welding Robot',
            description_en:'The artificial intelligence mounted on the robot can accurately find the 3D location, direction, and dimensions of various randomly placed products. Using this technology, the manufacturing process primarily involves moving parts, placing or aligning them in position, and once the parts are ready on the machine, robots pick them up and move them to the next location. These loading and transport operations are most commonly used on manufacturing sites.',
          },{
            image:require("@/assets/image/tech/tech24.gif"),
            title:'플라즈마 절단 로봇',
            description: '협동로봇이 CAD 데이터에서 절단면을 추출하고 적절한 각도로 목표 형상에 맞게 플라즈마 아크 가공을 합니다. 이는 제조업, 조선업, 건설업 등에서 복잡한 형상과 두꺼운 금속 소재를 높은 속도와 정밀도로 절단하기 위한 기술입니다.',
            title_en:'Plasma Cutting Robot',
            description_en:'The collaborative robot extracts the cutting surface from CAD data and performs plasma arc processing at an appropriate angle to fit the target shape. This is a technology for cutting complex shapes and thick metal materials at high speed and precision in the manufacturing, shipbuilding, and construction industries.',
          },{
            image:require("@/assets/image/tech/tech23.gif"),
            title:'AI 융합 로봇',
            description: '복잡한 제조 공정 자동화 문제를 해결하기 위해 자체인공지능 기술을 로봇에 적용하고 여러가지 유형의 로봇을 융합하여 새로운 지능형 제조공정을 실현화 하고 있습니다.',
            title_en:'AI Convergence Robot',
            description_en:'To solve complex manufacturing process automation problems, we are applying our own artificial intelligence technology to robots and fusing various types of robots to realize new intelligent manufacturing processes.',
          }]
        },
        {
          title: "AI",
          subtitle: "technology description",
          image: require("@/assets/image/background/bg35.jpg"),
          hashTags: ["인공지능"],
          items:[{
            image:require("@/assets/image/tech/tech27.gif"),
            title:'3D & 2D 비전 물체 인식 모델',
            description: '카메라 또는 스캐너 장비를 통한 2D 이미지 또는 3D 포인트 클라우드 데이터 기반으로 인공지능이 로봇의 눈이되어 자유롭게 사물을 분류하고 찾습니다. 또한 자체 MLOps 플랫폼 연결을 통해 기존의 사물을 더욱 정확하게 인식하고 새로운 사물을 인식할 수 있도록 지속적인 학습 환경을 제공합니다.',
            title_en:'3D & 2D vision object recognition model',
            description_en:'Based on 2D image or 3D point cloud data through camera or scanner equipment, artificial intelligence becomes the robot`s eyes and freely classifies and finds objects. Additionally, through connection to its own MLOps platform, it provides a continuous learning environment to recognize existing objects more accurately and recognize new objects.',
          },{
            image:require("@/assets/image/tech/tech25.gif"),
            title:'sLLM 기반 로봇 제어',
            description: '온프레미스 환경에서 수집된 데이터를 통해 벡터 데이터베이스를 구축하고, 검색 증강 생성(RAG)하여 로봇과 실시간 대화를 통한 상호작용을 위해 프롬프트 엔지니어링을 하고있습니다.',
            title_en:'sLLM-based robot control',
            description_en:'We are building a vector database using data collected in an on-premises environment, using search augmented generation (RAG), and engineering prompts for interaction through real-time conversation with robots.',
          }]
        },
        {
          title: "3D & 2D VISION",
          subtitle: "technology description",
          image: require("@/assets/image/tech/tech13.jpg"),
          items:[{
            image:require("@/assets/image/tech/tech14.jpg"),
            title:'3D 비전 기술',
            description: 'RGB-D Camera, ToF Camera, LiDAR, Laser Profiler 등 각종 3D 센싱 장비를 통한 데이터를 취득하고 제조 환경에서 물체와 특징을 정확하게 인식하는 기술을 개발하고 있습니다.',
            title_en:'3D vision technology',
            description_en:'We are acquiring data through various 3D sensing equipment such as RGB-D Camera, ToF Camera, LiDAR, and Laser Profiler, and developing technology to accurately recognize objects and features in the manufacturing environment.',
          },{
            image:require("@/assets/image/tech/tech28.jpg"),
            title:'2D 비전 기술',
            description: '2D 카메라를 통해 취득한 단일 2D 이미지 또는 다양한 각도의 여러개의 2D 이미지를 스티칭하여 물체를 분류하고 인식하는 기술을 연구하고 사용하고 있습니다.',
            title_en:'2D vision technology',
            description_en:'We are researching and using technology to classify and recognize objects by stitching a single 2D image acquired through a 2D camera or multiple 2D images from various angles.',
          }]
        },
        {
          title: "DIGITAL TWIN",
          subtitle: "technology description",
          image: require("@/assets/image/background/bg38.jpg"),
          hashTags: ["디지털트윈"],
          items:[{
            image:require("@/assets/image/tech/tech22.gif"),
            title:'로봇 관제 시스템',
            description: '엣지 로봇을 디지털 트윈 플랫폼과 연결하여 실시간으로 디지털 트윈되어 모니터링합니다. 이를 통해 현재 로봇의 상태를 계속 추적하고 예지 보전하여 통합 관리를 지원합니다. 또한 시뮬레이션에서 검증된 프로세스를 연결된 엣지 로봇에 Simulation To Real 합니다.',
            tags:['VRIDGE Twin'],
            title_en:'Robot Management System',
            description_en:'By connecting the edge robot to the digital twin platform, it is digitally twinned and monitored in real time. This continuously tracks the current status of the robot and supports integrated management through predictive maintenance. In addition, the process verified in simulation is Simulated To Real on the connected edge robot.',
          },{
            image:require("@/assets/image/main/robot.gif"),
            title:'로봇 시뮬레이션',
            description: '실제 물리적 시스템과 프로세스를 디지털 데이터로 모델링하여 미래의 상태를 예측하거나, 시나리오를 테스트하여 효율적인 로봇 프로세스 환경을 구축합니다. 이를 위해 자체 IK Solver 알고리즘과 경로 생성 알고리즘을 개발하여 기구학적 해석과 최적의 로봇 동작을 검증하고 있습니다.',
            tags:['VRIDGE Twin'],
            title_en:'Robot Simulation',
            description_en:'Model actual physical systems and processes with digital data to predict future states or test scenarios to build an efficient robotic process environment. To this end, we are developing our own IK solver algorithm and path generation algorithm to verify kinematic analysis and optimal robot motion.',
          }]
        },
        {
          title: "CLOUD",
          subtitle: "technology description",
          image: require("@/assets/image/background/bg36.jpg"),
          hashTags: ["클라우드"],
          items:[{
            image:require("@/assets/image/tech/tech18.gif"),
            title:'데이터 수집',
            description: '로봇과 엣지 디바이스에서 수집되는 각종 센서 퓨전 데이터를 실시간 고속으로 데이터 웨어하우스에 저장합니다. 아날로그 데이터를 초당 수십만 건 샘플링하여 디지털 데이터로 변환합니다.',
            tags:['VRIDGE DAS'],
            title_en:'Data Acquisition',
            description_en:'Various sensor fusion data collected from robots and edge devices are stored in the data warehouse at high speed in real time. Analog data is sampled hundreds of thousands of times per second and converted to digital data.',
          },{
            image:require("@/assets/image/tech/tech17.jpg"),
            title:'데이터 백업 및 복구',
            description: '온프레미스 스토리지 서버에 데이터 레이크(Data Lake)를 구축하여 데이터를 통합 저장 관리합니다. 또한 외부 클라우드 서비스와 연결하여 실시간 동기화 및 백업 복원을 통해 데이터의 안전성을 확보합니다. AI 플랫폼과의 연동으로 인공지능 데이터로 활용할 수 있습니다.',
            tags:['VRIDGE Keeper'],
            title_en:'Data backup and recovery',
            description_en:'Build a data lake on an on-premise storage server to store and manage data in an integrated manner. Additionally, it connects to external cloud services to ensure data safety through real-time synchronization and backup restoration. It can be used as artificial intelligence data by linking with the AI ​​platform.',
          },{
            image:require("@/assets/image/tech/tech16.jpg"),
            title:'AI / ML / LLMOps',
            description: '모든 엣지 로봇 AI를 플랫폼에 연결하여 통합 인공지능 학습 및 배포 파이프라인을 제공합니다. 지속적으로 데이터를 가공 및 통합하고 연결된 엣지 AI에 배포합니다.',
            tags:['VRIDGE AI', 'VRIDGE LLMOps'],
            title_en:'AI / ML / LLMOps',
            description_en:'Connect all edge robot AI to the platform, providing a unified artificial intelligence learning and deployment pipeline. Continuously process, integrate, and distribute data to connected edge AI.',
          }]
        },
      ],
      contentIndex: 0,
      itemIndex:0,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollEvent)
  },
  unmounted() {
    window.removeEventListener('scroll', this.scrollEvent);
  },
  methods: {
    scrollEvent(e) {
      let scrollPosY = e.srcElement.scrollingElement.scrollTop
      let contents = document.getElementsByClassName('tech-contents')

      let elems = []

      for(let i = 0; i < contents.length; i++) {
        elems.push({
          index: i,
          offsetTop: contents[i].offsetTop
        })
      }

      elems = elems.sort((x,y)=>{return y.offsetTop - x.offsetTop})

      for(let i = 0 ; i < elems.length; i++) {
        if(elems[i].offsetTop < scrollPosY +  (window.innerHeight / 2)) {
          this.contentIndex = elems[i].index

          let itemContents = document.getElementsByClassName('content-' + this.contentIndex)

          let itemElems = []
          for(let j = 0; j < itemContents.length; j++) {
            itemElems.push({
              index: j,
              offsetTop: itemContents[j].offsetTop
            })
          }

          itemElems = itemElems.sort((x,y)=>{return y.offsetTop - x.offsetTop})

          for(let j = 0; j < itemElems.length; j++) {
            if(itemElems[j].offsetTop < scrollPosY + (window.innerHeight - 300)) {
              this.itemIndex = itemElems[j].index
              break
            }
          }

          break
        }
      }

    },

    scrollToContent(index) {
      this.$nextTick(() => {
        if(index < 0) {
          window.scrollTo({
              top: 0,
              behavior: 'smooth' 
          });
          return
        } else if(index >= this.technologyContents.length){
          window.scrollTo({
              top: document.body.scrollHeight,
              behavior: 'smooth' 
          });
          return
        }
        const elem = document.getElementById('contents-' + index)
        if(elem) {
          window.scrollTo({
              top: elem.offsetTop + 100,
              behavior: 'auto' 
          });

        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.tech-container{
  min-height: 100vh;
  position: relative;
  color: $base-foreground-color;
  width:100%;
  margin:0;
  padding:0;
  max-width: 100%;
  @media screen and (max-width: #{$breakpoint-desktop}) {    
    overflow: hidden;
  }

  .tech-title{
    height:calc(100vh - 70px);
    font-size:1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    *{
      font-family: 'Pretendard-SemiBold' !important;
    }

    h1{
      font-size: 5rem;
      margin:0;
      animation-duration: 500ms;
      animation-delay: 200ms;
      animation-name: fade_up;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }

    p{
      font-size: 2rem;
      max-width: 1000px;
      animation-duration: 500ms;
      animation-delay: 500ms;
      animation-name: fade_up;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      text-align: start;
      opacity: 0;
      margin:12px;
    }

    @media screen and (max-width: #{$breakpoint-tablet}) {  
      h1{
        font-size:2.3rem;;
      }
      p{
        font-size: 1.2rem;
      }

    }
  }

  .tech-background{
    position: fixed;
    width:100vw;
    min-width:320px;

    height:100%;
    left:0;
    top:-60px;
    padding-bottom: 70px;
    z-index: -1;
    background-attachment: fixed;
    filter: brightness(30%) ;
    -webkit-filter: brightness(30%);
    background-repeat: no-repeat;
    background-size: cover;

    &::after{
      position: fixed;
      left:0;
      top:0;
      content:'';
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0, 0.1);
      backdrop-filter: blur(4px);
      z-index: 1;
    }
  }

  .tech-menus{
    position: -webkit-sticky;
    position: sticky;
    top:10vh;
    text-align: left;
    min-width:100px;
    max-width: 160px;
    border-right:1px solid $base-border-color;
    font-size: 1.02rem;
    font-weight: bold;
    z-index: 10;
    display: flex;
    flex-direction: column;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    padding:4px 0;

    @media screen and (max-width: #{$breakpoint-desktop}) {    
      min-width:60px;
      margin-right: 12px;
      padding:0 12px;
      display: none;
    }
    
    .menu {
      opacity: 0.3;
      display: inline-block;
      color: $base-foreground-dark-color;
      cursor: pointer;
      padding: 4px 0;
      font-size: 1.16rem;
      
   
      &:hover{
        opacity: 1;
      }

      &.active{
        opacity: 1;
      }
    }

  }

  .tech-visual{
    position: -webkit-sticky;
    position: sticky;
    top:calc(50% - 20vh);
    height: 40vh;
    border-radius: 1rem;
    width:calc(100% - 24px);
    margin:12px;
  
    img{
      width:calc(50%  - 24px);
      border-radius: 1rem;
      height: 100%;
      max-height: 100%;
      margin:12px;
      object-fit: cover;
      object-position: center;
      position: absolute;
      border-radius: 1rem;
      overflow: hidden;
    }
  

    @media screen and (max-width: #{$breakpoint-desktop}) {    
      transition: none !important;
      animation: none !important;
      position: relative;
      img{
        position: static;
        width:calc(100% - 24px);
        height: auto;
        object-fit: contain;
      }
    }
  }

  .tech-description{
    width:calc(50% - 160px);
    margin-left: 160px;
    text-align: start;


    h1{
      font-size: 2.3rem;
    }
    p{
      font-size: 1.2rem;
      opacity:0.8;
    }
    span{
      font-size:1.2rem;
      opacity: 1;
      font-family: 'Pretendard-Medium';
    }

    @media screen and (max-width: #{$breakpoint-desktop}) {    
      width:calc(100% - 24px);
      margin: 12px;

      h1{
        font-size:1.6rem;
      }
      p{
        font-size: 1.1rem;
      }
    }
  }

  .tech-contents{
    
    width:100%;
    min-height:100vh;
    color: $base-foreground-dark-color;

    .content-title{
      font-size: 4rem;
      padding: 100px 0;
      font-family: 'Pretendard-Bold' !important;
    }

    .tech-content{
      height:100vh;
    }

    @media screen and (max-width: #{$breakpoint-desktop}) {    
      min-height: auto;
      .tech-content{
        height: auto;
        overflow-x: hidden;
      }
      .content-title{
        font-size:2.4rem;
      }
    }
  }
}
</style>
