<template>
  <div>
    <main-layout />
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout.vue";

export default {
  name: "MainPage",
  components: { MainLayout },
  data() {
    return {};
  },
};
</script>

<style lang="scss"></style>
