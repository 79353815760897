<template>
  <div class="preparation-page">
    <div class="image mt-2">
      <img src="@/assets/image/prepare.png" height="300" />
    </div>
    <div class="message">
      <div class="vhp-text xlarge">
        <i class="fa-solid fa-circle-exclamation mr-1"></i>
        현재 페이지는 준비중입니다.
      </div>
      <div class="vhp-text gray light mt-4">
        더 나은 서비스 제공을 위하여 페이지 준비 중에 있습니다.
        <br />
        빠른 시일 내에 준비하여 찾아뵙겠습니다.
      </div>
    </div>
    <div class="action">
      <div class="error-home vhp-button" @click="$router.push('/')">
        <div class="vhp-text">홈 화면</div>
      </div>
      <div class="error-home vhp-button" @click="$router.go(-1)">
        <div class="vhp-text pink">이전화면</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorIndex",
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
.preparation-page {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    margin-bottom: 30px;
  }

  .action {
    display: flex;

    .vhp-button {
      padding: 10px 18px;
      margin: 4px;

      .vhp-text {
        font-family: "Pretendard-Medium";
      }
    }
  }
}
</style>
