<template>
  <!-- 서비스 페이지 -->
  <div v-if="!$route.path.includes('/admin')" class="vhp-container" :class="$utils.isMobile() ? 'mobile-app' : ''">
    <vhp-admin-header v-if="$store.state.isAdmin" />

    <header class="vhp-header" :class="{ [headerMode]: headerMode }">
      <vhpHeader
        v-if="$route.path === '/' || $route.path === '/technology'"
        :menus="menus.filter((e) => !e.hide)"
        :headerMode="headerMode"
      />
      <vhpHeader v-else :menus="menus.filter((e) => !e.hide)" />
    </header>

    <main class="vhp-main">
      <router-view
        class="vhp-main-content container-width"
        :class="{ 'pb-0': $route.path === '/' }"
      />
    </main>

    <footer class="vhp-footer" v-if="$route.path !== '/error'">
      <vhpFooter :menus="menus.filter((e) => !e.hide)" />
    </footer>


    <div
      class="vhp-floating-button"
      data-aos="fade-down"
      v-if="scrollUpBtn"
      @click="onScrollUp"
    >
      <img src="@/assets/image/arrow-up.svg"/>
    </div>

    
    <div
      class="vhp-service-button"
      :class="{active: serviceShow}"
    >
      <div class="content"  
        @click.stop="onClickService"
        
      >
        <img v-if="!serviceShow" src="@/assets/image/vridge-logo.png"/>
        <h3 v-if="!serviceShow">{{$t('바로가기')}} </h3>


        <i v-if="serviceShow" class="fas fa-close" style="font-size:1.3rem;"/>
      </div>

      <div  v-for="(item, idx) in services" :key="item.title" >
        <div class="service" 
          :class="[serviceShow ? 'show' : '', 's' + idx]"
          
          @click.stop="onClickServiceItem(item)"
        >
          <img :src="item.image"/>
          <p>
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>

    <ChannelTalk v-if="!$store.state.isAdmin" />
  </div>

  <!-- 관리자 페이지 -->
  <div v-else class="vhp-container">
    <header>
      <vhp-admin-header v-if="$store.state.isAdmin" />
    </header>

    <main class="vhp-main no-header">
      <router-view class="vhp-main-content container-width" />
    </main>
  </div>
</template>

<script>
import vhpAdminHeader from "@/components/vhpAdminHeader.vue";
import vhpHeader from "@/components/vhpHeader.vue";
import vhpFooter from "@/components/vhpFooter.vue";
import ChannelTalk from "@/components/channeltalk/ChannelTalk.vue";

export default {
  name: "App",
  components: {
    vhpAdminHeader,
    vhpHeader,
    vhpFooter,
    ChannelTalk,
  },
  data() {
    return {
      serviceShow:false,
      menus: [
        {
          text: "바질컴퍼니",
          key: "info",
          path: "/info",
          childrens: [
            {
              text: "회사소개",
              key: "vazil",
              path: "/info/vazil",
            },
            {
              text: "수상/인증",
              key: "awards",
              path: "/info/awards",
            },
            {
              text: "주요특허",
              key: "patent",
              path: "/info/patent",
            },
          ],
        },
        {
          text: "기술과 서비스",
          key: "service",
          path: "/service",
          childrens: [
            {
              text: "용접로봇",
              key: "welding",
              path: "/content?id=@content-1iepgkj24u2bb&list=/service",
            },
            {
              text: "산업용 로봇 자동화",
              key: "industry",
              path: "/content?id=@content-qy4y6lfq53ch&list=/service",
            },
            {
              text: "산업용 로봇 비전",
              key: "vision",
              path: "/content?id=@content-1hrggz2d46a7s&list=/service",
            },
            {
              text: "브릿지 AI",
              key: "vridge",
              path: "/content?id=63b50e864a12ff6f878ae04b&list=/service",
            },
            {
              text: "공정 모니터링 솔루션",
              key: "monitoring",
              path: "/content?id=@content-ts2ggs9s8j79&list=/service",
            },
          ],
          hide: true,
        },
        {
          text: "제품",
          key: "products",
          path: "/products",
          childrens: [{
            text: "로봇 솔루션",
            key: "robot",
            path: "/products?index=0",
          },{
            text: "S/W 솔루션",
            key: "sw",
            path: "/products?index=1",
          },{
            text: "통합 솔루션",
            key: "integration",
            path: "/products?index=2",
          }],
        },

        {
          text: "기술",
          key: "technology",
          path: "/technology",
          childrens: [],
        },
        {
          text: "로봇",
          key: "robot",
          path: "/robot/product",
          childrens: [],
          hide: true,
        },
        {
          text: "소식",
          key: "news",
          path: "/news",
          childrens: [
            {
              text: "스토리",
              key: "news",
              path: "/news",
            },
            {
              text: "공지사항",
              key: "notice",
              path: "/news/notice",
            },
            {
              text: "자료실",
              key: "resources",
              path: "/news/resources",
            },
            {
              text: "채용",
              key: "career",
              path: "/news/career",
              childrens: [],
            },
          ],
        },
        {
          text: "문의하기",
          key: "contact",
          path: "/contact",
          childrens: [],
        },
      ],

      pageTitle: "",
      pageTitleElements: [],
      scrollDown: false,
      scrollUpBtn: false,
      scrollPosY: 0,
      headerTop: 300,
      headerMode: "transparent",

      headerMaintains: ["/technology"],

      services:[{
        title:'AI',
        url:'https://demo.vridgeai.com',
        image:require('@/assets/image/vridge-logo.png')
      },{
        title:'Twin',
        url:'https://rms.vazilcompany.com/s/66b2cfab76a73f247375c312',
        image:require('@/assets/image/vridge-logo.png')
      },{
        title:'Keeper',
        path:'/products/details?id=@product-1722425305631',
        image:require('@/assets/image/vridge-logo.png')
      },{
        title:'DAS',
        path:'/products/details?id=@product-1722425339069',
        image:require('@/assets/image/vridge-logo.png')
      }]
    };
  },

  mounted() {
    window.addEventListener("scroll", this.scrollEvent);

    this.findPageTitle();
    this.setHeaderScroll();
    this.setLanguage();
    // this.drawBackground()
    this.sendVisit();
    this.checkIsAdmin();
    this.onScrollUp();
    this.initProductMenu()
  },

  unmounted() {
    window.removeEventListener("scroll", this.scrollEvent);
  },

  watch: {
    $route() {
      this.findPageTitle();
      this.setHeaderTop();
      this.setHeaderScroll();
      this.setLanguage();
    },
  },
  methods: {
    setLanguage() {
      if (this.$route.query.lang) {
        this.$utils.setCookie("vhp.locale", this.$route.query.lang, 7);
      }
      this.$i18n.locale = this.$utils.getCookie("vhp.locale") || "kr";
    },

    async initProductMenu() {
      for(let menu of this.menus) {
        if(menu.key === 'products') {
          
          const response = await this.$axios.get('/category').catch(err=>{console.log(err)})

          menu.childrens = []

          for(let item of response.data) {
            menu.childrens.push({
              text:item.name,
              key: item.id,
              path:'/products?index=' + item.order
            })
          }

          // childrens: [{
          //   text: "로봇 솔루션",
          //   key: "robot",
          //   path: "/products?index=0",
          // },{
          //   text: "S/W 솔루션",
          //   key: "sw",
          //   path: "/products?index=1",
          // },{
          //   text: "통합 솔루션",
          //   key: "integration",
          //   path: "/products?index=2",
          // }],
          break
        }
      }
    },

    drawBackground() {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = window.innerWidth;
      canvas.height = document.body.offsetHeight;

      ctx.font =
        "32px bold Apple SD Gothic Neo, Pretendard-Regular, NotoSansKR, sans-serif ";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = "#C4182805";
      ctx.beginPath();
      ctx.moveTo(canvas.width * 0.6, 0);
      ctx.lineTo(canvas.width, 0);
      ctx.lineTo(canvas.width, canvas.height * 0.8);
      ctx.fill();

      ctx.fillStyle = "#C4182803";
      ctx.beginPath();
      ctx.moveTo(canvas.width * 0.6, canvas.height);
      ctx.lineTo(canvas.width, canvas.height);
      ctx.lineTo(canvas.width, canvas.height * 0.5);
      ctx.fill();
      let src = canvas.toDataURL("image/png", 1.0);

      document.body.style.backgroundImage = "url(" + src + ")";
    },
    
    onClickService(){
      this.serviceShow = !this.serviceShow 
    },

    onClickServiceItem(item) {
      if(item.path){
        this.$router.push(item.path)
        
      } else if(item.url) {
        this.$utils.openLink(item.url)
      }
      this.serviceShow = false
    },

    scrollEvent() {
      this.setHeaderScroll();
    },

    findPageTitle() {
      setTimeout(() => {
        this.pageTitleElements = [];
        for (let elem of document.getElementsByClassName("page-title")) {
          let title = {
            html: elem.innerHTML,
            position: Math.ceil(elem.offsetTop),
          };
          this.pageTitleElements.push(title);
        }
      }, 500);
    },

    setPageTitle(scrollPosY) {
      if (!scrollPosY) {
        this.pageTitle = "";
        return;
      }

      for (let i = 0; i < this.pageTitleElements.length; i++) {
        if (scrollPosY >= this.pageTitleElements[i].position) {
          this.pageTitle =
            this.pageTitleElements[i].html || this.$t("바질컴퍼니");
          return;
        }
      }
      this.pageTitle = this.$t("바질컴퍼니");
    },

    setHeaderTop() {
      setTimeout(() => {
        const ht = document.getElementById("header-top");
        if (ht) {
          this.headerTop = ht.getBoundingClientRect().top || 300;
        } else {
          this.headerTop = 300;
        }
      }, 500);
    },

    setHeaderScroll() {
      this.headerMode = "transparent";
      let scrollPosY = window.pageYOffset || document.documentElement.scrollTop;
      let viewportHeight = window.innerHeight;
      if (scrollPosY > viewportHeight / 4) {
        if (!this.headerMaintains.includes(this.$route.path)) {
          this.headerMode = "white";
        }
        this.scrollUpBtn = true;
      } else {
        if (!this.headerMaintains.includes(this.$route.path)) {
          this.headerMode = "transparent";
        }
        this.scrollUpBtn = false;
      }

      if (this.headerTop > scrollPosY) {
        this.scrollDown = false;
        this.setPageTitle();
        return;
      }

      if (this.scrollPosY < scrollPosY) {
        this.scrollDown = true;
        this.setPageTitle(scrollPosY);
      } else {
        this.scrollDown = false;
        this.setPageTitle();
      }

      this.scrollPosY = scrollPosY;
    },

    onScrollUp() {
      window.scroll({
        behavior: "smooth",
        left: 0,
        top: 0,
      });

      setTimeout(() => {
        this.scrollDown = false;
      }, 100);
    },

    sendVisit() {
      if (!this.$utils.getCookie("vhp.visit")) {
        const dateKey = new Date()
          .toLocaleDateString("ko-KR")
          .replaceAll(".", "")
          .replaceAll(" ", "");
        this.$axios
          .post("/visit", {
            date: dateKey,
          })
          .then(() => {
            this.$utils.setCookie("vhp.visit", true, 1);
          });
      }
    },

    checkIsAdmin() {
      // frontToken 없을 경우 관리자 페이지 접근 불가
      if (!this.$store.state.frontToken) {
        this.$store.commit("setIsAdmin", false);
        return;
      }

      // frontToken 검증 통과 시 isAdmin 할당, axios headers에 apiToken 할당
      const decryptedText = this.$CryptoJS.AES.decrypt(
        this.$store.state.frontToken,
        process.env.VUE_APP_SECRET_KEY
      ).toString(this.$CryptoJS.enc.Utf8);
      if (decryptedText === process.env.VUE_APP_VALIDATION_TEXT) {
        this.$store.commit("setIsAdmin", true);
        this.$axios.defaults.headers.common["Authorization"] =
          this.$store.state.apiToken;
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  text-align: center;
  color: $base-foreground-color;
  min-width: $min-width !important;
}

.vhp-container {
  width: 100% !important;
  .vhp-header {
    width: 100%;
    min-width: $min-width;
    height: $header-height;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: none;
    transition: top 0.3s ease-in-out;

    &.white {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }

    &.hide {
      top: -$header-height;
      @media screen and (max-width: #{$breakpoint-md}) {
        top: -$header-mobile-height;
      }
    }

    &.down {
      top: -$header-height;
      position: fixed;

      @media screen and (max-width: #{$breakpoint-md}) {
        height: $header-mobile-height;
        border-bottom: 1px solid $base-border-color;
      }
    }

    &.sub {
      z-index: 1999;
      top: -$header-height;
      &.down {
        top: 0;
      }
    }
  }

  .vhp-main {
    width: 100%;
    max-width:100%;
    min-width: $min-width;
    margin-top: $header-height;

    &.no-header {
      margin-top: 0;
      .vhp-main-content {
        padding: 0;
      }
    }
    @media screen and (max-width: #{$breakpoint-md}) {
      margin-top: $header-mobile-height;
    }
    .vhp-main-content {
      margin: 0 auto;
      max-width: $full-width;
      min-height: 75vh;
      
      &.pb-0 {
        padding-bottom: 0;
      }

      @media screen and (max-width: #{$breakpoint-md}) {
        width: 100%;
        padding: 0 0px 40px 0px;
      }

      .vhp-main-content-title {
        padding: 20px 0 20px 0;

        @media screen and (max-width: #{$breakpoint-desktop}) {
          padding-top: 24px;
          padding-bottom: 24px;
        }

        @media screen and (max-width: #{$breakpoint-md}) {
          padding: 4px 0;
        }

        .page-title {
          font-family: "Pretendard-Bold" !important;
          letter-spacing: -2px;
          font-size: 42px;
          margin: 0;
          padding: 10px 10px 0 10px;
          animation-duration: 500ms;
          animation-name: fade_up;
          animation-timing-function: ease-in-out;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          @media screen and (max-width: #{$breakpoint-desktop}) {
            font-size: 36px;
          }

          @media screen and (max-width: #{$breakpoint-md}) {
            font-size: 28px;
          }
        }

        .page-subtitle {
          color: $base-foreground-subtitle-color;
          font-size: 20px;
          margin: 0;
          padding: 0 6px 6px 6px;
          animation-duration: 800ms;
          animation-name: fade_up;
          animation-timing-function: ease-in-out;
          letter-spacing: -0.5px;

          @media screen and (max-width: #{$breakpoint-desktop}) {
            font-size: 18px;
          }

          @media screen and (max-width: #{$breakpoint-md}) {
            font-size: 16px;
          }
        }
      }

      .page-submenus {
        padding: 32px;
        .page-submenus-item {
          font-size: 1.5rem;
          font-family: "Pretendard-Bold";
          opacity: 0.8;
          color: $base-foreground-sub-color;
          &:hover {
            opacity: 1;
            color: $base-foreground-color;
          }

          &.active {
            opacity: 1;
            color: $base-foreground-color;
          }

          @media screen and (max-width: #{$breakpoint-md}) {
            font-size: 16px;
          }
        }

        .page-submenus-divider {
          font-size: 18px;
          margin: 0 16px;
          opacity: 0.8;
          color: $base-foreground-sub-color;
          @media screen and (max-width: #{$breakpoint-md}) {
            font-size: 16px;
            margin: 0 10px;
          }
        }
      }
    }
    .page-background {
      width: 100vw;

      max-height: 340px;
      height: 340px;
      margin: 0 auto 60px auto;
      left: calc(50% - 50vw);
      z-index: -1;

      // border-radius: 24px;
      overflow: hidden;
      position: relative;
      background-attachment: fixed;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      animation-timing-function: ease-in-out;

      &.flex {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        object-fit: cover;
        border-radius: inherit;
        width: 100%;
        height: 100%;

        background-attachment: fixed;
        animation-name: zoom_in;
        animation-duration: 1500ms;
        animation-timing-function: ease-in-out;
      }

      @media screen and (max-width: #{$breakpoint-desktop}) {
        max-height: 260px;
      }

      @media screen and (max-width: #{$breakpoint-tablet}) {
        width: 100%;
        min-width: 300px;
        margin: 0;
        margin-bottom: 24px;
        max-height: 180px;
        height: 180px;
      }
    }
  }

  .vhp-footer {
    background-color: $base-background-color;
    border-top: 1px solid $base-border-color;
    width: 100%;
    bottom: 0;
  }
  

  .vhp-floating-button{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    right: 24px;
    bottom: 90px;
    font-size: 1.5rem;
    text-align: center;
    width: 56px;
    height: 56px;
    background-color: #fefefe;
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 8px;
    cursor: pointer;

    > img{
      object-fit: contain;
      max-width: 24px;
      max-height:  24px;
    }
  }

  .vhp-service-button{
    position: fixed;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 10;
    width: 110px;
    height:110px;
    background-color: #fefefe;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 8px;
    border-radius: 100%;
    transition: all .2s ease-in-out;
    left:50%;
    transform: translateX(-50%);
    bottom:-50px;
    cursor:pointer;

    &::before{
      content:'';
      width:100px;
      height:100px;
      z-index: 10;
      left:1px;
      top:1px;
      border-radius: 100%;
      border: 4px solid #03a9f450;
      position: fixed;
      transition: all .2s ease-in-out;
      opacity: 0.5;
    }

    &::after{
      content:'';
      width:110px;
      height:110px;
      z-index: 10;
      left:-6px;
      top:-6px;
      border-radius: 100%;
      border: 6px solid #03a9f420;
      position: fixed;
      transition: all .2s ease-in-out;
      opacity: 0.5;
      box-shadow: 0 0 4px #03a9f420;
    }

    &:hover{
      
      &::after{
        opacity: 1;
        box-shadow: 0 0 8px #03a9f460,  0 0 24px #03a9f420;
      }
    }

    &.active{
      &::after{
        opacity: 0.5;
        box-shadow: 0 0 4px #03a9f420;
      }
    }


    .content{
      transition: all .15s ease-in-out;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      z-index: 11;
      width:100%;
      height:40px;
      padding-top:14px;
      cursor: pointer;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      pointer-events: all;

      &:not(:first-child) {
        border-top: 1px solid $base-border-color;
      }
      
      img{
        width: 24px;
        height:  24px;
        margin:0;
        padding:0;
        object-fit: contain;
      }
      h3{
        margin:0;
        padding:0;
        font-size:0.8rem;
      }
    }

    .service{
      position: absolute;
      width:36px;
      height:36px;
      padding:12px;
      border-radius: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start; 
      background-color: #fff;
      border:1px solid $base-border-color;
      transition: .15s ease-in-out;
      opacity: 0;
      box-shadow: 0 0 12px #03a9f420;

      > img{
        width:26px;
        height:26px;
        object-fit: contain;
      }

      p {
        position: absolute;
        bottom: -4px;
        font-weight: bold;
        font-size:0.7rem;
      }
      &.s0{
        left: 24px;
        top: 24px;
      }
      &.s1{
        left: 24px;
        top: 24px;
      }
      &.s2{
        right: 24px;
        top: 24px;
      }
      &.s3{
        right: 24px;
        top: 24px;
      }

      &:hover{
  
        transform: scale(1.1);
      }


      &.show{
        opacity: 1;
        &.s0{
          left: -65px;
          top: -20px;
          bottom:unset;
          right:unset;
        }
        &.s1{
          left: -15px;
          top: -70px;
          bottom:unset;
          right:unset;
        }
        &.s2{
          right: -15px;
          top: -70px;
          bottom:unset;
          left:unset;
        }
        &.s3{
          right: -65px;
          top: -20px;
          bottom:unset;
          left:unset;
        }
      }


    }
  }

  &.mobile-app {
    .vhp-floating-button{
      width:44px;
      height:44px;
      right:14px;
      bottom:70px;
      border-radius: 18px;

    }
    // .vhp-service-button{
    //   display: none;
    //   bottom: 14px;
    //   left:14px;
    // }
  }
}
</style>
