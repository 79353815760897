<template>
  <div>
    <div class="vhp-main-content-title">
      <h1 class="page-title"><span class="primary--text">{{`'` + keyword +`' `}}</span>{{$t('검색결과')}}</h1>
    </div>

    <layout :contents="contents"  base-url="/news/details" list-url="/news"/>
  </div>
</template>

<script>
import layout from '@/components/layouts/CardLayout.vue'

export default {
  name: 'SearchIndex',
  components:{
    layout
  },
  data() {
    return {
      contents:[],
      keyword:'',
    }
  },
  mounted() {
    this.getContents()
  },
  methods:{
    getContents() {
      this.keyword = this.$route.query.keyword || ''

      if(!this.keyword) return
      
      this.$axios.get('/content/search',{
        params:{
          keyword:this.keyword,
          locale:this.$i18n.locale.toUpperCase()
        }
      })
      .then(res=>{
        this.contents = res.data
      })
      .catch(err=>{
        console.log(err)
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
