<template>
  <div class="admin-page admin-product-details">
    <div class="header">
      <button class="item" @click="clickPrevBtn"  style="float: left">
        뒤로
      </button>

      <div>
        <label>
          <input
            type="radio"
            v-model="product.hidden"
            name="isHidden"
            :value="false"
            :checked="!product.hidden"
            class="item"
          />공개
        </label>
        <label>
          <input
            class="item"
            type="radio"
            v-model="product.hidden"
            name="isHidden"
            :value="true"
          />비공개
        </label>
      </div>

      <div>
       
       
        <button
          @click="isPreview = !isPreview"
          class="item"
        >
          {{ isPreview ? "편집" : "미리보기" }}
        </button>

        <button

          v-if="pageType === 'edit'"
          @click="clickDelBtn"
          class="item"
          >
          삭제
          </button>

        <button @click="clickSaveBtn" class="vhp-button primary" >
          저장
        </button>
      </div>
    </div>

    <!-- 편집 모드 -->
    <div v-if="!isPreview" class="edit-container">
      <div class="row mb-16">
        <div class="col-30">
          <div class="image-container">
            <div class="image-preview">
              <div
                v-for="(image, index) in images"
                :key="index"
                class="image-preview-wrap"
                :class="{ active: index === activeImageIndex }"
                @click="activeImageIndex = index"
              >
                <img :src="image || ''" class="image-preview-card" />
                <button class="vhp-button round" @click="removeImage(index)">
                  <i class="fa-solid fa-times"></i>
                </button>
              </div>

              <div
                class="image-preview-wrap default"
                @click="$refs.imageInput.click()"
              >
                <i class="fa-solid fa-plus"></i>
              </div>
            </div>

            <div class="image-wrap">
              <img
                v-if="images.length > 0"
                class="image-card"
                :src="images[activeImageIndex]"
                :key="activeImageIndex || images.length"
              />
              <vhp-empty
                v-else
                :text="'이미지가 없습니다.'"
                :fullScreen="true"
                :showImage="true"
                class="image-card"
                @click="$refs.imageInput.click()"
              />

              <button
                class="vhp-button image-add-button"
                @click="$refs.imageInput.click()"
              >
                <div class="vhp-text small">이미지 추가</div>
              </button>
              <div v-show="images.length > 1" class="slide-button-wrap left">
                <div
                  class="slide-button prev"
                  @click="
                    activeImageIndex > 0
                      ? activeImageIndex--
                      : (activeImageIndex = images.length - 1)
                  "
                >
                  <span />
                  <span />
                </div>
              </div>
              <div v-show="images.length > 1" class="slide-button-wrap right">
                <div
                  class="slide-button next"
                  @click="
                    activeImageIndex < images.length - 1
                      ? activeImageIndex++
                      : (activeImageIndex = 0)
                  "
                >
                  <span />
                  <span />
                </div>
              </div>
            </div>
            <input
              @input="changed = true"
              ref="imageInput"
              type="file"
              accept="image/*"
              multiple
              @change="addImage"
              hidden
            />
          </div>
        </div>

        <div class="col-70">
          <div class="description-container">
            <div class="info-container mb-1">
              <div class="vhp-input dense mr-1">
                <div class="vhp-text small bold ma-1">아이디</div>
                <input
                  class="transparent outline"
                  type="text"
                  v-model="product.id"
                  readonly
                />
              </div>

              <div class="vhp-input dense">
                <div class="vhp-text small bold ma-1">브랜드</div>
                <input
                  @input="changed = true"
                  class="transparent outline"
                  type="text"
                  v-model="product.brand"
                  required
                />
              </div>
            </div>

            <div class="info-container mb-1">
              <div class="vhp-input dense">
                <div class="vhp-text small bold ma-1">카테고리</div>
                <vhp-select
                  v-model="product.category"
                  :items="category"
                  item-key="name"
                  item-value="id"
                  outline
                  dense
                />
              </div>
            </div>

            <div class="info-container mb-1">
              <div class="vhp-input dense">
                <div class="vhp-text small bold ma-1">상품이름</div>
                <input
                  @input="changed = true"
                  class="transparent outline"
                  type="text"
                  v-model="product.title"
                  required
                />
              </div>
            </div>

            <div class="info-container mb-1">
              <div class="vhp-input dense">
                <div class="vhp-text small bold ma-1">상품요약</div>
                <input
                  @input="changed = true"
                  class="transparent outline"
                  type="text"
                  v-model="product.subtitle"
                  required
                />
              </div>
            </div>

            <div class="info-container mb-1">
              <div class="vhp-input dense mr-1">
                <div class="vhp-text small bold ma-1">상품가격</div>
                <input
                  @input="changed = true"
                  class="transparent outline"
                  type="text"
                  v-model="product.price"
                  required
                />
              </div>

              <div class="vhp-input dense">
                <div class="vhp-text small bold ma-1">재고수량</div>
                <input
                  @input="changed = true"
                  class="transparent outline"
                  type="text"
                  v-model="product.quantity"
                  required
                />
              </div>
            </div>

            <div
              v-if="!product.options || product.options.length === 0"
              class="info-container column"
            >
              <div class="vhp-text small bold ma-1">상품옵션</div>
            </div>

            <template
              v-for="(option, optionIdx) in product.options"
              :key="optionIdx"
            >
              <div class="info-container column">
                <div class="vhp-text small bold mt-1 mx-1">
                  {{ "상품옵션-" + (optionIdx + 1) }}
                </div>
              </div>
              <div class="option-container my-1">
                <div class="option-container-wrap">
                  <button
                    class="close-button"
                    @click="deleteOptionItem(option)"
                  >
                    <div class="vhp-text large light">×</div>
                  </button>
                  <div class="info-container column">
                    <div class="vhp-input dense">
                      <div class="vhp-text small light mx-1">옵션명</div>
                      <input
                        @input="changed = true"
                        v-model="option.name"
                        class="transparent outline"
                        type="text"
                        required
                      />
                    </div>
                  </div>
                  <div
                    class="info-container"
                    v-for="(select, selectIdx) in option.value"
                    :key="selectIdx"
                  >
                    <div class="vhp-input dense mr-1">
                      <div class="vhp-text small light mt-1 mx-1">옵션값</div>
                      <input
                        @input="changed = true"
                        class="transparent outline"
                        type="text"
                        v-model="select.name"
                        required
                      />
                    </div>

                    <div class="vhp-input dense mr-1">
                      <div class="vhp-text small light mt-1 mx-1">옵션가</div>
                      <input
                        @input="changed = true"
                        class="transparent outline"
                        type="text"
                        v-model="select.value"
                        required
                      />
                    </div>

                    <div class="vhp-input dense">
                      <div class="vhp-text small light mt-1 mx-1">재고수량</div>
                      <input
                        @input="changed = true"
                        class="transparent outline"
                        type="text"
                        v-model="select.quantity"
                        required
                      />
                    </div>

                    <button
                      v-if="selectIdx === option.value.length - 1"
                      class="vhp-button add-button"
                      @click="option.value.push({ ...newSelect })"
                    >
                      <div class="vhp-text small light">추가</div>
                    </button>
                    <button
                      v-else
                      class="vhp-button add-button"
                      @click="deleteSelectItem(option, select)"
                    >
                      <div class="vhp-text small light red">삭제</div>
                    </button>
                  </div>
                </div>
              </div>
            </template>

            <button
              class="vhp-button mt-2 mb-0"
              @click="product.options.push({ ...newOption })"
            >
              <div class="vhp-text small">옵션추가</div>
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-100 vh-80">
          <vue-editor
            v-model="product.content"
            class="ql-editor"
            @focus="changed = true"
            @text-change="editorInputChange"
          />
          <div class="img-resize-box">
            <label>
              W
              <input
                v-model="imgResizeVal"
                maxlength="4"
                @input="changed = true"
                @keydown.enter="resizeInputChange"
            /></label>
          </div>
        </div>
      </div>
    </div>

    <!-- 미리보기 모드 -->
    <div v-else class="mt-16">
      <product-layout :product="product" />
    </div>
  </div>
</template>

<script>
import ProductLayout from "@/components/layouts/ProductLayout.vue";
export default {
  name: "AdminProductDetails",
  components: { ProductLayout },
  data() {
    return {
      pageType: "",
      isPreview: false,
      changed: false,

      images: [],
      activeImageIndex: 0,

      newOption: { name: "", value: [{ name: "", value: 0, quantity: 0 }] },
      newSelect: { name: "", value: 0, quantity: 0 },

      product: {
        id: "",
        category: "",
        title: null,
        subtitle: null,
        brand: "VAZIL",
        price: 0,
        quantity: 0,
        options: [],
        images: [],
        hashTags: [],
        content: "",
        hidden: false,
      },

      category: [],

      resizeTargetImg: null,
      resizeBox: null,
      imgResizeVal: "",
    };
  },

  computed: {},
  mounted() {
    if (!this.$store.state.isAdmin) {
      alert("관리자 권한 없음");
      this.$router.push({ path: "/" });
    }
    this.init();
  },
  watch: {
    activeImageIndex() {
      if (this.activeImageIndex < 0) {
        this.activeImageIndex = 0;
      } else if (this.activeImageIndex > this.images.length - 1) {
        this.activeImageIndex = this.images.length - 1;
      }
    },
    images: {
      handler(newImages) {
        this.product.images = [...newImages];
      },
      deep: true,
    },
    // product: {
    //   handler(newProduct) {
    //     this.images = newProduct.images;
    //   },
    //   deep: true,
    // },
  },
  methods: {
    init() {
      if (this.$route.query) {
        this.pageType = this.$route.query.type;
        if (this.$route.query.id) {
          this.product.id = this.$route.query.id;
          this.getProduct(this.$route.query.id);
        } else {
          this.product.id = "@product-" + new Date().getTime();
        }
      }
      this.getCategory();
      this.resizeBox = document.querySelector(".img-resize-box");
      this.setClickOutsideEvent();
      this.setEditorScrollEvent();
    },

    async getCategory() {
      this.$axios
        .get("/category")
        .then((res) => {
          this.category = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getProduct(id) {
      if (!id) return;

      this.$axios
        .get("/product/details", { params: { id } })
        .then((res) => {
          this.product = res.data;
          this.images = [...res.data.images];
        })
        .catch((err) => {
          console.log(err);
        });
    },

    goProductPage() {
      this.$router.push({ path: "/admin/product" });
    },

    tokenAlert() {
      window.alert(
        "토큰이 만료되었습니다. 관리자 모드 해제 후 다시 로그인 해주세요."
      );
    },

    addImage(event) {
      const files = event.target.files;
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.images.push(e.target.result);
            this.activeImageIndex = this.images.length - 1;
          };
          reader.readAsDataURL(files[i]);

          // const blob = window.URL.createObjectURL(files[i]);
          // this.images.push(blob);
        }
      }
    },

    removeImage(index) {
      if (index !== -1) {
        this.images.splice(index, 1);
        this.activeImageIndex = index - 1;
      }
    },

    deleteOptionItem(item) {
      const index = this.product.options.indexOf(item);
      if (index !== -1) {
        this.product.options.splice(index, 1);
      }
    },

    deleteSelectItem(option, select) {
      const optionIndex = this.product.options.indexOf(option);

      if (optionIndex !== -1) {
        const selectIndex = option.value.indexOf(select);

        if (selectIndex !== -1) {
          this.product.options[optionIndex].value.splice(selectIndex, 1);
        }
      }
    },

    clickPrevBtn() {
      if (this.changed) {
        window.confirm(
          `페이지 이동 시 ${
            this.pageType === "create" ? "작성 내용이" : "수정 사항이"
          } 저장되지 않습니다. 이동하시겠습니까?`
        ) && this.$router.go(-1);
      } else {
        this.$router.go(-1);
      }
    },

    clickDelBtn() {
      const res = window.confirm("콘텐츠를 삭제하시겠습니까?");
      if (res) this.deleteProduct();
    },

    deleteProduct() {
      this.$axios
        .delete("/product", {
          params: {
            productId: this.product.id,
          },
        })
        .then(() => {
          this.goProductPage();
        })
        .catch((err) => {
          console.log(err);
          alert("오류가 발생하였습니다. 다시 시도해주세요.");
        });
    },

    clickSaveBtn() {
      if (this.checkValid() !== true) return;
      const res = window.confirm("저장하시겠습니까?");
      res &&
        (this.pageType === "create"
          ? this.createProduct()
          : this.editProduct());
    },

    checkValid() {
      if (!this.product.title) {
        window.alert("상품명을 입력하세요.");
      } else if (!this.product.content) {
        window.alert("내용을 입력하세요.");
      } else {
        return true;
      }
    },

    async createProduct() {
      await this.$axios
        .post("/product", {
          ...this.product,
          editor: this.$store.state.admin.username,
        })
        .then(() => {
          this.goProductPage();
        })
        .catch((err) => {
          console.log(err);
          alert("오류가 발생하였습니다. 다시 시도해주세요.");
        });
    },

    async editProduct() {
      await this.$axios
        .put("/product", {
          ...this.product,
          editor: this.$store.state.admin.username,
        })
        .then(() => {
          this.goProductPage();
        })
        .catch((err) => {
          console.log(err);
          alert("오류가 발생하였습니다. 다시 시도해주세요.");
        });
    },

    resizeInputChange() {
      this.resizeTargetImg.style.width = this.imgResizeVal + "px";

      const src = this.resizeTargetImg.src;
      const rawContent = this.content.content;
      const imgIndex = rawContent.lastIndexOf("<img", rawContent.indexOf(src));
      const imgString = rawContent.slice(
        imgIndex,
        rawContent.indexOf(">", imgIndex) + 1
      );

      if (imgString.includes("width")) {
        const widthIndex = rawContent.indexOf("width", imgIndex);
        const widthStr = rawContent.slice(
          widthIndex,
          rawContent.indexOf("px", widthIndex) + 3
        );

        this.content.content = rawContent.replace(
          imgString,
          imgString.replace(widthStr, `width="${this.imgResizeVal}px"`)
        );
      } else {
        const newImgString =
          `<img width="${this.imgResizeVal}px" ` + imgString.slice(5);
        this.content.content = rawContent.replace(imgString, newImgString);
      }

      this.$nextTick(() => {
        this.setImageClickEvent();
      });
    },

    setImageClickEvent() {
      document
        .querySelector(".ql-editor")
        .getElementsByTagName("img")
        .forEach((img) => {
          img.addEventListener("click", (e) => {
            this.resizeBox = document.querySelector(".img-resize-box");
            this.resizeTargetImg = e.target;
            this.imgResizeVal = e.target.clientWidth;
            this.resizeBox.style.display = "inline";
            this.resizeBox.style.top = e.y + "px";
            this.resizeBox.style.left = e.x + "px";
          });
        });
    },

    setEditorScrollEvent() {
      document.querySelector(".ql-container").addEventListener("scroll", () => {
        this.resizeBox.style.display = "none";
      });
    },

    setClickOutsideEvent() {
      window.addEventListener("click", this.clickOutsideEvent);
    },

    editorInputChange(delta) {
      const isImageExist = delta.ops.some((o) => o.insert?.image);
      isImageExist && this.setImageClickEvent();
    },
  },
};
</script>

<style lang="scss">
.admin-page.admin-product-details {
  width: 100vw !important;
  max-width: 100vw !important;
  box-sizing: border-box;
  position: relative;

  .header {
    border-bottom: 1px solid $base-border-color;
    z-index: 100;
    background-color: $base-background-color;
    width: calc(100% - 16px);
    padding:8px;
    position: fixed;
    top:0;
    left:0;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item{
      cursor: pointer;
      border: 0;
      padding: $base-button-padding;
      font-size: 14px;
      border-radius: 0.5rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: transparent !important;
      margin:0 !important;
      font-family: 'Pretendard-SemiBold' !important;
      opacity: 0.7;

      &:hover{
        opacity: 1;
      }
    }
    

  
  }

  .edit-container {
    margin-top: 60px;
    width: 100%;
    max-width: 1400px;
    .row {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
      display: flex;
      flex-flow: row;
      &.center {
        justify-content: center;
        align-items: center;
      }

      @media screen and (max-width: #{$breakpoint-desktop}) {
        flex-direction: column;
      }
    }
    .col-30 {
      flex: 1 1 calc(30% - 10px);
      text-align: left;
      padding: 20px;
      position: relative;
    }
    .col-70 {
      flex: 2 1 calc(70% - 10px);
      text-align: left;
      padding: 20px;
      position: relative;
    }
    .col-100 {
      flex: 1;
    }
    .vh-80 {
      min-height: 80vh;
    }

    .image-container {
      position: relative;
      display: flex;
      justify-content: center;
      max-height: 100%;
      max-width: 100%;
      min-height: 480px;

      .image-preview {
        margin: 0 10px;
        // display: grid;
        // grid-template-columns: repeat(1, 1fr);
        // grid-template-rows: repeat(6, 1fr);
        // gap: 10px;
        // min-height: 100%;
        max-height: 460px;
        min-width: 68px;
        max-width: 68px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: #{$breakpoint-desktop}) {
          display: none;
        }

        .image-preview-wrap {
          position: relative;
          max-width: 100%;
          max-height: 100%;
          overflow: hidden;
          border-radius: 4px;
          max-height: 68px;
          margin-bottom: 10px;

          .vhp-button {
            position: absolute;
            top: -3px;
            right: -3px;
            z-index: 100;
            width: 12px;
            height: 12px;
            padding: 10px;
            background-color: rgba($base-button-default-background-color, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .image-preview-card {
            width: 100%;
            height: auto;
            display: block;
            aspect-ratio: 1;
            object-fit: cover;
            transition: all 0.2s ease-in-out;

            &:hover:not(.default) {
              transform: scale(1.1);
            }
          }

          &.active {
            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: inherit;
              border: 2px solid $base-primary-opacity-color;
              box-sizing: border-box;
              pointer-events: none;
            }
          }

          &:hover:not(.default) {
            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: inherit;
              border: 2px solid $base-button-active-color;
              box-sizing: border-box;
              pointer-events: none;
            }
          }

          &.default {
            height: 68px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-bottom: 0;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: inherit;
              border: 1.5px solid $base-border-color;
              box-sizing: border-box;
            }
          }
        }
      }

      .image-wrap {
        display: flex;
        min-width: 460px;
        width: 100%;
        max-height: 460px !important;
        // max-width: 460px !important;
        aspect-ratio: 1;
        padding: 0 50px;
        position: relative;

        .image-card {
          height: 100%;
          width: 100%;
          object-fit: cover;
          aspect-ratio: 1;
          border-radius: 0.72rem;
          overflow: hidden;
          // box-shadow: rgba(149, 157, 165, 0.1) 0px 16px 32px;
          border: 1px solid $base-border-color;
        }

        .image-add-button {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -60px;
        }

        .slide-button-wrap {
          position: absolute;
          display: flex;
          top: 50%;
          transform: translateY(-50%);

          &.left {
            left: 0;
          }

          &.right {
            right: 0;
          }

          .slide-button {
            padding: 0;
            display: inline-block;
            margin: 6px;
            height: 32px;
            width: 32px;

            border-radius: 100%;
            position: relative;
            border: 1px solid $base-button-default-background-color;
            cursor: pointer;

            &:hover {
              background-color: $base-primary-transparent-color;
            }

            span {
              width: 8px;
              height: 2px;
              position: absolute;
              top: calc(50% + 1.5px);
              left: 11px;
              transition: all 0.1s ease-in-out;
              background-color: $base-button-background-color;
              transform: rotate(45deg);

              &:nth-of-type(2) {
                top: calc(50% - 3px);
                right: 11px;
                transform: rotate(-45deg);
              }
            }

            &.prev {
              transform: rotate(0);
              &:active {
                transform: scale(0.95);
              }
            }

            &.next {
              transform: rotate(180deg);
              &:active {
                transform: scale(0.95) rotate(180deg);
              }
            }

            &.disabled {
              opacity: 0.5;
            }
          }
        }

        .download-section {
          position: absolute;
          bottom: -80px;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }
    }

    .description-container {
      min-height: 420px;
      height: auto;
      padding: 20px 30px;
      border-radius: 0.72rem;
      box-shadow: rgba(149, 157, 165, 0.1) 0px 16px 32px;
      width: auto;
      max-width: 100%;
      position: relative;

      &.no-shadow {
        box-shadow: none;
      }

      &.outline {
        border: 1px solid $base-border-color;
      }

      &.dense {
        min-height: 320px;
      }

      .price {
        margin-left: -1px;
        margin-right: 5px;
      }
      .vat {
        opacity: 0.7;
        font-size: 13px;
      }

      .info-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        // width: 100%;
        height: 100%;
        // margin-bottom: 5px;

        .vhp-input {
          margin: 4px 0;
          width: 100%;

          input {
            padding: 10px 24px 10px 12px;
            font-family: "Pretendard-Light";
          }
        }

        // &.column {
        //   flex-direction: column;
        //   .vhp-input {
        //     // flex-direction: row;
        //   }
        // }

        .option-delete-button {
          margin-bottom: 5px;
          margin-left: 5px;
        }
      }

      .option-container {
        position: relative;

        // max-width: 100%;
        // border: 1px solid $base-border-color;
        border-radius: 0.5rem;
        background-color: rgba($base-button-default-background-color, 0.4);
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        // padding: 10px;

        .option-container-wrap {
          margin: 10px;
          width: 100%;
        }

        input {
          background-color: $base-background-color;
        }
        .close-button {
          background-color: transparent;
          position: absolute;
          right: 3.5px;
          top: 1.5px;
          border: none;
          cursor: pointer;
          z-index: 10;

          &:hover {
            border-radius: 100%;
            background-color: $base-button-default-background-color;
          }
        }

        .add-button {
          padding: 12px;
          margin-top: auto;
          margin-bottom: 4px;
          margin-left: 2px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.5rem;
        }
      }
    }

    
  .quillWrapper {
    font-size: 1rem;
    height: calc(100% - 160px);
    padding:0;
    .ql-toolbar{
      
      border: 1px solid $base-border-color;
    }

    .ql-container {
      border: 1px solid $base-border-color;
      height: 92%;
      overflow-y: scroll;
      background-color: #e3bdbd26;

      .ql-editor {
        font-size: 1rem;
        width: 100%;
        max-width: $full-width;
        min-height: 100%;
        height: auto;
        background-color: white;
        margin: auto;

        img {
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 0 1px;
          }
        }
      }
    }
  }

    .img-resize-box {
      display: none;
      position: absolute;
      padding: 5px 7px;
      background: #3c3c3c9e;
      color: white;
      border-radius: 5px;

      input {
        padding-left: 4px;
        width: 50px;
        border-radius: 5px;
        border: none;
      }
    }
  }
}
</style>
