<template>
  <div class="admin-page content-page">
    <div class="header">
      <button @click="clickPrevBtn" class="item">뒤로</button>

      <div v-if="content">
        <label>
          <input
            type="radio"
            v-model="content.hidden"
            name="isHidden"
            :value="false"
            :checked="!content.hidden"
          />공개
        </label>
        <label>
          <input
            type="radio"
            v-model="content.hidden"
            name="isHidden"
            :value="true"
          />비공개
        </label>
      </div>

      <div>
        <button class="item" @click="isPreview = !isPreview">
          {{ isPreview ? "편집" : "미리보기" }}
        </button>
        <button v-if="pageType === 'edit'" @click="clickDelBtn" class="item">삭제</button>

        <button @click="clickSaveBtn" class="vhp-button primary">저장</button>
      </div>
    </div>

    <!-- 편집 모드 -->
    <div
      v-if="!isPreview"
      style="width: 100%; height: calc(100vh - 80px); margin-top: 80px"
    >
      <div class="config">
          
        <vhp-select 
          v-model="content.category"
          :items="menus" 
          item-key="text"
          item-value="name"
          :placeholder="$t('카테고리')" 
          class="mr-2"
        />

        <vhp-select 
          v-model="content.locale"
          :items="[{value:'KR', text:'한국어'}, {value:'EN', text:'English'}]" 
          item-key="text"
          item-value="value"
          :placeholder="$t('언어')" 
          class="mr-2"
        />
   
        <button @click="$refs.thumbnailDialog.load()" class="vhp-button mr-2">
          <i class="fas fa-image mr-1"/>
          썸네일
        </button>
        
        <div class="hash-tags">
          <span
            v-for="(item, index) in content.hashTags"
            :key="'tag-' + index"
            @click="removeTag(index)"
            class="tag-item"
          >
            # {{ item }}
            <span>X</span>
          </span>
          <label
            >#
            <input
              v-model="newTag"
              placeholder="태그 추가"
              type="text"
              class="flat"
              maxlength="50"
              style="width: 120px; padding: 3px 5px"
              @input="changed = true"
              @keydown.enter="addTag"
              @keydown.space="addTag"
            />
          </label>
        </div>
      </div>

     

      <div class="title-inputs">
        <input
          v-model="content.title"
          placeholder="제목"
          type="text"
          maxlength="50"
          @input="changed = true"
        />
        <input
          v-model="content.subtitle"
          placeholder="설명"
          type="text"
          maxlength="70"
          @input="changed = true"
        />
      </div>

      <vue-editor
        v-model="content.content"
        class="ql-editor"
        @focus="changed = true"
        @text-change="editorInputChange"
      />
      <div class="img-resize-box">
        <label>
          W
          <input
            v-model="imgResizeVal"
            maxlength="4"
            @input="changed = true"
            @keydown.enter="resizeInputChange"
        /></label>
      </div>
    </div>

    <!-- 미리보기 모드 -->
    <div v-else style="width: 100%; height: calc(100vh - 80px); margin-top: 80px">
      <content-layout :previewContent="content" />
    </div>

    <vhp-dialog
      title="썸네일 설정"
      id="content-thumbnail"
      ref="thumbnailDialog"
      @load="loadThumbnailDialog"
      :preventClose="true"
      @close="closeThumbnailDialog"
    >
      <template v-slot:content>
        <img
          v-if="thumbnailFile"
          :src="thumbnailFile"
          alt="thumbnail"
          @click="loadThumbnailFileInput"
        />
        <label class="input-file-label">
          파일 선택
          <input
            type="file"
            id="thumbnail"
            accept="image/png, image/jpeg"
            @change="changeThumbnail"
          />
        </label>
        <button
          v-if="thumbnailFile"
          @click="removeThumbnail"
          class="fill vhp-button remove-btn"
        >
          삭제
        </button>
        <button
          v-if="thumbnailFile"
          @click="saveThumbnail"
          class="fill vhp-button save-btn"
        >
          저장
        </button>
      </template>
    </vhp-dialog>
  </div>
</template>

<script>
import ContentLayout from "@/components/layouts/ContentLayout.vue";

export default {
  name: "AdminContent",
  components: { ContentLayout },
  data() {
    return {
      pageType: null,
      menus: [
        { name: "STORY", text: "소식 > 스토리" },
        { name: "NOTICE", text: "소식 > 공지사항" },
        { name: "RESOURCE", text: "소식 > 자료실" },
      ],
      content: {
        category: "",
        locale: "KR",
        title: null,
        subtitle: null,
        content: null,
        image: null,
        hashTags: [],
        hidden: null,
      },
      newTag: "",
      thumbnailFile: null,
      changed: false,
      isPreview: false,
      resizeTargetImg: null,
      resizeBox: null,
      imgResizeVal: "",
    };
  },

  computed: {},
  methods: {
    init() {
      this.pageType = this.$route.params.type;

      if (this.$route.params.id) this.getContent(this.$route.params.id);

      this.resizeBox = document.querySelector(".img-resize-box");
      this.setClickOutsideEvent();
      this.setEditorScrollEvent();
    },
    getContent(id) {
      if (!id) return;

      this.$axios
        .get("/content/details", { params: { id } })
        .then((res) => {
          this.content = res.data;

          if (this.content.image) {
            document.querySelector(".input-file-label").style.display = "none";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clickPrevBtn() {
      if (this.changed) {
        window.confirm(
          `페이지 이동 시 ${
            this.pageType === "create" ? "작성 내용이" : "수정 사항이"
          } 저장되지 않습니다. 이동하시겠습니까?`
        ) && this.$router.go(-1);
      } else {
        this.$router.go(-1);
      }
    },

    clickDelBtn() {
      const res = window.confirm("콘텐츠를 삭제하시겠습니까?");
      if (res) this.deleteContent();
    },
    deleteContent() {
      this.$axios
        .delete("/content", {
          params: {
            contentId: this.$route.params.id,
            actorName: this.$store.state.admin.username,
          },
        })
        .then(() => {
          this.goConsolePage();
        })
        .catch((err) => {
          console.log(err);
          this.tokenAlert();
        });
    },
    clickSaveBtn() {
      if (this.checkValid() !== true) return;

      const res = window.confirm("저장하시겠습니까?");
      res && (this.pageType === "create" ? this.createContent() : this.editContent());
    },
    checkValid() {
      if (!this.content.category) {
        window.alert("카테고리를 선택하세요.");
      } else if (!this.content.title) {
        window.alert("제목을 입력하세요.");
      } else if (!this.content.content) {
        window.alert("내용을 입력하세요.");
      } else {
        return true;
      }
    },
    createContent() {
      this.$axios
        .post("/content", {
          ...this.content,
          editor: this.$store.state.admin.username,
        })
        .then(() => {
          this.goConsolePage();
        })
        .catch((err) => {
          console.log(err);
          this.tokenAlert();
        });
    },
    editContent() {
      this.$axios
        .put("/content", this.content)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((err) => {
          console.log(err);
          this.tokenAlert();
        });
    },
    addTag(e) {
      if (this.newTag === "" && e.code === "Space") {
        e.returnValue = false;
        return;
      }

      this.content.hashTags.push(this.newTag);
      this.newTag = "";
      e.returnValue = false;
    },
    removeTag(index) {
      this.content.hashTags.splice(index, 1);
    },
    goConsolePage() {
      this.$router.push({ path: "/admin/console" });
    },
    tokenAlert() {
      window.alert("토큰이 만료되었습니다. 관리자 모드 해제 후 다시 로그인 해주세요.");
    },
    loadThumbnailDialog() {
      if (!this.content.image) {
        document.querySelector("input#thumbnail").click();
      } else {
        this.thumbnailFile = this.content.image;
      }
    },
    closeThumbnailDialog() {
      if (!this.content.image) {
        this.thumbnailFile = null;
        document.querySelector(".input-file-label").style.display = "block";
      }
      document.querySelector("input#thumbnail").value = "";

      this.$refs.thumbnailDialog.close();
    },
    loadThumbnailFileInput() {
      document.querySelector("input#thumbnail").click();
    },
    changeThumbnail(e) {
      const file = e.target.files[0];
      if (file) {
        document.querySelector(".input-file-label").style.display = "none";
      }
      const blob = window.URL.createObjectURL(file);
      this.thumbnailFile = blob;
    },
    removeThumbnail() {
      this.content.image = null;
      this.thumbnailFile = null;
      document.querySelector(".input-file-label").style.display = "block";
      document.querySelector("input#thumbnail").value = "";
    },
    saveThumbnail() {
      const file = document.querySelector("input#thumbnail").files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        this.content.image = reader.result;
        this.closeThumbnailDialog();
      };

      reader.readAsDataURL(file);
    },
    // editor 내부의 모든 이미지에 click 이벤트 추가
    setImageClickEvent() {
      document
        .querySelector(".ql-editor")
        .getElementsByTagName("img")
        .forEach((img) => {
          img.addEventListener("click", (e) => {
            this.resizeBox = document.querySelector(".img-resize-box");
            this.resizeTargetImg = e.target;
            this.imgResizeVal = e.target.clientWidth;
            this.resizeBox.style.display = "inline";
            this.resizeBox.style.top = e.y + "px";
            this.resizeBox.style.left = e.x + "px";
          });
        });
    },
    resizeInputChange() {
      this.resizeTargetImg.style.width = this.imgResizeVal + "px";

      const src = this.resizeTargetImg.src;
      const rawContent = this.content.content;
      const imgIndex = rawContent.lastIndexOf("<img", rawContent.indexOf(src));
      const imgString = rawContent.slice(imgIndex, rawContent.indexOf(">", imgIndex) + 1);

      if (imgString.includes("width")) {
        const widthIndex = rawContent.indexOf("width", imgIndex);
        const widthStr = rawContent.slice(
          widthIndex,
          rawContent.indexOf("px", widthIndex) + 3
        );

        this.content.content = rawContent.replace(
          imgString,
          imgString.replace(widthStr, `width="${this.imgResizeVal}px"`)
        );
      } else {
        const newImgString = `<img width="${this.imgResizeVal}px" ` + imgString.slice(5);
        this.content.content = rawContent.replace(imgString, newImgString);
      }

      this.$nextTick(() => {
        this.setImageClickEvent();
      });
    },
    clickOutsideEvent(e) {
      if (
        !(this.resizeTargetImg?.contains(e.target) || this.resizeBox?.contains(e.target))
      ) {
        this.resizeBox.style.display = "none";
      }
    },
    setEditorScrollEvent() {
      document.querySelector(".ql-container").addEventListener("scroll", () => {
        this.resizeBox.style.display = "none";
      });
    },
    setClickOutsideEvent() {
      window.addEventListener("click", this.clickOutsideEvent);
    },
    editorInputChange(delta) {
      const isImageExist = delta.ops.some((o) => o.insert?.image);
      isImageExist && this.setImageClickEvent();
    },
  },
  mounted() {
    if (!this.$store.state.isAdmin) {
      alert("관리자 권한 없음");
      this.$router.push({ path: "/" });
    }

    this.init();
  },
  unmounted() {
    window.removeEventListener("click", this.clickOutsideEvent);
  },
  watch: {},
};
</script>

<style lang="scss">
.admin-page.content-page {
  box-sizing: border-box;
  position: relative;

  padding: 0 30px !important;
  width: 100vw !important;
  max-width: $full-width;
  box-sizing: border-box;
  

  .header {
    border-bottom: 1px solid $base-border-color;
    z-index: 100;
    background-color: $base-background-color;
    width: calc(100% - 16px);
    padding: 8px;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: row;
    height: 48px;
    justify-content: space-between;
    align-items: center;

    .item {
      cursor: pointer;
      border: 0;
      padding: $base-button-padding;
      font-size: 14px;
      border-radius: 0.5rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: transparent !important;
      margin: 0 !important;
      font-family: "Pretendard-SemiBold" !important;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }
  }
  .config{
    text-align: start;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .hash-tags {
    padding: 10px 15px;
  }

  .title-inputs {
    display: flex;
    flex-direction: column;
    
    font-size: 1.5rem;
    input {
      margin: 4px 0;
      font-size: 1.2rem;
      padding: 10px;
      border: 1px solid $base-border-color;
      border-radius: 0.3rem;

      &:nth-child(2) {
        font-size: 1rem;
      }
    }
  }

  .tag-item {
    margin: 0 5px;
    padding: 3px;
    padding-left: 5px;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    line-height: 25px;

    span {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: none;
      font-weight: 800;
    }

    &:hover {
      background-color: $base-primary-shadow-color;
      color: $base-primary-opacity-color;

      span {
        display: block;
        color: $base-primary-color;
      }
    }
  }

  .quillWrapper {
    font-size: 1rem;
    height: calc(100% - 160px);
    padding:0;
    .ql-toolbar{
      
      border: 1px solid $base-border-color;
    }

    .ql-container {
      border: 1px solid $base-border-color;
      height: 92%;
      overflow-y: scroll;
      background-color: #e3bdbd26;

      .ql-editor {
        font-size: 1rem;
        width: 100%;
        max-width: $full-width;
        min-height: 100%;
        height: auto;
        background-color: white;
        margin: auto;

        img {
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 0 1px;
          }
        }
      }
    }
  }

  .img-resize-box {
    display: none;
    position: absolute;
    padding: 5px;
    background: #3c3c3c9e;
    color: white;
    border-radius: 5px;

    input {
      width: 50px;
      border-radius: 5px;
    }
  }
}
</style>
