import { createWebHistory, createRouter } from "vue-router";
import Index from "./views/index.vue";
import Admin from "./views/admin/index.vue";
import AdminConsole from "./views/admin/console.vue";
import AdminAccount from "./views/admin/account.vue";
import AdminLog from "./views/admin/log.vue";
import AdminStatistics from "./views/admin/statistics.vue";
import AdminHistory from "./views/admin/history.vue";
import AdminPatent from "./views/admin/patent.vue";
import AdminAward from "./views/admin/award.vue";
import AdminContent from "./views/admin/content/_type.vue";
import AdminProduct from "./views/admin/product/index.vue";
import AdminProductDetails from "./views/admin/product/details.vue";
import InfoIndex from "./views/info/index.vue";
import NewsIndex from "./views/news/index.vue";
import ContactIndex from "./views/contact/index.vue";
import SearchIndex from "./views/search/index.vue";
import PrivacyIndex from "./views/privacy/index.vue";
import ErrorIndex from "./views/error/index.vue";
import PreparationIndex from "./views/preparation/index.vue";
import ProductIndex from "./views/products/index.vue";
import TechnologyIndex from "./views/technology/index.vue";
import RobotIndex from "./views/robot/index.vue";
import ServiceIndex from "./views/service/index.vue";

const DEFAULT_TITLE = "VAZIL Company";

const routes = [
  {
    path: "/",
    component: Index,
    name: "홈",
  },
  {
    path: "/admin",
    component: Admin,
    meta: {
      title: "관리자 로그인",
    },
  },
  {
    path: "/admin/console",
    name: "adminConsole",
    component: AdminConsole,
    props: true,
    meta: {
      title: "관리 - 콘솔",
    },
  },
  {
    path: "/admin/account",
    name: "adminAccount",
    component: AdminAccount,
    props: true,
    meta: {
      title: "관리 - 계정 관리",
    },
  },
  {
    path: "/admin/award",
    name: "adminAward",
    component: AdminAward,
    props: true,
    meta: {
      title: "관리 - 수상/인증 관리",
    },
  },
  {
    path: "/admin/patent",
    name: "adminPatent",
    component: AdminPatent,
    props: true,
    meta: {
      title: "관리 - 특허 관리",
    },
  },
  {
    path: "/admin/log",
    name: "adminLog",
    component: AdminLog,
    props: true,
    meta: {
      title: "관리 - 로그",
    },
  },
  {
    path: "/admin/statistics",
    name: "adminStatistics",
    component: AdminStatistics,
    props: true,
    meta: {
      title: "관리 - 통계",
    },
  },
  {
    path: "/admin/content/:type",
    name: "adminContent",
    component: AdminContent,
    props: true,
    meta: {
      title: "관리 - 콘텐츠",
    },
  },
  {
    path: "/admin/history",
    name: "adminHistory",
    component: AdminHistory,
    props: true,
    meta: {
      title: "관리 - 연혁",
    },
  },
  {
    path: "/admin/product",
    name: "adminProduct",
    component: AdminProduct,
    props: true,
    meta: {
      title: "관리 - 상품 관리",
    },
  },
  {
    path: "/admin/product/details",
    name: "adminProductDetails",
    component: AdminProductDetails,
    props: true,
    meta: {
      title: "관리 - 상품 정보",
    },
  },
  {
    path: "/info",
    redirect: "/info/vazil",
    component: InfoIndex,
    name: "바질컴퍼니",
    children: [
      {
        path: "vazil",
        name: "개요",
        component: () => import("./views/info/vazil.vue"),
      },
      {
        path: "history",
        name: "주요연혁",
        component: () => import("./views/info/history.vue"),
      },
      {
        path: "workplace",
        name: "사업장소개",
        component: () => import("./views/info/workplace.vue"),
      },
      {
        path: "awards",
        name: "수상/인증",
        component: () => import("./views/info/awards.vue"),
      },
      {
        path: "patent",
        name: "주요특허",
        component: () => import("./views/info/patent.vue"),
      },
    ],
    meta: {
      title: "소개",
    },
  },
  {
    path: "/service",
    component: ServiceIndex,
    name: "기술과 서비스",
    children: [
      {
        path: "",
        name: "전체",
        component: () => import("./views/service/index.vue"),
      },
      {
        path: "details",
        component: () => import("./views/service/details.vue"),
      },
    ],
    meta: {
      title: "기술과 서비스",
    },
  },
  {
    path: "/robot",
    component: RobotIndex,
    children: [
      {
        path: "product",
        component: () => import("./views/robot/product.vue"),
      },
      {
        path: "details",
        component: () => import("./views/robot/details.vue"),
      },
    ],
    name: "로봇",
    meta: {
      title: "로봇",
    },
  },
  {
    path: "/technology",
    component: TechnologyIndex,
    children: [],
    name: "기술",
    meta: {
      title: "기술",
    },
  },
  {
    path: "/products",
    component: ProductIndex,
    name: "제품",
    children: [
      {
        path: "details",
        name: "상세정보",
        component: () => import("./views/products/details.vue"),
      }
    ],
    meta: {
      title: "제품",
    },
  },
  {
    path: "/news",
    redirect: "/news/story",
    component: NewsIndex,
    name: "소식",
    children: [
      {
        path: "story",
        name: "스토리",
        component: () => import("./views/news/story.vue"),
      },
      {
        path: "notice",
        name: "공지사항",
        component: () => import("./views/news/notice.vue"),
      },
      {
        path: "resources",
        name: "자료실",
        component: () => import("./views/news/resources.vue"),
      },
      {
        path: "details",
        component: () => import("./views/news/details.vue"),
      },
      {
        path: "career",
        name: "채용",
        component: () => import("./views/news/career.vue"),
      },
    ],
    meta: {
      title: "소식",
    },
  },
  {
    path: "/contact",
    component: ContactIndex,
    children: [],
    name: "문의",
    meta: {
      title: "문의",
    },
  },
  {
    path: "/privacy",
    component: PrivacyIndex,
    children: [],
    name: "개인정보 처리방침",
    meta: {
      title: "개인정보 처리방침",
    },
  },
  {
    path: "/content",
    component: () => import("./views/content/index.vue"),
  },
  {
    path: "/search",
    component: SearchIndex,
    children: [],
    meta: {
      title: "검색",
    },
  },
  {
    path: "/error",
    component: ErrorIndex,
    children: [],
    meta: {
      title: "오류",
    },
  },
  {
    path: "/preparation",
    component: PreparationIndex,
    children: [],
    meta: {
      title: "준비중",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/error",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach(() => {
  if (location.pathname !== "/") {
    window.scrollTo(0, 0);
  }
});

router.afterEach((to) => {
  document.title = (to.meta.title ? to.meta.title + " | " : "") + DEFAULT_TITLE;
});

export default router;
