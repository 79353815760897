<script>
import * as ChannelService from "@channel.io/channel-web-sdk-loader";

export default {
  methods: {
    loadChannelTalk() {
      ChannelService.loadScript();
      ChannelService.boot({
        pluginKey: "2aa1c70b-49f0-4d04-922a-8942068ce012", 
      });
    },
  },
  mounted() {
    this.loadChannelTalk();
  },
};
</script>
